import 'react-toastify/dist/ReactToastify.css';
import '../../assets/styles/UploadSearchCommodity.css';

const TaxAuthority = () => {
  
  return (
    <div>
          <div className='pagecontainer scrollablearea'>
          <h1 className='text-3xl font-medium mb-8'>Tax Authority</h1>
        </div>
    </div>
  );
};

export default TaxAuthority;
