import React, { useLayoutEffect, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useGlobalContext } from '../../../hook/globalstate';

const SettingModal = ({ settingWidgetData, onClose, dropKey, settingId }) => {

  const { state, setState } = useGlobalContext()
  const [hideLabel, setHideLabel] = useState(false);
  const [keyLabel, setKeyLabel] = useState('');
  const [customTitle, setCustomTitle] = useState('');
  const [logarithmicScale, setLogarithmicScale] = useState(false);
  const [valueLabel, setValueLabel] = useState('');

  // Function to update or add properties
  const updateProperties = (existingProperties = [], newProperties) => {
    const propertyMap = new Map(existingProperties.map(prop => [prop.property, prop]));

    newProperties.forEach(newProp => {
      propertyMap.set(newProp.property, newProp);
    });

    return Array.from(propertyMap.values());
  };

  useEffect(() => {
    const settingData = state.settingData[`${settingId}-${dropKey}`];
    if(settingData){
      setHideLabel(settingData.hideLabel);
      setLogarithmicScale(settingData.logarithmicScale);
      setKeyLabel(settingData.keyLabel);
      setCustomTitle(settingData.label);
      setValueLabel(settingData.valueLabel);
    }    
  }, []);

  useLayoutEffect(() => {
    const titleModel = state.settingModel?.find(sm => sm.modalId === settingId);
    if (titleModel) {
      const properties = titleModel.properties;
      const hideLabel = properties.find(p => p.property === "hideLabel")?.value?.value;
      const logarithmicScale = properties.find(p => p.property === "logarithmicScale")?.value?.value;
      const keyLabel = properties.find(p => p.property === "keyLabel")?.value?.value;
      const customTitle = properties.find(p => p.property === "label")?.value?.value;

      setHideLabel(hideLabel);
      setLogarithmicScale(logarithmicScale);
      setKeyLabel(keyLabel);
      setCustomTitle(customTitle);
    }
  }, [])

  const handleSubmit = () => {
    const payload = {
      properties: [
        {
          property: "label",
          value: {
            type: "text",
            value: customTitle,
          },
        },
        {
          property: "hideLabel",
          value: {
            type: "boolean",
            value: hideLabel,
          },
        },
        {
          property: "keyLabel",
          value: {
            type: "text",
            value: keyLabel,
          },
        },
        {
          property: "valueLabel",
          value: {
            type: "text",
            value: valueLabel,
          },
        },
        {
          property: "logarithmicScale",
          value: {
            type: "boolean",
            value: logarithmicScale,
          },
        },
        {
          property: "modalId",
          value: {
            type: "text",
            value: settingId,
          },
        },
        {
          property: "sectionId",
          value: {
            type: "text",
            value: dropKey,
          },
        },
      ],
      modalId: settingId,
      sectionId: dropKey,
    };
    const settingData = {
      type: "settingData",
      payload: {
        [`${settingId}-${dropKey}`]: {  // Use template literals to dynamically set the key
          label: customTitle,
          hideLabel: hideLabel,
          keyLabel: keyLabel,
          valueLabel: valueLabel,
          logarithmicScale: logarithmicScale,
          settingId: settingId,
          dropKey: dropKey,
        }
      }
    };
    
    setState(settingData)
    settingWidgetData(payload)
    onClose();
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 300, padding: 2, border: '1px solid #ddd', borderRadius: 2 }}>
      <Typography variant="h6">Editing Series Table</Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={hideLabel}
            onChange={(e) => setHideLabel(e.target.checked)}
          />
        }
        label="Hide Label"
      />
      <TextField
        label="Key Label"
        value={keyLabel}
        onChange={(e) => setKeyLabel(e.target.value)}
      />
      <TextField
        label="Custom Widget Title"
        value={customTitle}
        onChange={(e) => setCustomTitle(e.target.value)}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={logarithmicScale}
            onChange={(e) => setLogarithmicScale(e.target.checked)}
          />
        }
        label="Logarithmic Scale"
      />
      <TextField
        label="Value Label"
        value={valueLabel}
        onChange={(e) => setValueLabel(e.target.value)}
      />
      <Button onClick={handleSubmit} sx={{ backgroundColor: '#ff6b04', color: '#fff' }}>
        Done
      </Button>
    </Box>
  );
};

export default SettingModal;
