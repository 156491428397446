import { Box, Grid, Button, TextField, Card, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import KpiBoxes from './KpiBoxes';
import WidgetMenuReport from './WidgetMenuReport';
import DroppableItem from './droppableItem';
import AddIcon from '@mui/icons-material/Add';
import { shallowEqual, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { authAPI } from '../../services/authAPI';
import { errorMessage } from '../common/errorhandling';
import { useNavigate } from 'react-router-dom';
import HideSidebar from '../../assets/images/hide-sidebar.svg';
import ShowSidebar from '../../assets/images/show-sidebar.svg';
import CancelIcon from '@mui/icons-material/Cancel';
import IconClose from '../../assets/images/icon-close.svg';
import moment from 'moment';
import dayjs from 'dayjs';
//import { useGlobalContext } from '../../hook/globalstate';
import { useForm } from 'react-hook-form';
import { countryNameMapping } from './AnalyticalCommon';

const CreateReport = () => {
  const [droppableArea, setDroppableArea] = useState([1]);
  const [droppedItems, setDroppedItems] = useState({});
  const [settingModalData, setSettingModalData] = useState({});
  const access_token = useSelector((state) => state.auth.access_token, shallowEqual);
  const [filters, setFilters] = useState([]);
  const navigate = useNavigate();
  const countryName = countryNameMapping();
  // const { states } = useGlobalContext()

  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      title: 'New Report',
    },
  });


  /**
   * @function useEffect
   * @description Updates the droppable area whenever the dropped items change.
   */
  useEffect(() => {
    setDroppableArea([...droppableArea]);
  }, [droppedItems]);


  // Function to get all items
  const getAllItems = (droppedItems) => {
    const items = [];

    Object.keys(droppedItems).forEach(key => {
      droppedItems[key].forEach(entry => {
        items.push(entry.item);
      });
    });

    return items;
  };

  const items = getAllItems(droppedItems);
  localStorage.setItem('widgetNames', items);

  /**
   * @function handleDragOver
   * @description Prevents the default behavior of drag over event.
   * @param {object} e - The event object.
   */
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  /**
   * @function addNewDroppableArea
   * @description Adds a new droppable area.
   */
  const addNewDroppableArea = () => {
    setDroppableArea([...droppableArea, (Math.max(...droppableArea) + 1)]);
  };

  /**
   * @function removeAndResetKeys
   * @description Removes and resets the keys of an object.
   * @param {object} obj - The object to reset keys.
   * @param {string} keyToRemove - The key to remove.
   * @returns {object} The object with reset keys.
   */
  const removeAndResetKeys = (obj, keyToRemove) => {
    let resetObject = {};
    let newKey = 1;

    for (let key in obj) {
      if (obj[key] !== undefined && key != keyToRemove) {
        resetObject[newKey] = obj[key];
        newKey++;
      }
    }

    return resetObject;
  };

  /**
   * @function handleAreaDelete
   * @description Handles the deletion of a droppable area.
   * @param {string} areaKey - The key of the area to delete.
   */
  const handleAreaDelete = (areaKey) => {
    const selectedType = { ...droppedItems };
    const items = removeAndResetKeys(selectedType, areaKey);
    const droppedAr = Object.keys(items);
    setDroppableArea(droppedAr);
    setDroppedItems(items);
  };

  /**
   * @function handleDroppedArea
   * @description Handles the dropped area items.
   * @param {Array} items - The dropped items.
   * @param {string} dropKey - The key of the drop area.
   */
  const handleDroppedArea = (items, dropKey) => {
    setDroppedItems((prevState) => ({ ...prevState, [dropKey]: items }));
  };

  /**
   * @function moveDroppableArea
   * @description Moves the droppable area up or down.
   * @param {string} dropKey - The key of the drop area.
   * @param {string} direction - The direction to move ('up' or 'down').
   * @returns {boolean} False if the movement is not possible.
   */
  const moveDroppableArea = (dropKey, direction) => {
    const key = parseInt(dropKey);
    if ((key === 1 && direction === 'up') || (key === droppableArea.length && direction === 'down')) {
      return false;
    } else {
      const currentItem = droppedItems?.[key];
      if (direction === 'up') {
        const movedItem = droppedItems?.[key - 1];
        setDroppedItems((prevState) => ({ ...prevState, [key - 1]: currentItem, [key]: movedItem }));
      } else {
        const movedItem = droppedItems?.[key + 1];
        setDroppedItems((prevState) => ({ ...prevState, [key]: movedItem, [key + 1]: currentItem }));
      }
    }
  };

  /**
   * @function mergeData
   * @description Generates the desired structure for the report.
   * @returns {Array} The structured data for the report.
   */


  const mergeData = () => {
    const defaultProperties = [
      { "property": "label", "value": { "type": "text", "value": "" } },
      { "property": "hideLabel", "value": { "type": "boolean", "value": false } },
      { "property": "keyLabel", "value": { "type": "text", "value": "" } },
      { "property": "valueLabel", "value": { "type": "text", "value": "" } },
      { "property": "logarithmicScale", "value": { "type": "boolean", "value": false } },
      { "property": "modalId", "value": { "type": "text", "value": "" } },
      { "property": "sectionId", "value": { "type": "text", "value": "" } }
    ];

    const finalItems = { ...droppedItems }

    const fData = Object.entries(finalItems)
      .filter(([key, value]) => value !== undefined)
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
      
      const settingData = (settingModalData && settingModalData.length > 0)
      ? settingModalData.sort((a, b) => a.sectionId - b.sectionId)
      : [];
      let i = 0;
      // Iterate over each group of items in droppedItems and map them individually
      const itemsArray = Object.keys(fData).map(key => {
        return droppedItems[key].map((item, index) => {
          let properties = defaultProperties.map((prop, propIndex) => {
            // const settingProp = settingModalData[index]?.properties?.find(p => p.property === prop.property);
            let settingProp;
            if(item.itemId == settingData[i]?.modalId && key == settingData[i]?.sectionId){
              settingProp = settingData[i]?.properties[propIndex];
            }
            
            return settingProp ? settingProp : prop;
          });
          if(item.itemId == settingData[i]?.modalId && key == settingData[i]?.sectionId){ i++; }
          
          return {
            "name": item?.item,
            "wName": item?.name,
            "itemId": item?.itemId,
            "properties": properties
          };
        });
      });

    return itemsArray;
  };

  /**
   * @function dragItemSend
   * @description Sends the report data to the server.
   * @param {boolean} params - The parameter to check before sending data.
   */
  const dragItemSend = async (data) => {
    const filters = window.localStorage.getItem('filters');
    const viewCurrency = window.localStorage.getItem('viewCurrency');
    if (data) {
      try {
        const finalData = mergeData();
        const formData = new FormData();
        // formData.append('title', title);
        formData.append('title', data.title);
        formData.append('description', "");
        formData.append('root', null);
        // formData.append('model', JSON.stringify([finalData]));
        formData.append('model', JSON.stringify(finalData));
        formData.append('folder', "");
        formData.append('filters', filters);
        formData.append('viewCurrency', viewCurrency);
        const response = await authAPI.createWidget({ access_token, formData });

        if (response.status) {
          toast.success(response?.message || "Report Created Successfully");
          navigate('/analyticals')
        } else {
          errorMessage();
          toast.error(response?.message || "Something went wrong");
        }
      } catch (error) {
        errorMessage(error);
        toast.error("An error occurred while creating the report");
      }
    }
  };


  const onSubmit = handleSubmit(dragItemSend);

  /**
   * @function handleSettingModelData
   * @description Handles the setting modal data.
   * @param {object} settingData - The setting data object.
   */
  const handleSettingModelData = (settingData) => {
    setSettingModalData(prevItems => {
      // Ensure prevItems is an array
      const itemsArray = Array.isArray(prevItems) ? prevItems : [];
  
      // Find if the item with the same modalId and sectionId already exists
      const existingIndex = Array.isArray(itemsArray) ? itemsArray.findIndex(item => 
        item.modalId === settingData.modalId && 
        item.sectionId === settingData.sectionId
      ) : -1;      
  
      if (existingIndex !== -1) {
        // If the item exists, replace it with the new item
        const updatedItems = [...itemsArray];
        updatedItems[existingIndex] = settingData;
        return updatedItems;
      } else {
        // If the item does not exist, add the new item
        return [...itemsArray, settingData];
      }
    });
  };
  

  const [isOpen02, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen02);

  const getFilterValueData = (filtersValue) => {
    
    setFilters(filtersValue)
  }

  const handleRemoveFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
    window.localStorage.setItem('filters', JSON.stringify(newFilters));
    window.dispatchEvent(new Event('filterUpdate'));
  };


  const filterRemoveFunction = (filterRemove) => {
    setFilters(filterRemove);
    window.localStorage.setItem('filters', JSON.stringify(filterRemove));
    window.dispatchEvent(new Event('filterUpdate'));

  };

  return (
    <>
      <Grid container className={`anlytical-dashboard  ${isOpen02 ? 'showReportMenu' : 'hideReportMenu'}`}>
        <Grid item className="scrollablesidebar">
          <Grid container sx={{ backgroundColor: '#ffffff', }} m={0} className={` sm:pt-5 bg-white folder-list ${isOpen02 ? 'show' : ''}`}>
            <button className="sidebarClose" onClick={toggle}>
              <img width="15" height="15" src={IconClose} alt="" />
            </button>
            <WidgetMenuReport />
          </Grid>
        </Grid>
        <Grid item p={2} className='scrollablearea'>
          <button className="sidebarToggle mb-3" onClick={toggle}>
            <img width="20" height="20" src={isOpen02 ? HideSidebar : ShowSidebar} alt="" /> {isOpen02 ? 'Hide' : 'Show'} Widgets
          </button>
          <Grid container className='creatReportHeading'>
            <Grid item xs={12} sm={6} md={3} className='report-action'>
              <KpiBoxes droppableArea={droppableArea} droppedItems={droppedItems} title={getValues('title')} settingModalData={settingModalData} handleSaveClick={onSubmit} getFilterValueData={getFilterValueData} filterRemoveFunction={filterRemoveFunction} className="actionbox" />
            </Grid>
            <Grid item xs={12} sm={6} md={9} p={2} sx={{ background: 'none', padding: '15px 0px' }}>
              <Card>
                <TextField
                  id="filled-basic"
                  label="Title"
                  variant="standard"
                  {...register('title')}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{ width: '100%', p: '20px 10px 23px 15px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setValue('title', e.target.value)}
                />
              </Card>

            </Grid>
          </Grid>
          {filters?.length > 0 ? (
            <Card sx={{ marginTop: '0' }}>
              {filters.map((filter, index) => (
                <Typography
                  variant="body1"
                  key={index}
                  sx={{ padding: '5px 0px 5px 12px', fontSize: '14px' }}
                >
                  Report Filters:
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveFilter(index)}
                    sx={{ float: "none" }}
                  >
                    <CancelIcon fontSize="small" sx={{ color: "#1a425f" }} />
                  </IconButton>
                  {filter?.columnDisplay}: 
                  <strong>                  
                    { filter?.columnDisplay === "Dispatch Country" || filter?.columnDisplay === "Origin Country" ? countryName[filter?.value?.value] : filter?.value?.value }
                  </strong>
                  {filter?.value?.dateType === 'lastmonth' ? (
                    <strong> Last Month</strong>
                  ) : filter?.value?.dateType === 'thisquarter' ? (
                    <strong> This Quarter</strong>
                  ) : filter?.value?.dateType === 'lastquarter' ? (
                    <strong> Last Quarter</strong>
                  ) : filter?.value?.dateType === 'thisyear' ? (
                    <strong> This Year</strong>
                  ) : filter?.value?.dateType === 'lastyear' ? (
                    <strong> Last Year</strong>
                  ) : (
                    <strong> {filter?.value?.dateType}</strong>
                  )}
                  {filter?.value?.startDate && filter?.value?.endDate && (
                    <strong> : {dayjs(filter?.value?.startDate).format('DD/MM/YYYY')} to {dayjs(filter?.value?.endDate).format('DD/MM/YYYY')}
                    </strong>
                  )}
                </Typography>
              ))}
            </Card>
          ) : (
            ''
            // <Typography variant='body1' sx={{ padding: '10px 0px 10px 12px', fontSize: '14px' }}>
            //   {viewCurrency ? `Currency: ${viewCurrency}` : 'No filters set'}
            // </Typography>
          )}

          <Box sx={{ display: 'block', padding: '20px 0px 0px 0' }}>
            <Grid container spacing={0}>
              {droppableArea?.map((area, index) => {
                return (
                  <Grid key={`draggableArea${area}`} item xs={12} sm={12} md={12}>
                    <DroppableItem
                      onDragOver={handleDragOver}
                      dropKey={area}
                      handleAreaDelete={handleAreaDelete}
                      handleDroppedArea={handleDroppedArea}
                      moveDroppableArea={moveDroppableArea}
                      handleSettingModelData={handleSettingModelData}
                      sectionItem={droppedItems?.[area] || []}
                      totalAreas={droppableArea.length}
                    />
                  </Grid>
                );
              })}
              <Grid item xs={12} sm={12} md={12} className='dashboard-row' sx={{ textAlign: 'center', p: '0px !important', margin: '10px 0' }}>
                <Button variant='text' className='report-btn' onClick={addNewDroppableArea}>
                  <AddIcon />
                </Button>
              </Grid>
            </Grid>
          </Box>

        </Grid>
      </Grid>

    </>
  );
};

export default CreateReport;
