import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { unsetSuccessMessage } from "./reducers/messageSlice";
import { setUserProfile } from "./reducers/userProfileSlice";
import { authAPI } from "./services/authAPI";
import { useIdleTimer } from 'react-idle-timer';
import { signOutManager } from "./reducers/authSlice";
import SidebarNav from "./routes/sidebarNav";
import ClearLocalStorageOnNavigation from "./components/analytical/ClearLocalStorageOnNavigation";

const App = () => {
  const { access_token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onIdle = () => {
    try {
      dispatch(signOutManager());
    } catch (error) {
      console.error("Error during idle logout:", error);
    }
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 120 * 60 * 1000, // 120 minute idle timeout (2 hours)
  });

  useEffect(() => {
    dispatch(unsetSuccessMessage({ message: null }));
  }, [dispatch]);

  const fetchUserProfile = useCallback(
    async (token) => {
      try {
        if (token) {
          const profileData = await authAPI.getUserProfile({ access_token: token });
          if (profileData) {
            dispatch(setUserProfile(profileData));
          }
        }
      } catch (error) {
        console.log("Error fetching user profile:", error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (access_token) {
      fetchUserProfile(access_token);
    }
  }, [access_token, fetchUserProfile]);

  return (
    <Router>
      <ClearLocalStorageOnNavigation />
      <Routes>
        <Route path="*" element={<SidebarNav />} />
      </Routes>
    </Router>
  );
};

export default App;
