import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const [greetingMessage, setGreetingMessage] = useState("");
  const userProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    const determineGreeting = () => {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      let greeting;

      if (currentHour < 12) {
        greeting = "Good morning";
      } else if (currentHour < 18) {
        greeting = "Good afternoon";
      } else {
        greeting = "Good evening";
      }

      setGreetingMessage(greeting);
    };

    determineGreeting();
    const rootDiv = document.getElementById("root");
    if (rootDiv) {
      rootDiv.classList.remove("analtical-tool");
    }
    // Update greeting every minute to account for the changing time
    const interval = setInterval(determineGreeting, 60000);

    // Cleanup the interval to avoid memory leaks
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div className="pagecontainer scrollablearea">
        <div className="dashboard-logo">
          <div className="flex justify-center">
            <div className="animated-logo">
              <div className="animated-logo-360"></div>
            </div>
          </div>
        </div>
        <div className="welcome-heading">
          <h1>
            {greetingMessage}, <span>{userProfile.first_name}</span>{" "}
          </h1>
          <p>Welcome! to your C360 Portal</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;