
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  successMessage: null, 
  
}

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload.message;
      
    },
    unsetSuccessMessage: (state, action) => {
      state.successMessage = action.payload.message;
    },
  },
});

export const { setSuccessMessage,unsetSuccessMessage } = messageSlice.actions;

export default messageSlice.reducer;
