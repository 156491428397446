import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "../../assets/styles/Sidebar.css";
import { API_URL } from "../../services/Config";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import Expert from "../../assets/images/expert.svg";
import Iconanalytical from "../../assets/images/icon-analytical.svg";
import Iconcommudity from "../../assets/images/icon-commudity.svg";
import Icondashboard from "../../assets/images/icon-dashboard.svg";
import Icondatamanagement from "../../assets/images/icon-datamanagement.svg";
import Icontaxauthority from "../../assets/images/icon-tax-authority.svg";
import IconInformation from "../../assets/images/logo_information.svg";
import Vault from "../../assets/images/vault.svg";
import CloseIcon from "@mui/icons-material/Close";
import "../../assets/styles/responsive.css";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Typography,
  Grid,
  Button,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  Select,
  IconButton,
  MenuItem,
  InputLabel,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import { authAPI } from "../../services/authAPI";
import { setCurrencyList } from "../../reducers/userProfileSlice";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

let currencyData = [];
const Sidebar = ({ toggleSidebar, sidebarMinimized, ...restProps }) => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile);
  const access_token = useSelector((state) => state.auth.access_token);
  const currencyList = useSelector(
    (state) => state.userProfile.currencyList,
    shallowEqual
  );
  const [activatedPlans, setActivatedPlans] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadFileParams, setUploadFileParams] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [totalStorage] = useState(1073741824);
  const usedStorage = userProfile?.used_storage;
  const hasFetched = useRef(false);
  const calculatePercentage = () => {
    return (usedStorage / totalStorage) * 100;
  };

  const percentage = calculatePercentage();

  const [eoriSelection, setEoriSelection] = useState(
    userProfile && userProfile.eoris && userProfile.eoris.length > 0
      ? userProfile.eoris[0].eori
      : ""
  );

  const [currencyValue, setCurrencyValue] = useState("GBP");
  const fileInputRef = useRef(null);
  // const fileNameSelected = fileName?.toLowerCase()?.includes('import') ? 'import' : fileName?.toLowerCase()?.includes('export') ? 'export' : '';
  const [currencyError, setCurrencyError] = useState(false);
  const [eoriError, setEoriError] = useState(false);
  const [importError, setImportError] = useState(false);
  const [showDropArea, setShowDropArea] = useState(false); // New state for drop area visibility
  useEffect(() => {
    if (userProfile && userProfile.modules) {
      const moduleLabels = userProfile.modules.map(
        (module) => module.module_label
      );
      setActivatedPlans(moduleLabels);
    } else {
      console.log("No modules found in userprofile.");
      setActivatedPlans([]);
    }
  }, [userProfile, showPopup, currencyList]);
  const [loading, setLoading] = useState(false);

  // const handleDownloadData = async () => {
  //   try {
  //     setLoading(true); // Set loading to true when download starts

  //     const response = await fetch(
  //       `${API_URL}/api/data-manage/download-data/`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer ${access_token}`,
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Failed to download data");
  //     }

  //     const downloadFile = await response.json();
  //     if (downloadFile) {
  //       window.location.href = downloadFile?.url;
  //     }
  //   } catch (error) {
  //     toast.error(error.message);
  //   } finally {
  //     setLoading(false); // Set loading to false when download finishes
  //   }
  // };

  const handleFileSelectionAndSubmit = async () => {
    try {
      if (
        !fileInputRef.current ||
        !fileInputRef.current.files ||
        fileInputRef.current.files.length === 0
      ) {
        toast.error("Please select a file.");
        return;
      }

      const file = fileInputRef.current.files;
      setSelectedFile(Array.from(file));
      setShowPopup(true);

      setShowDropArea(false);
    } catch (error) {
      toast.error(error.error);
    }
  };

  const checkCurrencyAvailable = async () => {
    let checkCurrency = false;
    let checkImport = false;
    let checkEori = false;
    const data = [];
    for (let i = 0; i < selectedFile?.length; i++) {
      const params = uploadFileParams?.filter((param) => param?.index === i);
      if (params?.length > 0) {
        const param = params?.[0];
        data.push({ ...param, file: selectedFile?.[i] });
      }
      if (
        uploadFileParams?.[i]?.currency == "" ||
        uploadFileParams?.[i]?.currency == undefined ||
        uploadFileParams?.[i]?.currency == null
      ) {
        checkCurrency = true;
      }
      if (
        uploadFileParams?.[i]?.importExportSelection == "" ||
        uploadFileParams?.[i]?.importExportSelection == undefined ||
        uploadFileParams?.[i]?.importExportSelection == null
      ) {
        checkImport = true;
      }
      if (
        uploadFileParams?.[i]?.eoriSelection == "" ||
        uploadFileParams?.[i]?.eoriSelection == undefined ||
        uploadFileParams?.[i]?.eoriSelection == null
      ) {
        checkEori = true;
      }
    }
    return {
      checkCurrency: checkCurrency,
      checkImport: checkImport,
      checkEori: checkEori,
      data: data,
    };
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    let checkCurrency = await checkCurrencyAvailable();
    let r = 0
    if (checkCurrency?.checkCurrency) {
      setCurrencyError(true);
      r=1
    }  else{
      setCurrencyError(false)
    }
    // if (checkCurrency?.checkEori) {
    //   setEoriError(true);
    //   r=1
    // } else{
    //   setEoriError(false)
    // }
    if (checkCurrency?.checkImport) {
      setImportError(true);
      r=1
    }  else{
      setImportError(false)
    }
    if(r === 1){
      return false;
    }
    
    try {
      // Construct a FormData object with the file and additional information
      const formData = new FormData();
      checkCurrency?.data.forEach((data,index) => {
        formData.append(`data[${index}]file`, data?.file);
        formData.append(`data[${index}]importExportSelection`, data?.importExportSelection);
        formData.append(`data[${index}]eoriSelection`, data?.eoriSelection);
        formData.append(`data[${index}]currency`, data?.currency);
        // formData.append(`importExportSelection[${index}]`, data?.importExportSelection);
        // formData.append(`eoriSelection[${index}]`, data?.eoriSelection);
        // formData.append(`currency[${index}]`, data?.currency);
      });
      // formData.append("importExportSelection", data?.importExport);
      // formData.append("eoriSelection", eoriSelection);
      // formData.append("currency", currencyValue);

      // Make the API call
      setLoading(true);
      const response = await fetch(`${API_URL}/api/analytics/upload/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        body: formData,
      });
      
      if (response.ok) {
        const data = await response.json();
        if (data?.message) {
          window.location.href = "/data-manage";
        } else {
          toast.error(data.error);
        }
        setLoading('false')
      } else {
        const errorData = await response.json();
        setLoading('false')
        throw new Error(errorData.error);
      }
    } catch (error) {
      toast.error(error.error);
      setLoading('false')
    } finally {
      // Reset the file input value and close the popup
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setShowPopup(false);
      setSelectedFile(null);
      setUploadFileParams(null);
      setLoading('false')
    }
    
  };

  const CurrenciesList = useCallback(async () => {
    try {
      const countriesData = await authAPI.getCurrencies({ access_token });
      dispatch(setCurrencyList(countriesData));
      currencyData = countriesData;
    } catch (error) {
      console.error("Error fetching currencies list:", error);
    }
  }, [dispatch, access_token]);

  useEffect(() => {
    if (access_token && !hasFetched.current) {
      CurrenciesList();
      hasFetched.current = true;
    }
  }, [access_token, CurrenciesList]);

  const handleEoriChange = (event, index) => {
    //setEoriSelection(event.target.value);
    if (uploadFileParams?.length > 0) {
      const checkIndex = uploadFileParams?.filter(
        (param) => param?.index === index
      );
      if (checkIndex?.length > 0) {
        setUploadFileParams(
          uploadFileParams?.map((task) =>
            task.index === index
              ? { ...task, eoriSelection: event.target.value }
              : task
          )
        );
      } else {
        setUploadFileParams((prev) => [
          ...prev,
          { index: index, eoriSelection: event.target.value },
        ]);
      }
    } else {
      setUploadFileParams([
        { index: index, eoriSelection: event.target.value },
      ]);
    }
  };

  const handeFileToggleChange = (event, index) => {
    console.log(event?.target?.value, index, "index");
    if (uploadFileParams?.length > 0) {
      const checkIndex = uploadFileParams?.filter(
        (param) => param?.index === index
      );
      if (checkIndex?.length > 0) {
        setUploadFileParams(
          uploadFileParams?.map((task) =>
            task.index === index
              ? { ...task, importExportSelection: event.target.value }
              : task
          )
        );
      } else {
        setUploadFileParams((prev) => [
          ...prev,
          { index: index, importExportSelection: event.target.value },
        ]);
      }
    } else {
      setUploadFileParams([
        { index: index, importExportSelection: event.target.value },
      ]);
    }
  };

  const handleCurrency = (event, index) => {
    //setCurrencyValue(event.target.value);
    if (uploadFileParams?.length > 0) {
      const checkIndex = uploadFileParams?.filter(
        (param) => param?.index === index
      );
      if (checkIndex?.length > 0) {
        setUploadFileParams(
          uploadFileParams?.map((task) =>
            task.index === index
              ? { ...task, currency: event.target.value }
              : task
          )
        );
      } else {
        setUploadFileParams((prev) => [
          ...prev,
          { index: index, currency: event.target.value },
        ]);
      }
    } else {
      setUploadFileParams([{ index: index, currency: event.target.value }]);
    }
    CurrenciesList();
  };

  const cancelSelectedFile = (event) => {
    // Reset the file input field
    if (fileInputRef.current !== null) fileInputRef.current.value = "";
    setShowPopup(false);
    setSelectedFile(null);
    setUploadFileParams(null);
    setEoriSelection("");
    setCurrencyValue("");
    setCurrencyError(false);
    setEoriError(false);
    setImportError(false);
  };

  // Helper function to check if a plan is activated
  const isPlanActivated = (plan) => activatedPlans.includes(plan);

  const [activeMenu, setActiveMenu] = useState(null);

  const ToggleClass = (menu) => {
    setActiveMenu(activeMenu === menu ? null : menu);
  };

  const showDroppableArea = () => {
    setShowDropArea(true);
    // document.querySelector("input").addEventListener("cancel", (evt) => {
    //   if(showDropArea)
    //     setShowDropArea(false);
    // });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length >= 1) {
      setSelectedFile(Array.from(files));
      setShowPopup(true);
      setShowDropArea(false); // Hide the drop area
    }
    setShowDropArea(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      {showDropArea && (
        <div
          className="drop-area"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          // onDragLeave={() =>{
          //   setShowDropArea(false)
          // }}
        >
          <div className="dropAreainner">
            <IconButton
              aria-label="close"
              onClick={() => setShowDropArea(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "#1f1f1f",
              }}
            >
              <CloseIcon />
            </IconButton>
            <h6>Drop your file here </h6>
            <p>Or</p>
            <div>
              {" "}
              <div class="dropAreaInput">
                <button class="btn">Select file from here</button>
                <input
                  multiple
                  type="file"
                  accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .bs"
                  ref={fileInputRef}
                  onChange={handleFileSelectionAndSubmit}
                  style={{}}
                  id="fileInput"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <nav className="sidebar h-full">
        <div className="sitelogo">
          {/* Place your logo image here */}
          <div className="flex justify-center">
            <Link to="/">
              <div className="animated-logo">
                <div className="animated-logo-360"></div>
              </div>
            </Link>
          </div>
        </div>
        <ul>
          <li>
            <NavLink to="/" activeClassName="active">
              <img src={Icondashboard} alt="Dashboard" /> <span>HOME</span>
            </NavLink>
          </li>
          <li>
            {isPlanActivated("Data Management") && (
              <li className={activeMenu === "Data Management" ? "active" : ""}>
                <NavLink to="javascript:void(0)" activeClassName="active">
                  <img src={Icondatamanagement} alt="Data Management" />{" "}
                  <span>DATA MANAGEMENT</span>
                </NavLink>
                <button
                  onClick={() => ToggleClass("Data Management")}
                  className="dropdownToggle"
                >
                  <KeyboardArrowDownIcon sx={{ color: "#fff" }} />
                </button>
                <ul>
                  <li>
                    <div>
                      <a href="#">
                        Broker Data <small>Coming Soon</small>
                      </a>
                    </div>
                  </li>
                  {userProfile?.user_type === "is_client_admin"&& (
                    <>
                      <li>
                        <div>
                          <a href="/data-manage">Manage Uploaded Data</a>
                        </div>
                      </li>
                      {/* <li>
                        {loading && <div className="loader"></div>}
                        <a href='#' onClick={handleDownloadData}>
                          Download Data
                        </a>
                      </li> */}
                      <li>
                        <label
                          htmlFor="upload"
                          style={{ cursor: "pointer" }}
                          onClick={showDroppableArea}
                        >
                          Upload Data
                        </label>
                      </li>
                    </>
                  )}
                </ul>
              </li>
            )}
          </li>
          <li>
            {isPlanActivated("Analytical Tool") && (
              <li>
                <NavLink
                  to="/analyticals"
                  activeClassName="active"
                  className={"sidebar-toggle"}
                  onClick={toggleSidebar}
                >
                  <img src={Iconanalytical} alt="Analytical Tool" />{" "}
                  <span>ANALYTICAL TOOL</span>
                </NavLink>
              </li>
            )}
          </li>
          <li>
            {isPlanActivated("Commodity Search") && (
              <li className={activeMenu === "Commodity Search" ? "active" : ""}>
                <NavLink to="/commodity-search" activeClassName="active">
                  <img src={Iconcommudity} alt="Commodity Search" />{" "}
                  <span>COMMODITY CODES</span>
                </NavLink>
                <button
                  onClick={() => ToggleClass("Commodity Search")}
                  className="dropdownToggle"
                >
                  <KeyboardArrowDownIcon sx={{ color: "#fff" }} />
                </button>
                <ul>
                  <li>
                    <NavLink to="/commodity-search" activeClassName="active">
                      <a href="#">Commodity Search </a>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/commodity-search" activeClassName="active">
                      <a href="#">
                        Commodity Checker <small>Coming Soon</small>{" "}
                      </a>
                    </NavLink>
                    {/* <ul>
                  <li>
                    <a href="#">
                      UK Commodity Code Checker <small>Coming Soon</small>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      EU Commodity Code Checker
                      <small>Coming Soon</small>
                    </a>
                  </li>
                </ul> */}
                  </li>
                </ul>
              </li>
            )}
          </li>
          <li>
            {isPlanActivated("The Vault") && (
              <li>
                <NavLink
                  id={"the-vault"}
                  to="the-vault"
                  activeClassName="active"
                  className={"thevault sidebar-toggle"}
                  onClick={toggleSidebar}
                >
                  <img src={Vault} alt="valut" width="22" />
                  <span>THE VAULT </span>
                </NavLink>
              </li>
            )}
          </li>
          <li>
            {(isPlanActivated("Your Freight and Logistics experts") ||
              isPlanActivated("Partners")) && (
              <li className={activeMenu === "Partners" ? "active" : ""}>
                <NavLink to="javascript:void(0)">
                  <img src={Expert} alt="" width="" />
                  <span>PARTNERS</span>
                </NavLink>
                <button
                  onClick={() => ToggleClass("Partners")}
                  className="dropdownToggle"
                >
                  <KeyboardArrowDownIcon sx={{ color: "#fff" }} />
                </button>
                <ul>
                  <li>
                    <a href="#">
                      Export documentation
                      <small>PINCVISION WEBSHOP</small>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      Import declarations
                      <small>Partners TBA</small>
                    </a>
                  </li>
                </ul>
              </li>
            )}
          </li>
          <li>
            {isPlanActivated("Tax Authority") && (
              <li>
                <NavLink to="/tax-authority" activeClassName="active">
                  <img src={Icontaxauthority} alt="Tax Authority Tools" />{" "}
                  <span>TAX AUTHORITY TOOLS</span>
                </NavLink>
                <ul>
                  <li>
                    <a href="#">
                      EU <small>Coming Soon</small>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      UK <small>Coming Soon</small>
                    </a>
                    <ul>
                      <li>
                        <a href="#">
                          Duty Calculator <small>Coming Soon</small>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Origin Search <small>Coming Soon</small>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Quotas <small>Coming Soon</small>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            )}
          </li>
          <li>
            <NavLink to="help" activeClassName="active">
              <img src={IconInformation} alt="Information" />{" "}
              <span>INFORMATION</span>
            </NavLink>
            <ul>
              <li>
                <a href="#">Terms and Privacy</a>
              </li>
            </ul>
          </li>
        </ul>
        {userProfile.user_type !== "is_client_user" && (
          <div className="storage-outer">
            <h4>User Storage</h4>
            <p>
              Your team has used{" "}
              <div>
                <div
                  style={{
                    width: "100%", // Adjust width as needed
                    height: "12px", // Adjust height as needed
                    backgroundColor: "#f0f0f0", // Background color of the progress bar
                    border: "1px solid #ccc",
                    borderRadius: "20px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      width: `${percentage}%`, // Dynamically adjust width based on percentage
                      height: "100%",
                      backgroundColor: "#007bff", // Color of the progress bar
                      borderRadius: "4px",
                    }}
                  />
                </div>
                <div style={{ marginTop: "5px" }}>
                  {!isNaN(percentage) ? `${percentage.toFixed(2)}%` : "0%"}
                </div>
              </div>
              of storage from available Space.
            </p>
          </div>
        )}
      </nav>
      <div>
        <ToastContainer />
      </div>
      {showPopup && (
        <div className="uploaddata-modal overlay">
          {/* <div className="modal-dialog uploaddata-modal overlay"> */}
          <div className="modal-content max-w-[1000px] w-full p-0 rounded-lg overflow-hidden">
            <h2 className="text-2xl font-medium mb-4 bg-gray p-3 px-5">
              Upload Data
            </h2>
            <div className="p-7">
              <button
                className=" absolute right-5 top-3 close-btn"
                onClick={() => cancelSelectedFile()}
              >
                <svg
                  width="25"
                  height={25}
                  xmlns="http://www.w3.org/2000/svg"
                  enable-background="new 0 0 24 24"
                  viewBox="0 0 24 24"
                  id="close"
                >
                  <path
                    d="M13.4,12l6.3-6.3c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4
                        l6.3,6.3l-6.3,6.3C4.1,18.5,4,18.7,4,19c0,0.6,0.4,1,1,1c0.3,0,0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.4,0.3,0.7,0.3
                        s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L13.4,12z"
                  ></path>
                </svg>
              </button>
              <div>
              {loading && <div className="loader"></div>}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Typography variant="body1" sx={{ pb: "15px" }}>
                    You've selected file(s) to upload. Please confirm the type
                    of data before uploading.
                  </Typography>
                  <Grid sx={{overflow:'auto'}}>
                  {selectedFile?.length > 0 &&
                    selectedFile?.map((selectedF, index) => (
                      <Grid
                        container
                        key={`selFile${index}`}
                        spacing={2}
                        borderBottom={1}
                        borderColor="primary.main"
                        sx={{ pb: "15px",minWidth:'600px' }}
                      >
                        <Grid item xs={3}>
                          <Typography className="font-medium mb-4 w-[10rem]">
                            File Name
                          </Typography>
                          {selectedF?.name?.length > 30 ? (
                            <Tooltip title={selectedF} placement="top">
                              ...
                              {selectedF?.name?.substring(
                                selectedF?.name?.length - 30,
                                selectedF?.name?.length
                              )}
                            </Tooltip>
                          ) : (
                            selectedF?.name
                          )}
                        </Grid>
                        <Grid item xs={3}>
                          <Typography className="font-medium mb-3 w-[8rem]">
                            Import/Export
                          </Typography>
                          <FormControl>
                            <Controller
                              name="importExport"
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <>
                                  <ToggleButtonGroup
                                    color="primary"
                                    exclusive
                                    size="small"
                                    {...field}
                                    value={
                                      uploadFileParams?.[index]
                                        ?.importExportSelection || ""
                                    }
                                    aria-label="Import/Export"
                                    onChange={(e) =>
                                      handeFileToggleChange(e, index)
                                    }
                                  >
                                    <ToggleButton value="import">
                                      Import
                                    </ToggleButton>
                                    <ToggleButton value="export">
                                      Export
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                  {error && (
                                    <FormHelperText sx={{ color: "#ff0404" }}>
                                      {error.message}
                                    </FormHelperText>
                                  )}
                                </>
                              )}
                            />
                            {importError && (
                              <FormHelperText sx={{ color: "#ff0404" }}>
                                Please select a file type.
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography className="font-medium mb-3 w-[10rem]">
                            EORI Number
                          </Typography>
                          <FormControl>
                            <Controller
                              name="eoriSelection"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  size="small"
                                  {...field}
                                  placeholder="e.g. GB000000000000"
                                  variant="outlined"
                                  value={
                                    uploadFileParams?.[index]?.eoriSelection
                                  }
                                  onChange={(e) => handleEoriChange(e, index)}
                                />
                              )}
                            />
                            {eoriError && (
                              <FormHelperText sx={{ color: "#ff0404" }}>
                                Please select a valid EORI Number.
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography className="font-medium mb-3 w-[10rem]">
                            Data Currency
                          </Typography>
                          <FormControl fullWidth>
                            <InputLabel className="currency-label">Currency</InputLabel>
                            <Select
                              sx={{ minHeight: "40px !important" }}
                              size="small"
                              value={uploadFileParams?.[index]?.currency}
                              autoComplete="off"
                              label="Currency"
                              onChange={(e) => handleCurrency(e, index)}
                            >
                              {currencyData && currencyData?.length ? (
                                currencyData?.map((currency) => (
                                  <MenuItem
                                    className="columnlist"
                                    key={currency.currencyCode}
                                    value={currency.currencyCode}
                                  >
                                    {currency.name}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value="" disabled>
                                  Loading currencies...
                                </MenuItem>
                              )}
                            </Select>
                            {currencyError && (
                              <FormHelperText sx={{ color: "#ff0404" }}>
                                Please select a valid currency.
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    ))}
</Grid>
                  <Grid sx={{ float: "right", mt: "15px" }}>
                    <Button
                      onClick={() => cancelSelectedFile()}
                      variant="contained"
                      className="upload-cancel-button btn-cancel"
                      sx={{ backgroundColor: "#f36b1c", mr: "5px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      className="button-default"
                    >
                      Confirm
                    </Button>
                  </Grid>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
