import { US, GB, IN, EU, AE, AF, AZ, AR, AU, AW, BS, BB, BD, BY, BZ, BM, BO, BA, BW, BG, BR, BN, KH, KY, CL, CO, CA, CR, HR, CU, CZ, DO, EG, SV, FJ, GH, GT, HN, HK, HU, IS, ID, CN, DK, GY, IR, IL, JM, JP, KZ, KP, KG, LA, LB, LR, MK, MY, MU, MX, MN, MZ, NP, NZ, NI, NG, NO, OM, PK, PA, PY, PE, PH, PL, QA, RO, RU, SA, RS, SC, SG, SB, SO, KR, ZA, CH, SR, SY, TW, TH, LK, SE, TT, TR, UA, UY, UZ, VE, VN, YE, AL, AI, AG, AM, AO, AQ, AS, AT, AX, BE, BF, BH, BI, BL, BJ, BQ, BT, BV, CC, CD, CF, CG, CI, CK, CM, CV, CW, CX, CY, DE, DJ, DM, DZ, EC, EE, EH, ER, ES, 
         ET, FI, FK, FM, FO, FR, GA, FD, GE, GF, GD, GI, GL, GM, GN, GP, GQ, GR, GS, GU, GW, HM, HT, IE, IM, IO, IQ, IT, JE, JO, KE, KI, KM, KN, KW, LI, LS, LT, LU, LV, LY, MA, MC, MD, ME, MF, MG, MH, ML, MM, MO, MQ, MP, MR, MS, MT, MV, MW, NA, NC, NE, NF, NL, NR, NU, PF, PG, PM, PN, PR, PT, PW, RE, RW, SD, SH, SI, SJ, SK, SL, SM, SN, SS, ST, SZ, TC, TD, TF, TG, TJ, TK, TL, TM, TN, TO, TV, TZ, UG, UM, VA, VC, VG, VI, VU, WF, WS, YT, ZM, ZW, AD, GG, LC, SX
  } from 'country-flag-icons/react/3x2'; // Import flags

export const countryNameMapping = () => {
  return {
  'United States': 'United States of America',
  'BR': 'Brazil',
  'US': 'United States of America',
  'BG': 'Bulgaria',
  'AD': 'Andorra',
  'AE': 'United Arab Emirates',
  'AF': 'Afghanistan',
  'AG': 'Antigua and Barbuda',
  'AI': 'Anguilla',
  'AL': 'Albania',
  'AM': 'Armenia',
  'AO': 'Angola',
  'AQ': 'Antarctica',
  'AR': 'Argentina',
  'AS': 'American Samoa',
  'AT': 'Austria',
  'AU': 'Australia',
  'AW': 'Aruba',
  'AX': 'Aland Islands',
  'AZ': 'Azerbaijan',
  'BA': 'Bosnia and Herzegovina',
  'BB': 'Barbados',
  'BD': 'Bangladesh',
  'BE': 'Belgium',
  'BF': 'Burkina Faso',
  'BG': 'Bulgaria',
  'BH': 'Bahrain',
  'BI': 'Burundi',
  'BJ': 'Benin',
  'BL': 'Saint Barthelemy',
  'BM': 'Bermuda',
  'BN': 'Brunei',
  'BO': 'Bolivia',
  'BQ': 'Bonaire, Saint Eustatius and Saba',
  'BR': 'Brazil',
  'BS': 'Bahamas',
  'BT': 'Bhutan',
  'BV': 'Bouvet Island',
  'BW': 'Botswana',
  'BY': 'Belarus',
  'BZ': 'Belize',
  'CA': 'Canada',
  'CC': 'Cocos Islands',
  'CD': 'Democratic Republic of the Congo',
  'CF': 'Central African Republic',
  'CG': 'Republic of the Congo',
  'CH': 'Switzerland',
  'CI': 'Ivory Coast',
  'CK': 'Cook Islands',
  'CL': 'Chile',
  'CM': 'Cameroon',
  'CN': 'China',
  'CO': 'Colombia',
  'CR': 'Costa Rica',
  'CU': 'Cuba',
  'CV': 'Cabo Verde',
  'CW': 'Curacao',
  'CX': 'Christmas Island',
  'CY': 'Cyprus',
  'CZ': 'Czechia',
  'DE': 'Germany',
  'DJ': 'Djibouti',
  'DK': 'Denmark',
  'DM': 'Dominica',
  'DO': 'Dominican Republic',
  'DZ': 'Algeria',
  'EC': 'Ecuador',
  'EU': 'European Union',
  'EE': 'Estonia',
  'EG': 'Egypt',
  'EH': 'Western Sahara',
  'ER': 'Eritrea',
  'ES': 'Spain',
  'ET': 'Ethiopia',
  'FI': 'Finland',
  'FJ': 'Fiji',
  'FK': 'Falkland Islands',
  'FM': 'Micronesia',
  'FO': 'Faroe Islands',
  'FR': 'France',
  'GA': 'Gabon',
  'GB': 'United Kingdom',
  'GD': 'Grenada',
  'GE': 'Georgia',
  'GF': 'French Guiana',
  'GG': 'Guernsey',
  'GH': 'Ghana',
  'GI': 'Gibraltar',
  'GL': 'Greenland',
  'GM': 'Gambia',
  'GN': 'Guinea',
  'GP': 'Guadeloupe',
  'GQ': 'Equatorial Guinea',
  'GR': 'Greece',
  'GS': 'South Georgia and the South Sandwich Islands',
  'GT': 'Guatemala',
  'GU': 'Guam',
  'GW': 'Guinea-Bissau',
  'GY': 'Guyana',
  'HK': 'Hong Kong',
  'HM': 'Heard Island and McDonald Islands',
  'HN': 'Honduras',
  'HR': 'Croatia',
  'HT': 'Haiti',
  'HU': 'Hungary',
  'ID': 'Indonesia',
  'IE': 'Ireland',
  'IL': 'Israel',
  'IM': 'Isle of Man',
  'IN': 'India',
  'IO': 'British Indian Ocean Territory',
  'IQ': 'Iraq',
  'IR': 'Iran',
  'IS': 'Iceland',
  'IT': 'Italy',
  'JE': 'Jersey',
  'JM': 'Jamaica',
  'JO': 'Jordan',
  'JP': 'Japan',
  'KE': 'Kenya',
  'KG': 'Kyrgyzstan',
  'KH': 'Cambodia',
  'KI': 'Kiribati',
  'KM': 'Comoros',
  'KN': 'Saint Kitts and Nevis',
  'KP': 'North Korea',
  'KR': 'South Korea',
  'XK': 'Kosovo',
  'KW': 'Kuwait',
  'KY': 'Cayman Islands',
  'KZ': 'Kazakhstan',
  'LA': 'Laos',
  'LB': 'Lebanon',
  'LC': 'Saint Lucia',
  'LI': 'Liechtenstein',
  'LK': 'Sri Lanka',
  'LR': 'Liberia',
  'LS': 'Lesotho',
  'LT': 'Lithuania',
  'LU': 'Luxembourg',
  'LV': 'Latvia',
  'LY': 'Libya',
  'MA': 'Morocco',
  'MC': 'Monaco',
  'MD': 'Moldova',
  'ME': 'Montenegro',
  'MF': 'Saint Martin',
  'MG': 'Madagascar',
  'MH': 'Marshall Islands',
  'MK': 'North Macedonia',
  'ML': 'Mali',
  'MM': 'Myanmar',
  'MN': 'Mongolia',
  'MO': 'Macao',
  'MP': 'Northern Mariana Islands',
  'MQ': 'Martinique',
  'MR': 'Mauritania',
  'MS': 'Montserrat',
  'MT': 'Malta',
  'MU': 'Mauritius',
  'MV': 'Maldives',
  'MW': 'Malawi',
  'MX': 'Mexico',
  'MY': 'Malaysia',
  'MZ': 'Mozambique',
  'NA': 'Namibia',
  'NC': 'New Caledonia',
  'NE': 'Niger',
  'NF': 'Norfolk Island',
  'NG': 'Nigeria',
  'NI': 'Nicaragua',
  'NL': 'Netherlands',
  'NO': 'Norway',
  'NP': 'Nepal',
  'NR': 'Nauru',
  'NU': 'Niue',
  'NZ': 'New Zealand',
  'OM': 'Oman',
  'PA': 'Panama',
  'PE': 'Peru',
  'PF': 'French Polynesia',
  'PG': 'Papua New Guinea',
  'PH': 'Philippines',
  'PK': 'Pakistan',
  'PL': 'Poland',
  'PM': 'Saint Pierre and Miquelon',
  'PN': 'Pitcairn',
  'PR': 'Puerto Rico',
  'PS': 'Palestinian Territory',
  'PT': 'Portugal',
  'PW': 'Palau',
  'PY': 'Paraguay',
  'QA': 'Qatar',
  'RE': 'Reunion',
  'RO': 'Romania',
  'RS': 'Serbia',
  'RU': 'Russia',
  'RW': 'Rwanda',
  'SA': 'Saudi Arabia',
  'SB': 'Solomon Islands',
  'SC': 'Seychelles',
  'SD': 'Sudan',
  'SS': 'South Sudan',
  'SE': 'Sweden',
  'SG': 'Singapore',
  'SH': 'Saint Helena',
  'SI': 'Slovenia',
  'SJ': 'Svalbard and Jan Mayen',
  'SK': 'Slovakia',
  'SL': 'Sierra Leone',
  'SM': 'San Marino',
  'SN': 'Senegal',
  'SO': 'Somalia',
  'SR': 'Suriname',
  'ST': 'Sao Tome and Principe',
  'SV': 'El Salvador',
  'SX': 'Sint Maarten',
  'SY': 'Syria',
  'SZ': 'Eswatini',
  'TC': 'Turks and Caicos Islands',
  'TD': 'Chad',
  'TF': 'French Southern Territories',
  'TG': 'Togo',
  'TH': 'Thailand',
  'TJ': 'Tajikistan',
  'TK': 'Tokelau',
  'TL': 'Timor Leste',
  'TM': 'Turkmenistan',
  'TN': 'Tunisia',
  'TO': 'Tonga',
  'TR': 'Turkey',
  'TT': 'Trinidad and Tobago',
  'TV': 'Tuvalu',
  'TW': 'Taiwan',
  'TZ': 'Tanzania',
  'UA': 'Ukraine',
  'UG': 'Uganda',
  'UM': 'United States Minor Outlying Islands',
  'US': 'United States',
  'UY': 'Uruguay',
  'UZ': 'Uzbekistan',
  'VA': 'Vatican',
  'VC': 'Saint Vincent and the Grenadines',
  'VE': 'Venezuela',
  'VG': 'British Virgin Islands',
  'VI': 'U.S. Virgin Islands',
  'VN': 'Vietnam',
  'VU': 'Vanuatu',
  'WF': 'Wallis and Futuna',
  'WS': 'Samoa',
  'YE': 'Yemen',
  'YT': 'Mayotte',
  'ZA': 'South Africa',
  'ZM': 'Zambia',
  'ZW': 'Zimbabwe',
  'CS': 'Serbia and Montenegro',
  'XS': 'Serbia',
  'AN': 'Netherlands Antilles',
  };

}
export const currencyIcon = () => {
  return {
    USD: <US />,
    GBP: <GB />,
    ALL: <AL />,
    INR: <IN />,
    EUR: <EU />,
    AED: <AE />,
    AZN: <AZ />,
    AFN: <AF />,
    ARN: <AR />,
    AWG: <AW />,
    AUD: <AU />,
    BSD: <BS />,
    BBD: <BB />,
    BDT: <BD />,
    BYN: <BY />,
    BZD: <BZ />,
    BMD: <BM />,
    BOB: <BO />,
    BAM: <BA />,
    BWP: <BW />,
    BGN: <BG />,
    BRL: <BR />,
    BND: <BN />,
    KHR: <KH />,
    KYD: <KY />,
    CLP: <CL />,
    COP: <CO />,
    CRC: <CR />,
    CNY: <CN />,
    DKK: <DK />,
    HRK: <HR />,
    CUP: <CU />,
    CZK: <CZ />,
    CAD: <CA />,
    DOP: <DO />,
    EGP: <EG />,
    SVC: <SV />,
    FJD: <FJ />,
    GHS: <GH />,
    GTQ: <GT />,
    GYD: <GY />,
    HNL: <HN />,
    HKD: <HK />,
    HUF: <HU />,
    ISK: <IS />,
    IDR: <ID />,
    IRR: <IR />,
    ILS: <IL />,
    JMD: <JM />,
    JPY: <JP />,
    KZT: <KZ />,
    KPW: <KP />,
    KGS: <KG />,
    LAK: <LA />,
    LBP: <LB />,
    LRD: <LR />,
    MKD: <MK />,
    MYR: <MY />,
    MUR: <MU />,
    MXN: <MX />,
    MNT: <MN />,
    MZN: <MZ />,
    NPR: <NP />,
    NZD: <NZ />,
    NIO: <NI />,
    NGN: <NG />,
    NOK: <NO />,
    OMR: <OM />,
    PKR: <PK />,
    PAB: <PA />,
    PYG: <PY />,
    PEN: <PE />,
    PHP: <PH />,
    PLN: <PL />,
    QAR: <QA />,
    RON: <RO />,
    RUB: <RU />,
    SAR: <SA />,
    RSD: <RS />,
    SCR: <SC />,
    SGD: <SG />,
    SBD: <SB />,
    SOS: <SO />,
    KRW: <KR />,
    ZAR: <ZA />,
    LKR: <LK />,
    SEK: <SE />,
    CHF: <CH />,
    SRD: <SR />,
    SYP: <SY />,
    TWD: <TW />,
    THB: <TH />,
    TTD: <TT />,
    TRY: <TR />,
    UAH: <UA />,
    UYU: <UY />,
    UZS: <UZ />,
    VEF: <VE />,
    VND: <VN />,
    YER: <YE />,
  };
}
export const countryIcon = () => {
  return {
    US: <US />,
    GB: <GB />,
    AL: <AL />,
    IN: <IN />,
    EU: <EU />,
    AE: <AE />,
    AZ: <AZ />,
    AF: <AF />,
    AR: <AR />,
    AW: <AW />,
    AU: <AU />,
    BS: <BS />,
    BB: <BB />,
    BD: <BD />,
    BY: <BY />,
    BZ: <BZ />,
    BM: <BM />,
    BO: <BO />,
    BA: <BA />,
    BW: <BW />,
    BG: <BG />,
    BR: <BR />,
    BN: <BN />,
    KH: <KH />,
    KY: <KY />,
    CL: <CL />,
    CO: <CO />,
    CR: <CR />,
    CN: <CN />,
    DK: <DK />,
    HR: <HR />,
    CU: <CU />,
    CZ: <CZ />,
    CA: <CA />,
    DO: <DO />,
    EG: <EG />,
    SV: <SV />,
    FJ: <FJ />,
    GH: <GH />,
    GT: <GT />,
    GY: <GY />,
    HN: <HN />,
    HK: <HK />,
    HU: <HU />,
    IS: <IS />,
    ID: <ID />,
    IR: <IR />,
    IL: <IL />,
    JM: <JM />,
    JP: <JP />,
    KZ: <KZ />,
    KP: <KP />,
    KG: <KG />,
    LA: <LA />,
    LB: <LB />,
    LR: <LR />,
    MK: <MK />,
    MY: <MY />,
    MU: <MU />,
    MX: <MX />,
    MN: <MN />,
    MZ: <MZ />,
    NP: <NP />,
    NZ: <NZ />,
    NI: <NI />,
    NG: <NG />,
    NO: <NO />,
    OM: <OM />,
    PK: <PK />,
    PA: <PA />,
    PY: <PY />,
    PE: <PE />,
    PH: <PH />,
    PL: <PL />,
    QA: <QA />,
    RO: <RO />,
    RU: <RU />,
    SA: <SA />,
    RS: <RS />,
    SC: <SC />,
    SG: <SG />,
    SB: <SB />,
    SO: <SO />,
    KR: <KR />,
    ZA: <ZA />,
    LK: <LK />,
    SE: <SE />,
    CH: <CH />,
    SR: <SR />,
    SY: <SY />,
    TW: <TW />,
    TH: <TH />,
    TT: <TT />,
    TR: <TR />,
    UA: <UA />,
    UY: <UY />,
    UZ: <UZ />,
    VE: <VE />,
    VN: <VN />,
    YE: <YE />,
    AI: <AI />,
    AG: <AG />,
    AM: <AM />,
    AO: <AO />,
    AQ: <AQ />,
    AS: <AS />,
    AT: <AT />,
    AX: <AX />,
    BE: <BE />,
    BF: <BF />,
    BH: <BH />,
    BI: <BI />,
    BJ: <BJ />,
    BL: <BL />,
    BQ: <BQ />,
    BT: <BT />,
    BV: <BV />,
    CC: <CC />,
    CD: <CD />,
    AD: <AD />,
    CF: <CF />,
    CG: <CG />,
    CI: <CI />,
    CK: <CK />,
    CM: <CM />,
    CV: <CV />,
    CW: <CW />,
    CX: <CX />,
    CY: <CY />,
    DE: <DE />,
    DZ: <DZ />,
    EC: <EC />,
    EE: <EE />,
    EH: <EH />,
    ER: <ER />,
    ES: <ES />,
    ET: <ET />,
    FI: <FI />,
    FK: <FK />,
    FM: <FM />,
    FO: <FO />,
    FR: <FR />,
    GA: <GA />,
    GD: <GD />,
    GE: <GE />,
    GF: <GF />,
    GG: <GG />,
    GI: <GI />,
    GL: <GL />,
    GM: <GM />,
    GN: <GN />,
    GP: <GP />,
    GQ: <GQ />,
    GR: <GR />,
    GS: <GS />,
    GU: <GU />,
    GW: <GW />,
    HM: <HM />,
    HT: <HT />,
    IE: <IE />,
    IM: <IM />,
    IO: <IO />,
    IQ: <IQ />,
    IT: <IT />,
    JE: <JE />,
    JO: <JO />,
    KE: <KE />,
    KM: <KM />,
    KN: <KN />,
    KW: <KW />,
    LC: <LC />,
    LI: <LI />,
    LS: <LS />,
    LT: <LT />,
    LU: <LU />,
    LV: <LV />,
    LY: <LY />,
    MA: <MA />,
    MC: <MC />,
    MD: <MD />,
    ME: <ME />,
    MF: <MF />,
    MG: <MG />,
    MH: <MH />,
    ML: <ML />,
    MM: <MM />,
    MO: <MO />,
    MQ: <MQ />,
    MR: <MR />,
    MS: <MS />,
    MT: <MT />,
    MV: <MV />,
    MW: <MW />,
    NA: <NA />,
    NC: <NC />,
    NE: <NE />,
    NF: <NF />,
    NL: <NL />,
    NR: <NR />,
    NU: <NU />,
    PF: <PF />,
    PG: <PG />,
    PM: <PM />,
    PN: <PN />,
    PR: <PR />,
    PT: <PT />,
    PW: <PW />,
    RE: <RE />,
    RW: <RW />,
    SD: <SD />,
    SH: <SH />,
    SI: <SI />,
    SJ: <SJ />,
    SK: <SK />,
    SL: <SL />,
    SM: <SM />,
    SN: <SN />,
    SS: <SS />,
    ST: <ST />,
    SX: <SX />,
    SZ: <SZ />,
    TD: <TD />,
    TC: <TC />,
    TF: <TF />,
    TG: <TG />,
    TJ: <TJ />,
    TK: <TK />,
    TL: <TL />,
    TM: <TM />,
    TN: <TN />,
    TO: <TO />,
    TV: <TV />,
    TZ: <TZ />,
    UG: <UG />,
    UM: <UM />,
    VA: <VA />,
    VC: <VC />,
    VG: <VG />,
    VI: <VI />,
    VU: <VU />,
    WF: <WF />,
    WS: <WS />,
    YT: <YT />,
    ZM: <ZM />,
    ZW: <ZW />,
    KI: <KI />,
    // LG: <LG />,
    MP: <MP />,
    DJ: <DJ />,
    DM: <DM />,
  };
}
export const countryList = () => {
  return {
  'United States': 'United States of America',
  'BR': 'Brazil',
  'US': 'United States of America',
  'BG': 'Bulgaria',
  'AD': 'Andorra',
  'AE': 'United Arab Emirates',
  'AF': 'Afghanistan',
  'AG': 'Antigua and Barbuda',
  'AI': 'Anguilla',
  'AL': 'Albania',
  'AM': 'Armenia',
  'AO': 'Angola',
  'AQ': 'Antarctica',
  'AR': 'Argentina',
  'AS': 'American Samoa',
  'AT': 'Austria',
  'AU': 'Australia',
  'AW': 'Aruba',
  'AX': 'Aland Islands',
  'AZ': 'Azerbaijan',
  'BA': 'Bosnia and Herzegovina',
  'BB': 'Barbados',
  'BD': 'Bangladesh',
  'BE': 'Belgium',
  'BF': 'Burkina Faso',
  'BG': 'Bulgaria',
  'BH': 'Bahrain',
  'BI': 'Burundi',
  'BJ': 'Benin',
  'BL': 'Saint Barthelemy',
  'BM': 'Bermuda',
  'BN': 'Brunei',
  'BO': 'Bolivia',
  'BQ': 'Bonaire, Saint Eustatius and Saba',
  'BR': 'Brazil',
  'BS': 'Bahamas',
  'BT': 'Bhutan',
  'BV': 'Bouvet Island',
  'BW': 'Botswana',
  'BY': 'Belarus',
  'BZ': 'Belize',
  'CA': 'Canada',
  'CC': 'Cocos Islands',
  'CD': 'Democratic Republic of the Congo',
  'CF': 'Central African Republic',
  'CG': 'Republic of the Congo',
  'CH': 'Switzerland',
  'CI': 'Ivory Coast',
  'CK': 'Cook Islands',
  'CL': 'Chile',
  'CM': 'Cameroon',
  'CN': 'China',
  'CO': 'Colombia',
  'CR': 'Costa Rica',
  'CU': 'Cuba',
  'CV': 'Cabo Verde',
  'CW': 'Curacao',
  'CX': 'Christmas Island',
  'CY': 'Cyprus',
  'CZ': 'Czechia',
  'DE': 'Germany',
  'DJ': 'Djibouti',
  'DK': 'Denmark',
  'DM': 'Dominica',
  'DO': 'Dominican Republic',
  'DZ': 'Algeria',
  'EC': 'Ecuador',
  'EE': 'Estonia',
  'EG': 'Egypt',
  'EH': 'Western Sahara',
  'ER': 'Eritrea',
  'ES': 'Spain',
  'ET': 'Ethiopia',
  'FI': 'Finland',
  'FJ': 'Fiji',
  'FK': 'Falkland Islands',
  'FM': 'Micronesia',
  'FO': 'Faroe Islands',
  'FR': 'France',
  'GA': 'Gabon',
  'GB': 'United Kingdom',
  'GD': 'Grenada',
  'GE': 'Georgia',
  'GF': 'French Guiana',
  'GG': 'Guernsey',
  'GH': 'Ghana',
  'GI': 'Gibraltar',
  'GL': 'Greenland',
  'GM': 'Gambia',
  'GN': 'Guinea',
  'GP': 'Guadeloupe',
  'GQ': 'Equatorial Guinea',
  'GR': 'Greece',
  'GS': 'South Georgia and the South Sandwich Islands',
  'GT': 'Guatemala',
  'GU': 'Guam',
  'GW': 'Guinea-Bissau',
  'GY': 'Guyana',
  'HK': 'Hong Kong',
  'HM': 'Heard Island and McDonald Islands',
  'HN': 'Honduras',
  'HR': 'Croatia',
  'HT': 'Haiti',
  'HU': 'Hungary',
  'ID': 'Indonesia',
  'IE': 'Ireland',
  'IL': 'Israel',
  'IM': 'Isle of Man',
  'IN': 'India',
  'IO': 'British Indian Ocean Territory',
  'IQ': 'Iraq',
  'IR': 'Iran',
  'IS': 'Iceland',
  'IT': 'Italy',
  'JE': 'Jersey',
  'JM': 'Jamaica',
  'JO': 'Jordan',
  'JP': 'Japan',
  'KE': 'Kenya',
  'KG': 'Kyrgyzstan',
  'KH': 'Cambodia',
  'KI': 'Kiribati',
  'KM': 'Comoros',
  'KN': 'Saint Kitts and Nevis',
  'KP': 'North Korea',
  'KR': 'South Korea',
  'XK': 'Kosovo',
  'KW': 'Kuwait',
  'KY': 'Cayman Islands',
  'KZ': 'Kazakhstan',
  'LA': 'Laos',
  'LB': 'Lebanon',
  'LC': 'Saint Lucia',
  'LI': 'Liechtenstein',
  'LK': 'Sri Lanka',
  'LR': 'Liberia',
  'LS': 'Lesotho',
  'LT': 'Lithuania',
  'LU': 'Luxembourg',
  'LV': 'Latvia',
  'LY': 'Libya',
  'MA': 'Morocco',
  'MC': 'Monaco',
  'MD': 'Moldova',
  'ME': 'Montenegro',
  'MF': 'Saint Martin',
  'MG': 'Madagascar',
  'MH': 'Marshall Islands',
  'MK': 'North Macedonia',
  'ML': 'Mali',
  'MM': 'Myanmar',
  'MN': 'Mongolia',
  'MO': 'Macao',
  'MP': 'Northern Mariana Islands',
  'MQ': 'Martinique',
  'MR': 'Mauritania',
  'MS': 'Montserrat',
  'MT': 'Malta',
  'MU': 'Mauritius',
  'MV': 'Maldives',
  'MW': 'Malawi',
  'MX': 'Mexico',
  'MY': 'Malaysia',
  'MZ': 'Mozambique',
  'NA': 'Namibia',
  'NC': 'New Caledonia',
  'NE': 'Niger',
  'NF': 'Norfolk Island',
  'NG': 'Nigeria',
  'NI': 'Nicaragua',
  'NL': 'Netherlands',
  'NO': 'Norway',
  'NP': 'Nepal',
  'NR': 'Nauru',
  'NU': 'Niue',
  'NZ': 'New Zealand',
  'OM': 'Oman',
  'PA': 'Panama',
  'PE': 'Peru',
  'PF': 'French Polynesia',
  'PG': 'Papua New Guinea',
  'PH': 'Philippines',
  'PK': 'Pakistan',
  'PL': 'Poland',
  'PM': 'Saint Pierre and Miquelon',
  'PN': 'Pitcairn',
  'PR': 'Puerto Rico',
  'PS': 'Palestinian Territory',
  'PT': 'Portugal',
  'PW': 'Palau',
  'PY': 'Paraguay',
  'QA': 'Qatar',
  'RE': 'Reunion',
  'RO': 'Romania',
  'RS': 'Serbia',
  'RU': 'Russia',
  'RW': 'Rwanda',
  'SA': 'Saudi Arabia',
  'SB': 'Solomon Islands',
  'SC': 'Seychelles',
  'SD': 'Sudan',
  'SS': 'South Sudan',
  'SE': 'Sweden',
  'SG': 'Singapore',
  'SH': 'Saint Helena',
  'SI': 'Slovenia',
  'SJ': 'Svalbard and Jan Mayen',
  'SK': 'Slovakia',
  'SL': 'Sierra Leone',
  'SM': 'San Marino',
  'SN': 'Senegal',
  'SO': 'Somalia',
  'SR': 'Suriname',
  'ST': 'Sao Tome and Principe',
  'SV': 'El Salvador',
  'SX': 'Sint Maarten',
  'SY': 'Syria',
  'SZ': 'Eswatini',
  'TC': 'Turks and Caicos Islands',
  'TD': 'Chad',
  'TF': 'French Southern Territories',
  'TG': 'Togo',
  'TH': 'Thailand',
  'TJ': 'Tajikistan',
  'TK': 'Tokelau',
  'TL': 'Timor Leste',
  'TM': 'Turkmenistan',
  'TN': 'Tunisia',
  'TO': 'Tonga',
  'TR': 'Turkey',
  'TT': 'Trinidad and Tobago',
  'TV': 'Tuvalu',
  'TW': 'Taiwan',
  'TZ': 'Tanzania',
  'UA': 'Ukraine',
  'UG': 'Uganda',
  'UM': 'United States Minor Outlying Islands',
  'US': 'United States',
  'UY': 'Uruguay',
  'UZ': 'Uzbekistan',
  'VA': 'Vatican',
  'VC': 'Saint Vincent and the Grenadines',
  'VE': 'Venezuela',
  'VG': 'British Virgin Islands',
  'VI': 'U.S. Virgin Islands',
  'VN': 'Vietnam',
  'VU': 'Vanuatu',
  'WF': 'Wallis and Futuna',
  'WS': 'Samoa',
  'YE': 'Yemen',
  'YT': 'Mayotte',
  'ZA': 'South Africa',
  'ZM': 'Zambia',
  'ZW': 'Zimbabwe',
  'CS': 'Serbia and Montenegro',
  'XS': 'Serbia',
  'AN': 'Netherlands Antilles',
  };
};


