import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Card, Grid, Typography, IconButton, Button, Dialog } from '@mui/material';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import BarChart3DComponent from './Charts/BarChart3DComponent';
import GaugeChart from './Charts/GaugeChart';
import InfoDisplay from './Charts/InfoDisplay';
import TableDataChart from './Charts/TableDataChart';
import LineChartComponent from './Charts/lineChartComponent';
import MapNewComponent from './Charts/newMapChart';
import LoupeIcon from '@mui/icons-material/Loupe';
import CloseIcon from '@mui/icons-material/Close';
import { useGlobalContext } from '../../hook/globalstate';
import dayjs from 'dayjs';
import { countryNameMapping } from './AnalyticalCommon';


const DynamicGrid = ({ data, itemTitle, reportFormattedDate, filters, page = '' }) => {
  const title = localStorage.getItem('selectedReportTitle')
  const reportTitle = (title != undefined && title != null && title != '') ? title : itemTitle;

  const [filterValue, setFilterValue] = useState(filters || []);
  const { states, setState } = useGlobalContext()
  const countryName = countryNameMapping();

  useEffect(() => {
    const extractWidgetNames = (data) => {
      const names = [];
      if (Array.isArray(data)) {
        data?.forEach((section) => {
          section.forEach((widget) => {
            if (widget.name) {
              names.push(widget.name);
            }
          });
        });
      }
      return names;
    };

    const names = extractWidgetNames(data);
    localStorage.setItem('widgetNames', names);
  }, [data]);

  useEffect(() => {
    if (Array.isArray(data)) {
      data?.forEach(widgetData => {
        widgetData?.forEach(widgetProperties => {    
          let settingData = {
            type: "settingData",
            payload: {
              [`${widgetProperties?.properties[5]?.value?.value}-${widgetProperties?.properties[6]?.value?.value}`]: {  // Use template literals to dynamically set the key
                label:widgetProperties?.properties[0]?.value?.value,
                hideLabel: widgetProperties?.properties[1]?.value?.value,
                keyLabel: widgetProperties?.properties[2]?.value?.value, 
                valueLabel: widgetProperties?.properties[3]?.value?.value, 
                logarithmicScale: widgetProperties?.properties[4]?.value?.value,
                settingId: widgetProperties?.properties[5]?.value?.value,
                dropKey: widgetProperties?.properties[6]?.value?.value,
              }
            }
          };
          
          setState(settingData);
        });
      });
    }

    fetchFilters()
  }, [])

  const fetchFilters = () => {
    const f = window.localStorage.getItem('filters')
    setFilterValue(JSON.parse(f))
  }
  useEffect(() => {
    window.addEventListener('filterUpdate', fetchFilters);

    return () => {
      window.removeEventListener('filterUpdate', fetchFilters);
    };
  }, []);

  const handleRemoveFilter = (index) => {
    const newFilters = filterValue.filter((_, i) => i !== index);
    setFilterValue(newFilters)
    window.localStorage.setItem('filters', JSON.stringify(newFilters));
    window.dispatchEvent(new Event('filterUpdate'));
  };


  const [showModal, setShowModal] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const [modalTitle, setModalTile] = useState(null); 
  const [modalHelpText, setModalHelpText] = useState(null); 


  const handleOpenModal = (itemDrop) => {
    setModalItem(itemDrop?.name);
    if (itemDrop?.properties?.length > 0) {
      const label = itemDrop.properties[0].value.value;
      const hidelabel = itemDrop.properties[1]?.value?.value;
      
      if (hidelabel == true) {
        setModalTile("");
      } else if (label !== "") {
        setModalTile(label);
      } else {
        setModalTile(itemDrop?.wName || "");
      }
    } else {
      setModalTile(itemDrop?.wName || "");
    }
    
    setModalHelpText(itemDrop?.helpText);
    setShowModal(true);
  }
  
  const handleCloseModal = () => {
    setShowModal(false);
    setModalItem(null);
    setModalTile(null);
    setModalHelpText(null); // Clear the item when the modal closes
  }



  if (!Array.isArray(data) || data?.length === 0) {
    return (
      <>
        <Box sx={{ flexGrow: 1, padding: 1 }} className="widgetView">
          <Box className="page-heading">
            <h1>
              {reportFormattedDate ? `${reportTitle} ${reportFormattedDate}` : ""} 
            </h1>
          </Box>
        </Box>
        <Typography variant="body1" sx={{ textAlign: 'center', padding: 2 }}>
          No data available
        </Typography>
      </>
    );
  }


  return (
    <Box sx={{ flexGrow: 1, padding: 1 }} className="widgetView">
      <Box className="page-heading">
        <h1>
          {reportTitle} {reportFormattedDate}
        </h1>
      </Box>
      <Box sx={{ pageBreakInside: 'auto', flexWrap: 'wrap' }}>
        {filterValue?.length > 0 ? (
          <Card sx={{ marginTop: '0' }}>
            {filterValue.map((filter, index) => (
              <Typography
                variant="body1"
                key={index}
                sx={{ padding: '5px 0px 5px 12px', fontSize: '14px' }}
              >
                Report Filters:
                {page !== 'report' ?
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveFilter(index)}
                    sx={{ float: "none" }}
                  >
                    <CancelIcon fontSize="small" sx={{ color: "#1a425f" }} />
                  </IconButton>
                  : <span>&emsp;</span>}
                {filter?.columnDisplay}: 
                <strong>
                { filter?.columnDisplay === "Dispatch Country" || filter?.columnDisplay === "Origin Country" ? countryName[filter?.value?.value] : filter?.value?.value }
                </strong>
                {filter?.value?.dateType === 'lastmonth' ? (
                  <strong> Last Month</strong>
                ) : filter?.value?.dateType === 'thisquarter' ? (
                  <strong> This Quarter</strong>
                ) : filter?.value?.dateType === 'lastquarter' ? (
                  <strong> Last Quarter</strong>
                ) : filter?.value?.dateType === 'thisyear' ? (
                  <strong> This Year</strong>
                ) : filter?.value?.dateType === 'lastyear' ? (
                  <strong> Last Year</strong>
                ) : (
                  <strong> {filter?.value?.dateType}</strong>
                )}
                {filter?.value?.startDate && filter?.value?.endDate && (
                  <strong> : {dayjs(filter?.value?.startDate).format('DD/MM/YYYY')} to {dayjs(filter?.value?.endDate).format('DD/MM/YYYY')}
                  </strong>
                )}
              </Typography>
            ))}
          </Card>
        ) : (
          ''
          // <Typography variant='body1' sx={{ padding: '10px 0px 10px 12px', fontSize: '14px' }}>
          //   {viewCurrency ? `Currency: ${viewCurrency}` : 'No filters set'}
          // </Typography>
        )}

        {data?.map((row, rowIndex) => (
          <Grid container spacing={2} key={rowIndex} sx={{ marginTop: '0px' }} className='pageBreak'>
            {row.map((item, itemIndex) => (
              <Grid item xs={12} sm={12} md={12 / row.length} key={itemIndex} sx={{ marginBottom: '5px' }} wrap={false} className='pageBreakinside'>
                <Box
                  sx={{
                    borderRadius: 5,
                    textAlign: 'center'
                  }}
                >
                    <Button onClick={(e) => handleOpenModal(item)} className='zoomWidgetIcon btn-delete mat-focus-indicator mat-tooltip-trigger mat-icon-button mat-button-base' variant='text'>
                      <LoupeIcon sx={{ color: '#000000' }} />
                    </Button>
                  {
                    ['widgets.imports.edr', 'widgets.imports.edrOnDutiable', 'widgets.imports.totalImports', 'widgets.imports.totalVatPaid', 'widgets.imports.totalVatValue', 'widgets.exports.exportsByEntryNo', 'widgets.imports.totalDutyPaid'].includes(item.name) &&
                    <InfoDisplay widgetName={item.name} widgetTitle={item?.wName} widgetData={item?.result} itemTitle={itemTitle} helpText={item?.helpText} dropKey={item?.properties[6]?.value?.value} settingId = {item?.properties[5]?.value?.value} />
                  }

                  {['widgets.imports.zeroCommodityCodes','widgets.imports.countryorigincomparedcountrydispatch', 'widgets.imports.dutyPaidByCurrency'].includes(item.name) &&
                    <TableDataChart widgetName={item.name} widgetTitle={item?.wName} widgetData={item?.result} helpText={item?.helpText} dropKey={item?.properties[6]?.value?.value} settingId = {item?.properties[5]?.value?.value} />
                  }

                  {['widgets.imports.totalCommodityCodesUsed', 'widgets.imports.edrbymonth', 'widgets.imports.importVatValue', 'widgets.imports.edrbymonthOnDutiable'].includes(item.name) &&
                    <LineChartComponent widgetName={item.name} widgetTitle={item?.wName} widgetData={item?.result} helpText={item?.helpText} dropKey={item?.properties[6]?.value?.value} settingId = {item?.properties[5]?.value?.value} />
                  }

                  {[
                    'widgets.imports.totalvatposp', 'widgets.imports.topCommodityCodesByDutyPaid', 'widgets.imports.suppliersInMultipleCurrencies', 'widgets.exports.totalcpc', 'widgets.imports.bottomCommodityCodesByDutyPaid', 'widgets.imports.topCommodityCodesByValue', 'widgets.imports.bottomCommodityCodesByValue', 'widgets.imports.averageClearancePerGdsLocn', 'widgets.imports.topCurrenciesByImportVal', 'widgets.imports.topCurrenciesByDutyPaid', 'widgets.imports.topDeclarantsByCustVal', 'widgets.imports.topDeclarants', 'widgets.imports.importsByPref', 'widgets.imports.importsByCpc', 'widgets.imports.topSuppliersByDutyPaid', 'widgets.imports.topSuppliersByImportVal', 'widgets.imports.bottomSuppliersByImportVal', 'widgets.imports.importValByCpc', 'widgets.imports.importValByPref', 'widgets.imports.topSuppliersByEntryNosPerEntry', 'widgets.imports.topSuppliersByLineEntry', 'widgets.imports.suppliersByDispCtry', 'widgets.imports.suppliersByOrigCtry', 'widgets.imports.dutyRateByDispCtr', 'widgets.imports.dutyRateByDispCtry', 'widgets.imports.dutyRateByOrigCtry', 'widgets.exports.topCommodityCodesByValue', 'widgets.exports.bottomCommodityCodesByValue', 'widgets.exports.topCommCodes', 'widgets.exports.bottomCommCodes', 'widgets.exports.topCurrenciesByVolume', 'widgets.exports.topConsigneesInMultipleCurrencies', 'widgets.exports.exportsByConsignee', 'widgets.imports.importCountByEpu'
                  ].includes(item.name) &&
                    <BarChart3DComponent widgetName={item.name} widgetTitle={item?.wName} widgetData={item?.result} helpText={item?.helpText} dropKey={item?.properties[6]?.value?.value} settingId = {item?.properties[5]?.value?.value} />
                  }

                  {['widgets.imports.totalVatValuevsVatPaid',].includes(item.name) &&
                    <GaugeChart widgetName={item.name} widgetTitle={item?.wName} widgetData={item?.result} helpText={item?.helpText} dropKey={item?.properties[6]?.value?.value} settingId = {item?.properties[5]?.value?.value} />
                  }

                  {[
                    "widgets.imports.dutyPaidByDispCtry", "widgets.imports.dutyPaidByOrigCtry", "widgets.imports.importValByDispCtry", "widgets.imports.importValByOrigCtry", "widgets.imports.declarantsByDispCtry", "widgets.imports.declarantsByOrigCtry", "widgets.imports.importsByDispCtry", "widgets.exports.commodityCodesPerDestCtry", "widgets.exports.noOfExportsGoingToDestinations", "widgets.imports.importsByOrigCtry"
                  ].includes(item.name) &&
                    <MapNewComponent data={data} widgetName={item.name} widgetTitle={item?.wName} widgetData={item?.result} helpText={item?.helpText} dropKey={item?.properties[6]?.value?.value} settingId = {item?.properties[5]?.value?.value} />
                  }
                </Box>
              </Grid>
            ))}
          </Grid>
        ))}
      </Box>
      <Dialog
        open={showModal}
        contentLabel="Minimal Modal Example"
        className='reportModal'
      >
        <Button onClick={handleCloseModal} className='btn-delete ' variant='text'>
          <CloseIcon sx={{ color: '#000' }} />
        </Button>

        <>
          <div className="reportModal">
            <Grid className='map-container'>
              {/* InfoDisplay chart  start*/}
              {((modalItem === 'widgets.imports.edr') || //c
                modalItem === 'widgets.imports.edrOnDutiable' || //c
                modalItem === 'widgets.imports.totalImports' || //c
                modalItem === 'widgets.imports.totalVatPaid' || //c
                modalItem === 'widgets.imports.totalVatValue' || //c
                modalItem === 'widgets.exports.exportsByEntryNo' || //c
                modalItem === 'widgets.imports.totalDutyPaid' || //c
                modalItem === 'widgets.imports.averageClearance'
              ) && <InfoDisplay widgetName={modalItem} widgetTitle={modalTitle} helpText={modalHelpText}  />}
              {/* InfoDisplay chart end */}

              {/* TableDataChart chart  */}
              {(

                modalItem === 'widgets.imports.zeroCommodityCodes' ||
                modalItem === 'widgets.imports.dutyPaidByCurrency' ||
                modalItem === 'widgets.imports.countryorigincomparedcountrydispatch'
              ) && <TableDataChart widgetName={modalItem} widgetTitle={modalTitle}  helpText={modalHelpText}  />}
              {/* TableDataChart chart end */}

              {/* LineChart chart  */}
              {(modalItem === 'widgets.imports.edrbymonth' || //c
                modalItem === 'widgets.imports.edrbymonthOnDutiable'
              ) && <LineChartComponent widgetName={modalItem} widgetTitle={modalTitle}  helpText={modalHelpText}  />}
              {/* LineChart chart  end*/}

              {/* BarChart3DComponent chart  */}
              {(
                modalItem === 'widgets.imports.topCommodityCodesByDutyPaid' || //c
                modalItem === 'widgets.imports.bottomCommodityCodesByDutyPaid' || //c
                modalItem === 'widgets.imports.topDeclarantsByCustVal' || //c
                modalItem === 'widgets.imports.topDeclarants' || //c
                modalItem === 'widgets.imports.topSuppliersByDutyPaid' || //c
                modalItem === 'widgets.imports.topSuppliersByImportVal' || //c
                modalItem === 'widgets.imports.importValByCpc' || //c
                modalItem === 'widgets.imports.importValByPref' || //c
                modalItem === 'widgets.imports.suppliersByDispCtry' || //c
                modalItem === 'widgets.imports.dutyRateByOrigCtry' || //c
                modalItem === 'widgets.exports.topCommodityCodesByValue' || //c
                modalItem === 'widgets.exports.totalcpc' || //c
                modalItem === 'widgets.exports.bottomCommodityCodesByValue' || //c
                modalItem === 'widgets.exports.exportsByConsignee' || //c
                modalItem === 'widgets.imports.suppliersInMultipleCurrencies' || //c
                modalItem === 'widgets.imports.totalvatposp' || //c
                modalItem === 'widgets.imports.importCountByEpu'
              ) && <BarChart3DComponent widgetName={modalItem} widgetTitle={modalTitle}  helpText={modalHelpText}  />}
              {/* BarChart3DComponent chart  end*/}

              {/* GaugeChart chart  start*/}
              {(
                modalItem === 'widgets.imports.totalVatValuevsVatPaid' 

              ) && <GaugeChart widgetName={modalItem} widgetTitle={modalTitle}  helpText={modalHelpText}  />}
              {/* GaugeChart chart  end*/}

              {/* MapComponent chart  start*/}
              {(
                modalItem === "widgets.imports.dutyPaidByOrigCtry" || //c
                modalItem === "widgets.imports.importValByDispCtry" ||//c
                modalItem === "widgets.imports.importsByDispCtry" || //c
                modalItem === "widgets.exports.commodityCodesPerDestCtry" || //c
                modalItem === "widgets.exports.noOfExportsGoingToDestinations" || //c
                modalItem === "widgets.imports.importsByOrigCtry"
              ) && <MapNewComponent widgetName={modalItem} widgetTitle={modalTitle}  helpText={modalHelpText} />}
              {/* MapComponent chart  end*/}
            </Grid>
          </div>
        </>
      </Dialog>
    </Box>
  );
};

export default DynamicGrid;
