import { signOutManager } from "../../reducers/authSlice";
import store from "../../store/store";
const widgetDefaultdata = [
    {item:'widgets.imports.totalVatPaid',popHeadingTitle:"VAT Paid", popHeadingValue:'Amount'},
    {item:'widgets.imports.totalvatposp',popHeadingTitle:"VAT Accounting", popHeadingValue:'Amount'},
    {item:'widgets.imports.edr',popHeadingTitle:"Effective Duty Rate", popHeadingValue:'Percentage'},
    {item:'widgets.imports.totalVatValue',popHeadingTitle:"VAT Value", popHeadingValue:'Amount'},
    {item:'widgets.imports.dutyPaidByOrigCtry',popHeadingTitle:"Origin Country", popHeadingValue:'Amount'},
    {item:'widgets.imports.importValByDispCtry',popHeadingTitle:"Dispatch Country", popHeadingValue:'Amount'},
    {item:'widgets.imports.importsByDispCtry',popHeadingTitle:"Dispatch Country", popHeadingValue:'Count'},
    {item:'widgets.imports.suppliersByDispCtry',popHeadingTitle:"Suppliers", popHeadingValue:'Count'},
    {item:'widgets.imports.topDeclarants',popHeadingTitle:"Declarants ", popHeadingValue:'Count'},
    {item:'widgets.imports.topDeclarantsByCustVal',popHeadingTitle:"Declarants ", popHeadingValue:'Amount'},
    {item:'widgets.imports.topSuppliersByDutyPaid',popHeadingTitle:"Suppliers", popHeadingValue:'Amount'},
    {item:' widgets.imports.topSuppliersByImportVal',popHeadingTitle:"Suppliers", popHeadingValue:'Amount'},
    {item:'widgets.imports.dutyRateByOrigCtry',popHeadingTitle:"Origin Country", popHeadingValue:'Average'},
    {item:'widgets.imports.suppliersInMultipleCurrencies',popHeadingTitle:"Suppliers", popHeadingValue:'Count'},
    {item:'widgets.imports.totalImports',popHeadingTitle:"Entry No", popHeadingValue:'Count'},
    {item:'widgets.imports.importValByCpc',popHeadingTitle:"CPC", popHeadingValue:'Amount'},
    {item:'widgets.imports.importValByPref',popHeadingTitle:"Preference", popHeadingValue:'Amount'},
    {item:'widgets.imports.topCommodityCodesByDutyPaid',popHeadingTitle:"Commodity Codes", popHeadingValue:'Amount'},
    {item:'widgets.imports.bottomCommodityCodesByDutyPaid',popHeadingTitle:"Commodity Codes", popHeadingValue:'Amount'},
    {item:'widgets.imports.edrOnDutiable',popHeadingTitle:"Dutiable Goods", popHeadingValue:'Percentage'},
    {item:' widgets.exports.exportsByEntryNo',popHeadingTitle:"Entry No", popHeadingValue:'Count'},
    {item:'widgets.exports.exportsByConsignee',popHeadingTitle:"Consignee", popHeadingValue:'Count'},
    {item:'widgets.exports.noOfExportsGoingToDestinations',popHeadingTitle:"Destination Country", popHeadingValue:'Count'},
    {item:'widgets.exports.totalcpc',popHeadingTitle:"CPC", popHeadingValue:'Amount'},
    {item:'widgets.exports.topCommodityCodesByValue',popHeadingTitle:"Commodity Codes", popHeadingValue:'Amount'},
    {item:'widgets.exports.bottomCommodityCodesByValue',popHeadingTitle:"Commodity Codes", popHeadingValue:'Amount'},
]

export const errorMessage = (props) => {
    try {
        if (props?.response?.status === 401 || props?.response?.status === 500) {
            store.dispatch(signOutManager());
            window.location.href = "/login";
        }
        return true;
    } catch (error) {
        console.log(error.message);
        return true
    }
}

export const sleep = (delay = 0) => {
    return new Promise(resolve => {
      setTimeout(resolve, delay)
    })
}

export const getTitleforWidget = (widget) => {
    if(widget !== ''){
        const value = widgetDefaultdata?.find((item)=>{
            return item?.item === widget
        })
        return value
    }
    
}