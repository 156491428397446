import React from 'react';
import { Box } from '@mui/material';

const DraggableItems = ({ item, itemId, name ,infoTitle }) => {
  const handleDragStart = (e) => {
    const dragData = JSON.stringify({ item, itemId, name ,infoTitle});
    e.dataTransfer.setData('text/plain', dragData); // Set the serialized data being dragged
  };

  return (
    <div draggable onDragStart={(e) => handleDragStart(e)}>
      <Box className="draggableitem" draggable onDragStart={(e) => handleDragStart(e)}>
        {name}
      </Box>
    </div>
  );
}

export default DraggableItems;