import React, { useRef, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Iconcopy from '../../assets/images/icon-copy.svg';
import Dashboardlogo from '../../assets/images/logo-dashboard.svg';
import '../../assets/styles/UploadSearchCommodity.css';
import { API_URL } from "../../services/Config";

const UploadSearchCommodity = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [searchResultStatus, setSearchResultStatus] = useState('idle');
  const [isSearching, setIsSearching] = useState(false);

  const [ukCommodityData, setUKCommodityData] = useState(null);
  const [niCommodityData, setNICommodityData] = useState(null);
  const [isInputValid, setIsInputValid] = useState(true);

  const fileInputRef = useRef(null);

  const handleFileSelectionAndSubmit = async () => {
    try {
      if (!fileInputRef.current || !fileInputRef.current.files || fileInputRef.current.files.length === 0) {
        toast.error('Please select a file.');
        return;
      }

      const formData = new FormData();
      formData.append('file', fileInputRef.current.files[0]);
      setIsUploading(true); // Set the upload status to true
      const response = await fetch(`${API_URL}/api/upload/`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.msg) {
          toast.success(data.msg);
        }
        else {
          toast.error(data.error);
        }
      }
      else {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
    } catch (error) {
      toast.error(error.error);
    } finally {
      setIsUploading(false); // Reset the upload status
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Reset the file input value
      }
    }
  };

  const handleSearchButtonClick = async () => {

    if (searchQuery.trim() === '') {
      setIsInputValid(false);
      return;
    }
    try {
      setIsSearching(true);
      setIsInputValid(true);
      setSearchResultStatus('searching');
      const response = await fetch(`${API_URL}/api/commodity_api_call/?commodity=${searchQuery}`);
      if (response.ok) {
        const data = await response.json();
        if (data.response_result === 'Commodity Found') {
          if (data.uk_data && data.uk_data.source === 'UK') {
            setUKCommodityData(data.uk_data);
          } else {
            setUKCommodityData(null);
          }
          if (data.ni_data && data.ni_data.source === 'NI') {
            setNICommodityData(data.ni_data);
          } else {
            setNICommodityData(null);
          }
          setSearchResultStatus('found');
        } else {
          setUKCommodityData(null);
          setNICommodityData(null);
          setSearchResultStatus('notFound');
        }
      } else {
        setUKCommodityData(null);
        setNICommodityData(null);
        setSearchResultStatus('notFound');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
    setSearchResultStatus('idle');
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchButtonClick();
    }
  };

  return (
    <div>
      <div className='pagecontainer scrollablearea'>
        <div className='dashboard-logo flex justify-center'>
          <img src={Dashboardlogo} alt='dashboard' />
        </div>

        {/* Upload Button */}
        {/* {isUploading ? (
          <p>Please wait...</p>
        ) : (
          <div className='upload-button'>
            <input id='upload' type='file' ref={fileInputRef} onChange={handleFileSelectionAndSubmit} hidden />
          </div>
        )} */}

        {/* Search Bar */}
        <div className='search-bar'>
          <input
            type='text'
            placeholder='Search Commodity...'
            value={searchQuery}
            onChange={handleSearchQueryChange}
            onKeyDown={handleKeyPress}
            className={isInputValid ? '' : 'invalid'} // Apply CSS class for styling
          />
          <button onClick={handleSearchButtonClick} disabled={isSearching}>
            {isSearching ? 'Please Wait...' : 'Search'}
          </button>
          {!isInputValid && <p className='error-message'>Please enter a valid code!</p>}
        </div>

        {/* Display UK and Northern Ireland Commodity Data */}
        <div className='resulttable'>
          {niCommodityData && (<Tabs>
            <TabList>
              <Tab>UK Commodity Data</Tab>
              <Tab>Northern Ireland Commodity Data</Tab>
              {/* <Tab>Title 3</Tab> */}
            </TabList>

            {ukCommodityData && (
              <TabPanel>
                <table className='search-table'>
                  <thead>
                    <tr>
                      <th>UK Integrated Online Tariff</th>
                      {/* <th>Copy commodities code <img src={Iconcopy} alt='Copy commodities code' /></th> */}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Commodity Code</td>
                      <td>{ukCommodityData.commodity_code}</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td>{ukCommodityData.description}</td>
                    </tr>
                    <tr>
                      <td>Source</td>
                      <td>{ukCommodityData.meta_source}</td>
                    </tr>
                    <tr>
                      <td>Validity Start Date</td>
                      <td>{ukCommodityData.validity_start_date}</td>
                    </tr>
                    <tr>
                      <td>Validity End Date</td>
                      <td>{ukCommodityData.validity_end_date}</td>
                    </tr>
                    <tr>
                      <td>Date of Trade</td>
                      <td>{ukCommodityData.date_of_trade}</td>
                    </tr>
                  </tbody>
                </table>

              </TabPanel>
            )}

            {/* Display Northern Ireland Commodity Data */}
            {niCommodityData && (
              <TabPanel>
                <table className='search-table'>
                  <thead>
                    <tr>
                      <th>Northern Ireland Integrated Online Tariff</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Commodity Code</td>
                      <td>{niCommodityData.commodity_code}</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td>{niCommodityData.description}</td>
                    </tr>
                    <tr>
                      <td>Source</td>
                      <td>{niCommodityData.meta_source}</td>
                    </tr>
                    <tr>
                      <td>Validity Start Date</td>
                      <td>{niCommodityData.validity_start_date}</td>
                    </tr>
                    <tr>
                      <td>Validity End Date</td>
                      <td>{niCommodityData.validity_end_date}</td>
                    </tr>
                    <tr>
                      <td>Date of Trade</td>
                      <td>{niCommodityData.date_of_trade}</td>
                    </tr>
                  </tbody>
                </table>
              </TabPanel>
            )}
          </Tabs>)}
          {/* Display "Not Found" message */}
          {searchResultStatus === 'notFound' && <div className='resultnotfound'>Commodity Not Found!</div>}
        </div>
        <div>
          {/* For show message using toastcontainer */}
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default UploadSearchCommodity;