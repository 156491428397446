import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "../reducers/authSlice";
import messageReducer from "../reducers/messageSlice";
import userProfileReducer from "../reducers/userProfileSlice";

const authPersistConfig = {
  key: "auth",
  storage,
};

const userProfilePersistConfig = {
  key: "userProfile",
  storage,
};

const messagePersistConfig = {
  key: "message",
  storage,
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedUserProfileReducer = persistReducer(
  userProfilePersistConfig,
  userProfileReducer
);
const persistedmessageReducer = persistReducer(
  messagePersistConfig,
  messageReducer
);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    userProfile: persistedUserProfileReducer,
    message: persistedmessageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: true,
    }),
});

export const persistor = persistStore(store);
export default store;
