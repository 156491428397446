import 'react-toastify/dist/ReactToastify.css';
import '../../assets/styles/UploadSearchCommodity.css';

const DataManagement = () => {
  

  return (
    <div>
        <div className='pagecontainer scrollablearea'>
        <h1 className='text-3xl font-medium mb-8'>Data Management</h1>
        </div>
    </div>
  );
};

export default DataManagement;
