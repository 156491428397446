const awsconfig = {
  aws_project_region: process.env.REACT_APP_aws_cognito_region,
  aws_cognito_region: process.env.REACT_APP_aws_cognito_region,
  aws_user_pools_id: process.env.REACT_APP_aws_user_pools_id,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_aws_user_pools_web_client_id,

  auth: {
    region: process.env.REACT_APP_region,
    userPoolId: process.env.REACT_APP_userPoolId,
    userPoolWebClientId: process.env.REACT_APP_userPoolWebClientId,
    mandatorySignIn: true,
    oauth: {
      scope: ["email", "openid"],
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      responseType: "code",
    },
  },
};

export default awsconfig;
