import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Modal,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  TablePagination,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { authAPI } from "../../services/authAPI";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

function PeopleListPopup({ open, onClose, sharedUsers }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [checkedState, setCheckedState] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const buttonRef = useRef(null);
  const access_token = useSelector(
    (state) => state.auth.access_token,
    shallowEqual
  );
  // Effect to reset checked state when modal opens or sharedUsers change
  useEffect(() => {
    if (open) {
      const initialState = sharedUsers?.users?.reduce((acc, user) => {
        acc[user.id] = user.has_permission;
        return acc;
      }, {});
      const selectedUsersState = sharedUsers?.users?.reduce((acc, user) => {
        acc[user.id] = user.has_permission ? user.id : null;
        return acc;
      }, {});
      setSelectedUsers(selectedUsersState || {});
      setCheckedState(initialState || {});
    }
  }, [open, sharedUsers]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckChange = (event, id) => {
    setCheckedState((prev) => ({ ...prev, [id]: event.target.checked }));
    if (event.target.checked) {
      setSelectedUsers((prev) => ({ ...prev, [id]: id }));
    } else {
      setSelectedUsers((prev) => {
        const newSelectedUsers = { ...prev };
        delete newSelectedUsers[id];
        return newSelectedUsers;
      });
    }
  };

  const handelSubmit = useCallback(async () => {
    setLoading(true);
    let selectedIds = Object.values(selectedUsers).filter((v) => v !== null);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
      allowOutsideClick: false,
      showCloseButton: true,
    });
    if (selectedIds.length === 0) {
      // toast.error("Please select at least one user");
      await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds for the toast to disappear
      setLoading(false); // Enable the button
      // return;
    }
    const data = new FormData();
    data.append("users", JSON.stringify(selectedIds));
    data.append("doc_id", sharedUsers?.id);
    const response = await authAPI.grantFilePermissionToUser({
      access_token,
      data,
    });
    if (response?.status) {
      swalWithBootstrapButtons.fire({
        title: "Shared!",
        text: `${response?.message}`,
        icon: "success",
      });
      onClose();
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [selectedUsers, sharedUsers, access_token, onClose]);
  const handleHeadCheckboxChange = (event) => {
    setCheckedState((prev) => ({
      ...prev,
      ...sharedUsers.users.reduce(
        (acc, user) => ({ ...acc, [user.id]: event.target.checked }),
        {}
      ),
    }));
    setSelectedUsers((prev) =>
      event.target.checked
        ? sharedUsers.users.reduce(
            (acc, user) => ({ ...acc, [user.id]: user.id }),
            {}
          )
        : {}
    );
  };
  return (
    <Modal
      className="sharePopup"
      open={open}
      aria-labelledby="people-list-modal-title"
      aria-describedby="people-list-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography id="people-list-modal-title" variant="h6" component="h2">
          People List
        </Typography>
        <TableContainer component={Paper} className="sharePopup_body">
          <Table aria-label="people list table">
            <TableHead>
              <TableRow>
                <TableCell className="share_checkbox" padding="checkbox">
                  <Checkbox
                    checked={Object.values(checkedState).every((v) => v)}
                    onChange={handleHeadCheckboxChange}
                  />
                </TableCell>
                <TableCell className="shareTbl_col">Full Name</TableCell>
                <TableCell className="shareTbl_col">Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sharedUsers?.users
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell padding="checkbox" className="share_checkbox">
                      <Checkbox
                        checked={!!checkedState[user.id]}
                        onChange={(event) => handleCheckChange(event, user.id)}
                        inputProps={{
                          "aria-labelledby": `checkbox-${user.id}`,
                        }}
                      />
                    </TableCell>
                    <TableCell className="shareTbl_col" scope="row">
                      {" "}
                      <span
                        className="userfirst_latter"
                        style={{ textTransform: "capitalize" }}
                      >
                        {user?.first_name?.charAt(0)}
                      </span>{" "}
                      {`${user.first_name} ${user.last_name}`}
                    </TableCell>
                    <TableCell className="shareTbl_col">{user.email}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sharedUsers?.users?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Button
          className="close_btn"
          type="reset"
          onClick={onClose}
          sx={{ mt: 2 }}
        >
          ×
        </Button>
        {isLoading ? (
          <LoadingButton
            loading
            loadingIndicator="Loading…"
            variant="contained"
            className="share_submit_btnLoading"
          >
            Fetch data
          </LoadingButton>
        ) : (
          <Button
            ref={buttonRef}
            className="share_submit_btn"
            type="submit"
            onClick={handelSubmit}
            sx={{ mt: 2 }}
          >
            Submit
          </Button>
        )}
      </Box>
    </Modal>
  );
}

export default PeopleListPopup;
