import React, { useState } from "react";

const CalendlyPopup = ({ handleClose }) => {
  return (
    <div className="popup aspect-w-16 aspect-h-9">
      <button className="close-btn" onClick={handleClose}>
        <svg
          width="30px"
          height="30px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Menu / Close_LG">
            <path
              id="Vector"
              d="M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001"
              stroke="#ffffff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </button>
      <div className="popup-inner"
        style={{
          maxWidth: 640,
          width: "100%",
          height: "50rem",
          overflow: "auto",
        }}
      >
        <iframe
          title="Calendly"
          src="https://calendly.com/gavin-tucker/15min"
          width="100%"
          height="100%"
          scrolling="no"
          frameBorder={0}
        />
      </div>
    </div>
  );
};

const Information = () => {
  const [showPopup, setShowPopup] = useState(false);

  const triggerCalendly = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const triggerEmail = () => {
    window.location.href =
      "mailto:support@ccdigitalsolutions.co.uk?subject=C360 Support Request";
  };
  return (
    <div className="pagecontainer scrollablearea">
      <h1 className="text-3xl font-medium mb-8">Information </h1>
      <div className="bg-white xl:p-10 md:p-6 sm:p-4 rounded-lg shadow shadow-gray-100">
        <p className="text-xl font-medium italic mb-2">
          Find guides, information and help on using C3.
        </p>
        <p className="text font-light mb-3 leading-5">
          Welcome to the Help Portal, below you can request software support for
          any issues with your C360 software license via email ticketing or we
          can help you manage your International trade, so book a meeting with
          one of our customs experts to discuss any customs needs, issues or
          questions.
        </p>
        <p className="text-1xl font-light">
          You can book a quick meeting with one of our Customs Experts below.
        </p>
        <div className="info-buttons">
          <button className="button-default" onClick={triggerCalendly}>
            Book Quick Meeting
          </button>
          <button
            className="button bg-orange text-white hover:bg-blue"
            onClick={triggerEmail}
          >
            C360 Software Support Request
          </button>
          </div>
         </div>
      {showPopup && <CalendlyPopup handleClose={closePopup} />}
    </div>
  );
};

export default Information;
