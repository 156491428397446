import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { authAPI } from '../../services/authAPI';

function ForgotPasswordRequest() {
  const navigate = useNavigate();
  const handleRequestReset = async (values, { setSubmitting, setFieldError }) => {
    try {
      const response = await authAPI.forgotPasswordRequest({ email: values.email });
      if (response.message) {
        navigate('/reset-password', { state: { successMessage: response.message } });
      }
      else if(response.error){
        setFieldError('email',response.error);
      }
    } catch (error) {
      setFieldError('email', 'Failed to request password reset. Please try again.');
    }
    setSubmitting(false);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email can\'t be blank.'),
  });

  const handleGoToBackClick = () => {
    navigate('/login');
  };

  return (
    <div>
      <div className='logincontainer-outer'>
        <div className='logincontainer'>
        <div className="flex justify-center">
        <Link to="/">
            <div className="animated-logo">
              <div className="animated-logo-360"></div>
            </div>
        </Link>
          </div>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={validationSchema}
            onSubmit={handleRequestReset}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className='mb-5'>
                  Forgot Password
                  <Field type="email" name="email" placeholder="Enter your email" />
                  <ErrorMessage name="email" component="div" className="error-message" />
                </div>
                {/* back Button */}
                <button className='button-back' onClick={handleGoToBackClick} >Back</button>
                
                <button className='text-end login-button'  type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordRequest;
