import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Routes, Route, Navigate } from "react-router-dom";
import Vault from "../components/vault";
import UpdatePassword from "../components/auth/ChangePassword";
import ForgotPasswordRequest from "../components/auth/ForgotPasswordRequest";
import Login from "../components/auth/Login";
import ResetPassword from "../components/auth/ResetPassword";
import UpdateUserProfile from "../components/auth/UpdateProfile";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import Sidebar from "../components/common/Sidebar";
import DataManage from "../components/dataManagement/DataManage";
import AddUser from "../components/features/AddUser";
import UploadSearchCommodity from "../components/features/UploadSearchCommodity";
import UserList from "../components/features/UserList";
import AnalyticalTool from "../components/pages/AnalyticalTool";
import Dashboard from "../components/pages/Dashboard";
import DataManagement from "../components/pages/DataManagement";
import Help from "../components/pages/Help";
import NotFound from "../components/pages/NotFound";
import TaxAuthority from "../components/pages/TaxAuthority";
import Report from "../components/analytical/report";
import CreateReport from "../components/analytical/createReport";
import EditReport from "../components/analytical/EditReport";

export default function SidebarNav() {
  const [sidebarMinimized, setSidebarMinimized] = useState(false);
  const { access_token } = useSelector((state) => state.auth);

  const toggleSidebar = () => {
    setSidebarMinimized(!sidebarMinimized);
    localStorage.removeItem('vaultItemId');
  };

  return (
    <>
      <div className={`dashboard ${(access_token === '' || access_token === undefined || access_token === null) ? "landingUser" : ""} ${sidebarMinimized ? "minimized" : ""}`}>
        {access_token && (
          <Sidebar
            sidebarMinimized={sidebarMinimized}
            toggleSidebar={toggleSidebar}
          />
        )}
        <div className="content">
          {access_token && <Header onToggleSidebar={toggleSidebar} />}
          <Routes>
            {/* Public routes */}
            <Route
              path="/forgot-password"
              element={<ForgotPasswordRequest />}
            />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/login"
              element={access_token ? <Navigate to="/" /> : <Login />}
            />

            {/* Protected routes */}
            {access_token ? (
              <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/home" element={<Dashboard />} />
                <Route path="/data-management" element={<DataManagement />} />
                <Route path="/data-manage" element={<DataManage />} />
                <Route path="/analytical" element={<AnalyticalTool />} />
                <Route path="/analyticals" element={<Report />} />
                <Route path="/report" element={<Report />} />
                <Route path="/create-report" element={<CreateReport />} />
                <Route path="/edit-report/:widget_id" element={<EditReport />} />

                <Route path="/commodity-search" element={<UploadSearchCommodity />} />
                <Route path="/the-vault" element={<Vault />} />
                <Route path="/the-vault/:name" element={<Vault />} />
                {/* <Route path="/freight-logistics" element="" /> */}
                <Route path="/tax-authority" element={<TaxAuthority />} />
                <Route path="/help" element={<Help />} />
                <Route path="/add-user" element={<AddUser />} />
                <Route path="/view-user" element={<UserList />} />
                <Route path="/update-profile" element={<UpdateUserProfile />} />
                <Route path="/update-password" element={<UpdatePassword />} />
                {/* Redirect all unknown paths to NotFound */}
                <Route path="*" element={<NotFound />} />
              </>
            ) : (
              // Redirect to login if not authenticated and trying to access a non-public route
              <Route path="*" element={<Navigate to="/login" />} />
            )}
          </Routes>
          <Footer />
        </div>
      </div>
    </>
  );
}