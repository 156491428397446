import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  user: null,
  eoris: null,
  first_name: null,
  last_name: null,
  profile_image: null,
  company_registration: null,
  company_name: null,
  user_limit: null,
  vat: null,
  position: null,
  user_type: null,
  country: null,
  currencyList: null,
};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    clearUserProfile: () => initialState,
    setUserProfile: (state, action) => {
      return action.payload;
    },
    setCurrencyList: (state, action) => {
      state.currencyList = action.payload
    },
  },
});

export const { setUserProfile, clearUserProfile,setCurrencyList } = userProfileSlice.actions;

export default userProfileSlice.reducer;
