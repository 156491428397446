import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ANALYTICAL_URL } from "../../services/Config";
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/styles/UploadSearchCommodity.css';


const OriginSearch = () => {
  const payloadData = useSelector(state => state.auth.payload_data);
  const [iframeLoaded, setIframeLoaded] = useState(true);
  const iframeRef = useRef(null);
  const navigate = useNavigate();
 	
  useEffect(() => {
    const iframe = document.getElementById('myIframe');
    setIframeLoaded(true);
    const rootDiv = document.getElementById('root');
    if (rootDiv) {
      rootDiv.classList.add('analtical-tool');
    }

    // Create a new object with the existing payloadData and the new key-value pair
    const newPayloadData = {
      ...payloadData,
      analytical_tool: "true",
    };

    const payload = { data: newPayloadData }; 
    if (iframe) {
      iframe.addEventListener('load', () => {
        iframe.contentWindow.postMessage(payload, `${ANALYTICAL_URL}`);
      });
    }
  }, [payloadData]);

  const handleGoToBackClick = () => {
    navigate('/');
  };

  return (
    <div>
      <div className='pagecontainer analytical-outer'>
        <h1 className='text-3xl font-medium mb-8'>Analytical Tool</h1>
        <div className='backbutton'>
          <button className='button-default' onClick={handleGoToBackClick}>Back</button>
        </div>
        {iframeLoaded && (
          <iframe
            ref={iframeRef}
            id="myIframe"
            src={ANALYTICAL_URL}
            width='100%'
            height='500'
            title='c360 Login'
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default OriginSearch;
