import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import SolidGauge from 'highcharts/modules/solid-gauge';
import { Box, CircularProgress, Typography } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import { authAPI } from '../../../services/authAPI';
import { toast } from 'react-toastify';

// Initialize Highcharts modules
HighchartsMore(Highcharts);
SolidGauge(Highcharts);

const GaugeChart = ({ widgetName, widgetTitle, widgetData, leftItem=null }) => {
    const [loading, setLoading] = useState(false);
    const [gaugeData, setGaugeData] = useState(Array.isArray(widgetData?.data) ? widgetData?.data : []);
    const access_token = useSelector((state) => state.auth.access_token, shallowEqual);
    const hasFetched = useRef(false);

    useEffect(() => {
        window.addEventListener('filterUpdate', fetchGaugeDisplay);
        window.addEventListener('currencyUpdate', fetchGaugeDisplay);

        return () => {
            window.removeEventListener('filterUpdate', fetchGaugeDisplay);
            window.removeEventListener('currencyUpdate', fetchGaugeDisplay);
        };
    }, []);

    const fetchGaugeDisplay = async () => {
        setLoading(true);
        try {
            const filters = window.localStorage.getItem('filters');
            const viewCurrency = window.localStorage.getItem('viewCurrency');
            const formData = new FormData();
            formData.append('name', widgetName);
            // if (filters) formData.append('filters', filters);
            if (filters !== '' && filters !== null && filters !== undefined)
                formData.append('filters', filters);

            formData.append('viewCurrency', (viewCurrency || 'GBP'));
            const response = await authAPI.fetchInfoDisplayWidget({ access_token, formData });
            setGaugeData(response?.result?.data || []);
        } catch (error) {
            toast.error(error?.message || 'Error fetching data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (access_token && !hasFetched.current && !widgetData?.data) {
            fetchGaugeDisplay();
            hasFetched.current = true;
        }
    }, [access_token, widgetData?.data]);

    useEffect(() => {
        if(leftItem !== null && leftItem?.[0]?.item === widgetName ){
            fetchGaugeDisplay();
        }
    },[leftItem])

    const commonOptions = {
        chart: {
            type: 'solidgauge'
        },
        accessibility: {
            enabled: false // Enable accessibility module
        },
        title: null,
        pane: {
            center: ['50%', '50%'],
            size: '100%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },
        yAxis: {
            stops: [
                [0.1, '#55BF3B'], // green
                [0.5, '#DDDF0D'], // yellow
                [0.9, '#DF5353']  // red
            ],
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            title: {
                y: -70
            },
            labels: {
                y: 16
            }
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
                }
            }
        }
    };

    const renderGaugeCharts = () => {
        if (!gaugeData || gaugeData?.length === 0) {
            return (
                <>
                    <Typography variant="h6" sx={{ textAlign: 'center', p: 25 }}>
                        No result found
                    </Typography>
                </>
            )
        }

        return gaugeData?.map((data, index) => {
            const options = {
                ...commonOptions,
                title: {
                    text: data.name
                },
                yAxis: {
                    ...commonOptions.yAxis,
                    min: 0,
                    max: Math.max(data.value * 1.2, 100),  // Adjust as per your range
                    title: {
                        text: data.name
                    }
                },
                series: [{
                    name: data.name,
                    data: [data.value],
                    dataLabels: {
                        format: `<div style="text-align:center"><span style="font-size:25px">{y}</span><br/>
                                <span style="font-size:12px;opacity:0.4">units</span></div>`
                    },
                    tooltip: {
                        valueSuffix: ' units'
                    }
                }]
            };

            return (
                <Box sx={{ width: '50%' }}>
                    <HighchartsReact
                        key={index}
                        highcharts={Highcharts}
                        options={options}
                    />
                </Box>
            );
        });
    };

    return (
        <Box className="item-content">
            <Typography variant="h6" sx={{ fontWeight: '600', paddingTop: '7px', paddingLeft: '15px' }}>
                {widgetTitle}
            </Typography>

            <Box className="dashboard-item" sx={{ display: 'flex' }}>
                {loading ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress sx={{ color: '#ff6b04' }} />
                    </Box>
                    : renderGaugeCharts()}
            </Box>
        </Box >
    );
};

export default GaugeChart;
