import React, { useState, useRef, useEffect } from 'react';
import {
  Box, CircularProgress, Divider, Grid, Typography,
  Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector, shallowEqual } from 'react-redux';
import { authAPI } from '../../services/authAPI';
import KpiBoxes from './KpiBoxes';
import DynamicGrid from './DynamicGrid';
import '../analytical/analytical.css';
import HideSidebar from '../../assets/images/hide-sidebar.svg';
import ShowSidebar from '../../assets/images/show-sidebar.svg';
import IconClose from '../../assets/images/icon-close.svg';
import PrintDynamicGrid from './PrintDynamicGrid';
import { toast } from 'react-toastify';

const Report = () => {
  const [dataImport, setDataImport] = useState([]);
  const [expanded, setExpanded] = useState('panel1');
  const [itemTitle, setItemTitle] = useState('');
  const [filteredData, setFilteredData] = useState('');
  const [declarantNumber, setDeclarantNumber] = useState('');
  const [uniqueCurrencies, setUniqueCurrencies] = useState('');
  const [totalImportValue, setTotalImportValue] = useState('');
  const [cDate, setCreatedDate] = useState('');
  const [createdDate, setItemCreatedDate] = useState('');
  const [widgetId, setWidgetId] = useState('');
  const [widgetData, setWidgetData] = useState([]);
  const [initialLoading, setInitialLoading] = useState(false);
  const [gridLoading, setGridLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const access_token = useSelector((state) => state.auth.access_token, shallowEqual);
  const hasFetched = useRef(false);
  const [filters, setFilters] = useState([]);
  const [showPrint, setShowPrint] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const checkWidgetReportId = localStorage.getItem('selectedReportId');
  useEffect(() => {
    window.addEventListener('printInitilize', printInit);

    return () => {
      window.removeEventListener('printInitilize', printInit);
      setShowPrint(false)
    };
  }, []);

  const printInit = () => {
      setShowPrint(true);
  }

  const printInitOld = () => {
    setPrintLoading(true)
  }

  const printSuccess = () =>{
    setPrintLoading(false)
    setShowPrint(false)
  }


  const fetchWidgetReport = async () => {
    setInitialLoading(true);
    try {
      const response = await authAPI.getDashboardWidget({ access_token });
      const fetchedData = response?.result || [];
      setDataImport(fetchedData);
      setUniqueCurrencies(response?.unique_currencies);
      setDeclarantNumber(response?.unique_declrant_numbers);
      setTotalImportValue(response?.sum_custom_value);
      if (fetchedData?.user && fetchedData?.user?.length > 0) {
        const firstItem = fetchedData.user[0];
        setItemTitle(firstItem?.title);
        // setFilteredData(firstItem?.filters);
        // setFilters(firstItem?.filters);
        setWidgetId(firstItem?.id);
        const id = (checkWidgetReportId != undefined && checkWidgetReportId != null && checkWidgetReportId != '') ? checkWidgetReportId : firstItem?.id;
        fetchWidgetReportById(id);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setInitialLoading(false);
    }
  };
  useEffect(() => {
    if (access_token && !hasFetched.current) {
      fetchWidgetReport();
      hasFetched.current = true;
    }
  }, [access_token]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fetchWidgetReportById = async (id) => {
    setGridLoading(true);
    try { 
      const response = await authAPI.dashboardWidgetRead({ access_token, id });
      setCreatedDate(response.result?.createdAt);
      setWidgetData(response?.result?.wdata || []);
      setFilteredData(response?.result?.filters);
      setFilters(response?.result?.filters);
      localStorage.setItem('selectedReportTitle',response?.result?.title)
      if (response?.result?.filters?.length > 0) {
        window.localStorage.removeItem('filters')
        window.localStorage.setItem('filters', JSON.stringify(response?.result?.filters))
        window.dispatchEvent(new Event('filterUpdate'));
      } else {
        window.localStorage.removeItem('filters')
        window.dispatchEvent(new Event('filterUpdate'));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setGridLoading(false);
    }
  };

  const handleClick = (title, id, createdAt) => {
    setWidgetId(id);
    setItemTitle(title);
    setItemCreatedDate(createdAt);
    fetchWidgetReportById(id);
    localStorage.removeItem('selectedReportId');
  };

  const toggleMenus = () => {
    setIsOpen(!isOpen);
  };

  // Function to check if a date is valid
  const isValidDate = (d) => d instanceof Date && !isNaN(d);

  const date = new Date(createdDate || cDate);

  const [isOpen01, setIsOpen01] = useState(true);
  const toggle = () => setIsOpen01(!isOpen01);

  // Format the date to 'DD/MM/YYYY, HH:MM:SS'
  const reportFormattedDate = isValidDate(date)
    ? date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    : '';

  const styles = {
    gridContainer: {
      position: 'relative',
    },
    accordion: {
      marginBottom: '0px !important',
      borderRadius: '0 !important',
      boxShadow: 'none',
    },
    accordionSummary: {
      paddingLeft: '15px',
    },
    typography: {
      cursor: 'pointer',
      padding: '0px 0',
      fontSize: '14px',
      display: 'block'
    },
    divider: {
      opacity: 0.4,
    },
    chartContainer: {
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '0',
    },

  };

  const getFilterValueData = (filtersValue) => {
    setFilters(filtersValue)
  }

  const filterRemoveFunction = (filterRemove) => {
    setFilters(filterRemove);
    window.localStorage.setItem('filters', JSON.stringify(filterRemove));
    window.dispatchEvent(new Event('filterUpdate'));

  };

  useEffect(() => {
    window.addEventListener('filterUpdate', DynamicFilter);
  }, []);

  const DynamicFilter = () => {

  }

  return (
    <Grid container className={`anlytical-dashboard  ${isOpen01 ? 'showReportMenu' : 'hideReportMenu'}`}>
      {!isOpen && (
        <Grid item sx={{ background: '#fff' }} className={`scrollablesidebar sm:pt-5 folder-list ${isOpen01 ? 'show' : ''}`}>
          {initialLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} >
              <CircularProgress sx={{ color: '#ff6b04' }} />
            </Box>
          ) : (
            <>
                <button className="sidebarClose" onClick={toggle}>
              <img width="15" height="15" src={IconClose} alt="" />
            </button>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={styles.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header" className='reports-heading'
                  sx={styles.accordionSummary}
                >
                  <Typography sx={{ fontSize: '14px' }}>
                    <i className="far fa-folder" aria-hidden="true"></i> Your Reports <span className='tag'>{dataImport?.user?.length}</span>
                  </Typography>
                </AccordionSummary> 
                <Divider sx={styles.divider} />
                <AccordionDetails sx={styles.accordionDetails}>
                  {dataImport?.user?.map((item) => (
                    <Typography key={item?.id} onClick={() => handleClick(item?.title, item?.id, item?.createdAt)} sx={styles.typography}>
                      <span className='report-menu'>{item?.title}</span>
                      <Divider sx={styles.divider} />
                    </Typography>

                  ))}

                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ ...styles.accordion, marginTop: '0px !important' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                  sx={styles.accordionSummary}
                >
                  <Typography sx={{ fontSize: '14px' }}>
                    <i className="far fa-folder" aria-hidden="true"></i> Shared Reports <span className='tag'>{dataImport?.shared?.length}</span>
                  </Typography>
                </AccordionSummary>
                <Divider sx={styles.divider} />
                <AccordionDetails sx={styles.accordionDetails}>
                  {dataImport?.shared?.map((item) => (
                    <Typography key={item?.id} onClick={() => handleClick(item?.title, item?.id)} sx={styles.typography}>
                      <span className='report-menu'>{item?.title}</span>
                      <Divider sx={styles.divider} />
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </Grid>
      )}

      <Grid item p={2} sx={styles.gridContainer} className='scrollablearea'>

        <button className="sidebarToggle mb-3" onClick={toggle}>
          <img width="30" height="30" src={isOpen01 ? HideSidebar : ShowSidebar} alt="" /> {isOpen01 ? 'Hide' : 'Show'} Reports
        </button>
        <KpiBoxes itemTitle={itemTitle} handlePrint={printInitOld} toggleMenus={toggleMenus} widgetId={widgetId} fetchWidgetReport={fetchWidgetReport} uniqueCurrencies={uniqueCurrencies} declarantNumber={declarantNumber} getFilterValueData={getFilterValueData} filteredData={filteredData} filterRemoveFunction={filterRemoveFunction} totalImportValue={totalImportValue} />
        <Box sx={styles.chartContainer} flexWrap={'wrap'} className="printReport">
          {(gridLoading) ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', paddingTop: '200px', flexWrap: 'wrap' }}>
              <CircularProgress sx={{ color: '#ff6b04' }} />
            </Box>
          ) : (
            <>
              {printLoading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px', flexWrap: 'wrap' }} className="printLoader">
                  <CircularProgress sx={{ color: '#ff6b04' }} />
                </Box>
              )}
              <Box sx={{ padding: '0 10px' }}>
                <DynamicGrid data={widgetData} itemTitle={itemTitle} reportFormattedDate={reportFormattedDate} filters={filters} page="report" />
              </Box>
              {showPrint &&
                <Box sx={{ padding: '0 10px', opacity: 0 }}>
                  <PrintDynamicGrid data={widgetData} itemTitle={itemTitle} reportFormattedDate={reportFormattedDate} filters={filters} page="report" printSuccess={printSuccess} />
                </Box>
              }
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Report;
