import React, { useReducer, createContext, useContext } from "react";



export const initialState = {
  hideLabel: false,
  keyLabel: '',
  customTitle: '',
  logarithmicScale: false,
  valueLabel: '',
  settingModel: [],
  settingData: {},
};

// const reducer: React.Reducer<InitialState, Action> = (state, action) => {
export const reducer = (state, action) => {
  switch (action.type) {
    case "settingData":
      return { 
        ...state, 
        settingData: {
          ...state.settingData,
          ...action.payload // Merge new settingData into existing settingData
        }
      };
    case "clearSettingData": // Add a new case to clear settingData
      return { 
        ...state, 
        settingData: {} // Clear the settingData by setting it to an empty object
      };
    case "hideLabel":
      return { ...state, hideLabel: action.payload };
    case "keyLabel":
      return { ...state, keyLabel: action.payload };
    case "customTitle":
      return { ...state, customTitle: action.payload };
    case "logarithmicScale":
      return { ...state, logarithmicScale: action.payload };
    case "valueLabel":
      return { ...state, valueLabel: action.payload };

    case "settingModel":
      const existingIndex = state.settingModel.findIndex(
        model => model.modalId === action.payload.modalId
      );

      if (existingIndex !== -1) {
        // Update the existing model
        const updatedModels = state.settingModel.map((model, index) =>
          index === existingIndex ? action.payload : model
        );

        return { ...state, settingModel: updatedModels };
      } else {
        // Add the new model
        return {
          ...state,
          settingModel: [...state.settingModel, action.payload]
        };
      }
    default:
      return state;
  }
};
// Create a context
const GlobalStateContext = createContext({});

export const useGlobalContext = () => useContext(GlobalStateContext);

// Create a provider component
export const GlobalStateProvider = ({
  children,
}) => {
  const [state, setState] = useReducer(reducer, initialState);

  return (
    <GlobalStateContext.Provider value={{ state, setState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};