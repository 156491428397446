import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Box, CircularProgress, FormControl, MenuItem, Select } from '@mui/material';
import { setCurrencyList } from "../../../reducers/userProfileSlice";
import { useDispatch, useSelector } from 'react-redux';
import {currencyIcon} from "../../analytical/AnalyticalCommon"
import { API_URL } from "../../../services/Config";
const CurrencyFilters = ({ onCurrencyChange }) => {
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.auth.access_token);
  const hasFetched = useRef(false);
  const [loading, setLoading] = useState(false);
  // State to store fetched currency data
  const [currencyData, setCurrencyData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const currencyFlagMap = currencyIcon();
  const fetchCurrenciesList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/analytics/widgets/currencies/`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });
      const data = await response.json();
      dispatch(setCurrencyList(data));
      setCurrencyData(data);

    } catch (error) {
      console.error("Error fetching currencies list:", error);
    }
    setLoading(false);
  }, [dispatch, access_token]);

  useEffect(() => {
    if (access_token && !hasFetched.current) {
      fetchCurrenciesList();
      hasFetched.current = true;
    }
  }, [access_token, fetchCurrenciesList]);

  // Handle change in selected currency
  const handleCurrencyChange = (event) => {
    const value = event.target.value;
    setSelectedCurrency(value);
    onCurrencyChange({ type: 'string', value: value });
  };

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70%' }}>
          <CircularProgress sx={{ color: '#ff6b04' }} />
        </Box>
      ) : (
        <FormControl variant="filled" sx={{ minWidth: 180 }}>
          {/* <InputLabel>
        Currency
      </InputLabel> */}
          <Select
            value={selectedCurrency}
            placeholder='currency'
            onChange={handleCurrencyChange}
          >
            {currencyData?.map((currency, index) => (
              <MenuItem className='columnlist' key={index} value={currency?.currencyCode}>
                 <span class="flag-icon">{currencyFlagMap[currency?.currencyCode]}</span> {currency?.currencyCode} {currency?.symbol}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default CurrencyFilters;
