import {
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authAPI } from "../../services/authAPI";
import IconEdit from '../../assets/images/icon-edit-o.svg';
import IconDelete from '../../assets/images/icon-delete-o.svg';
const UserList = () => {
  const access_token = useSelector((state) => state.auth.access_token);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [modal, setModal] = useState(false);
  const [isResponse, setIsResponse] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    userId: "",
    first_name: "",
    last_name: "",
    email: "",
    country: "",
    zone: "",
    position: "",
  });
  const toggleModal = () => {
    setModal(!modal);
    if (modal) {
      document.body.classList.remove("active-modal");
    } else {
      document.body.classList.add("active-modal");
    }
  };

  const fetchCountryList = async () => {
    try {
      const countriesData = await authAPI.countryList();
      setCountries(countriesData);
    } catch (error) {
      console.error("Error fetching country list:", error);
    }
  };

  const handleUpdateUser = (user) => {
    setSelectedUser(user);
    setSelectedCountry(user.user.country);
    // Initialize the formData state with the user's current data
    setFormData({
      userId: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.user.email,
      country: user.user.country,
      zone: user.user.zone,
      position: user.position,
    });
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setSelectedUser(null);
    setIsPopupOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const fetchUserProfile = useCallback(async () => {
    setIsLoading(true);
    try {
      if (access_token) {
        const response = await authAPI.getUsers({ access_token });
        setUserList(response);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("Error fetching user profile:", error);
    }
  }, [access_token]);

  useEffect(() => {
    fetchUserProfile();
    fetchCountryList();
  }, [isResponse, fetchUserProfile]);

  const onAccept = async () => {
    setShowModal(false);
    try {
      const response = await authAPI.deleteUser({
        access_token,
        userId: userIdToDelete,
      });
      if (response.message) {
        toast.success(response.message);
        // Update the user list by removing the deleted user
        setUserList((prevUserList) =>
          prevUserList.filter((user) => user.id !== userIdToDelete)
        );
      } else if (response.error) {
        toast.error(response.error);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsDeleting(false);
      setUserIdToDelete(null); // Reset the userIdToDelete
    }
  };

  const OnUnAccept = () => {
    // hide the modal
    setIsDeleting(false);
    setShowModal(false);
    setUserIdToDelete(null); // Reset the userIdToDelete if canceled
  };

  const handleDeleteUser = (userId) => {
    setIsDeleting(true);
    setShowModal(true);
    setUserIdToDelete(userId); // Set the userIdToDelete when deleting a user
  };

  const handleSubmitData = async (e) => {
    e.preventDefault();
    setIsResponse(false);
    try {
      setIsLoading(true);
      const { userId, first_name, last_name, email, position, zone } = formData;
      const response = await authAPI.updateUserData({
        access_token,
        userId,
        first_name,
        last_name,
        email,
        position,
        zone,
        country: selectedCountry,
      });
      if (response) {
        const updatedUserList = userList.map((u) =>
          u.id === userId
            ? {
              ...u,
              first_name,
              last_name,
              position,
              zone,
              user: { ...u.user, country: selectedCountry },
            }
            : u
        );
        setUserList(updatedUserList);
        setIsResponse(true);
        toast.success("User updated sucessfully");
        // Close the popup after successful update
        handleClosePopup();
        setModal(false);
      } else if (response.error) {
        toast.error("Something wrong..!");
      }
    } catch (error) {
      toast.error("Something wrong..!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddUserClick = () => {
    navigate("/add-user");
  };

  return (
    <div>
      <div className="pagecontainer view-user scrollablearea">
        <div className="flex justify-between">
          <h1 className="text-3xl font-medium mb-8">Users list</h1>
          <div className="text-right">
            <button className="button-default" onClick={handleAddUserClick}>
              Add New User
            </button>
          </div>
        </div>
        <ToastContainer />
        {showModal && (
          <div className="popup">
            <div className="modal-content w-[20rem] p-10">
              <div className="modal-header"></div>
              <div className="modal-body text-center">
                <h6>Are you sure ?</h6>
              </div>
              <div className="modal-footer flex justify-center">
                <button className="button-default" onClick={onAccept}>
                  OK
                </button>
                <button onClick={OnUnAccept}>Cancel</button>
              </div>
            </div>
          </div>
        )}

        {isLoading ? (
          // Loader while loading data
          <div className="loader" />
        ) : (
          <>
            {userList.length === 0 ? (
              // Check if user list is empty
              <div className="user-not-found-message">User not found.</div>
            ) : (
              <div class="overflow-auto">
              <table className="w-full border-collapse table mt-3">
                <thead className="table-light">
                  <tr>
                    <th className="p-2 text-left font-medium">First Name</th>
                    <th className="p-2 text-left font-medium">Last Name</th>
                    <th className="p-2 text-left font-medium">Email</th>
                    <th className="p-2 text-left font-medium">Country</th>
                    <th className="p-2 text-left font-medium">Zone</th>
                    <th className="p-2 text-left font-medium">Position</th>
                    <th className="p-2 text-left w-32 font-medium">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userList.map((user) => (
                    <tr key={user.id}>
                      <td className="p-2" data-label="First Name">{user.first_name}</td>
                      <td className="p-2" data-label="Last Name">{user.last_name}</td>
                      <td className="p-2" data-label="Email">{user.user.email}</td>
                      <td className="p-2" data-label="Country">{user.user.country}</td>
                      <td className="p-2" data-label="Zone">{user.user.zone}</td>
                      <td className="p-2" data-label="Position">{user.position}</td>
                      <td className="p-2" data-label="Action">
                        <button
                          onClick={() => {
                            handleUpdateUser(user);
                            toggleModal();
                          }}
                          className="btn-modal mr-2"
                        >
                         <img src={IconEdit} alt="Edit" style={{ width: '18px', height: '18px' }} />
                        </button>
                        <button
                          onClick={() => handleDeleteUser(user.id)}
                          disabled={isDeleting}
                        >
                          <span
                            className="h-6 w-6 text-blue-500"
                            style={{ opacity: isLoading ? 0.5 : 1 }}
                          >
                           <img src={IconDelete} alt="Delete" style={{ width: '18px', height: '18px' }} />
                          </span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            )}
          </>
        )}

        {modal && isPopupOpen && selectedUser && (
          <div className="popup">
            <div className="popup-inner">
              <h2 className="text-2xl font-medium mb-5">Update User</h2>
              <button className="close-btn" onClick={toggleModal}>
                {" "}
                <XMarkIcon className="h-6 w-6 text-blue-500" />
              </button>
              <form onSubmit={handleSubmitData}>
                <div className="flex -mx-2">
                <div className="form-group mb-3 w-50 px-2">
                  <label>
                    First Name:
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group mb-3 w-50 px-2">
                  <label>
                    Last Name:
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                  />
                </div>
                </div>
                <div className="flex -mx-2">
                <div className="form-group mb-3 w-50 px-2">
                  <label>
                    Email:
                  </label>
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    readOnly
                  />
                </div>
                <div className="form-group mb-3 w-50 px-2">
                  <label>
                    Country:
                  </label>
                  <select
                    className="bg-gray p-2 w-full"
                    value={selectedCountry}
                    onChange={handleCountryChange}
                  >
                    {/* Default selected country */}
                    <option value="">{selectedCountry}</option>

                    {/* Other countries */}
                    {countries.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                </div>
                <div className="flex -mx-2">
                <div className="form-group mb-3 w-50 px-2">
                  <label>
                    Zone:
                  </label>
                  <input
                    type="text"
                    name="zone"
                    value={formData.zone}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group mb-3 w-50 px-2">
                  <label>
                    Position:
                  </label>
                  <input
                    type="text"
                    name="position"
                    value={formData.position}
                    onChange={handleInputChange}
                  />
                </div>
                </div>
                <button
                  className="button-default mt-0"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Please wait..." : "Update"}
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserList;
