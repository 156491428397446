import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { toast } from 'react-toastify';
import { useSelector, shallowEqual } from 'react-redux';
import { authAPI } from '../../../services/authAPI';
import { Box, CircularProgress, Typography, IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
const LineChartComponent = ({ widgetName, widgetTitle, widgetData, leftItem = null, helpText, viewHelpText, hideInfo = false }) => {
  const name = widgetName;
  const title = widgetTitle;
  const [lineChartData, setLineChartData] = useState(widgetData || []);
  const access_token = useSelector((state) => state.auth.access_token, shallowEqual);
  const [loading, setLoading] = useState(false);
  const hasFetched = useRef(false); // Use a ref to track if the API has been called
  const [isFetched, setIsFetched] = useState(false); // State to track if fetching is complete
  const location = useLocation();
  const pathType = location.pathname;

  useEffect(() => {
    window.addEventListener('filterUpdate', fetchLineChartData);
    window.addEventListener('currencyUpdate', fetchLineChartData);

    return () => {
      window.removeEventListener('filterUpdate', fetchLineChartData);
      window.removeEventListener('currencyUpdate', fetchLineChartData);
    };
  }, []);

  //const currencySymbol = window.localStorage.getItem('currencySymbol');

  const fetchLineChartData = async () => {
    setLoading(true);
    try {
      const filters = window.localStorage.getItem('filters');
      const viewCurrency = window.localStorage.getItem('viewCurrency');
      const formData = new FormData();
      formData.append('name', name);
      if (filters !== '' && filters !== null && filters !== undefined)
        formData.append('filters', filters);

      formData.append('viewCurrency', (viewCurrency || 'GBP'));
      const response = await authAPI.fetchInfoDisplayWidget({ access_token, formData });
      setLineChartData(response?.result || []);
      setIsFetched(true);
    } catch (error) {
      toast.error(error?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (access_token && !hasFetched.current && !widgetData) {
      fetchLineChartData();
      hasFetched.current = true; // Set the flag to true after the first call
    }
  }, [access_token, widgetData]);

  useEffect(() => {
    if (leftItem !== null && leftItem?.[0]?.item === widgetName) {
      fetchLineChartData();
    }
  }, [leftItem])

  if (loading) {
    return (
      <div>
        <Box className="item-content" sx={{ paddingTop: '160px', height: '400px' }}>
          <Box className="dashboard-item">
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress sx={{ color: '#ff6b04' }} />
            </Box>
          </Box>
        </Box>
      </div>
    );
  }

  if (isFetched && !lineChartData.length) {
    return (
      <Box className="item-content" sx={{ height: '400px' }}>
        <Typography sx={{ textAlign: 'left', fontWeight: '600', padding: '5px 10px', fontSize: '20px' }}>
          {widgetTitle}
        </Typography>
        <Box className="dashboard-item">
          <Typography variant="h6" sx={{ textAlign: 'center', p: 20 }}>
            No result found
          </Typography>
        </Box>
      </Box>
    );
  }

  const options = {
    chart: {
      type: 'line',
      borderRadius: '.5rem', // Set the border radius here
    },
    accessibility: {
      enabled: false // Enable accessibility module
    },
    title: {
      text: title,
      align: 'left',
      fontSize: '10px',
    },
    xAxis: {
      categories: lineChartData.map(item => {
        if (item?.date) {
          return moment(item?.date, "YYYY-MM-DD").format("MMM YYYY"); // Adjust the date format here
        } else if (item?.year_month) {
          return moment(item?.year_month, "YYYY-MM").format("MMM YYYY"); // Adjust the date format here
        }
        return ''; // Fallback in case neither date nor year_month is available
      }),
      labels: {
        rotation: -45,
        style: {
          fontSize: '12px',
          fontFamily: 'Verdana, sans-serif'
        }
      }
    },
    yAxis: {
      title: {
        text: 'Values'
      }
    },
    series: [{
      name: 'Values',
      data: lineChartData.map(item => item?.value ?? item?.totalValue ?? 0), // Fallback in case neither value nor totalValue is available
    }],
    tooltip: {
      formatter: function () {
        return `<b>Date: ${this.x}</b><br/>Value:${this.y}`;
      }
    }
  };

  return (
    <div>
      <Box className="item-content tooltip-outer" sx={{ height: '400px' }}>
        {pathType === '/analyticals' && viewHelpText ? null : ((pathType === '/analyticals') ? (
          <Tooltip title={helpText} placement="right" className='info-tooltip'>
            <IconButton sx={{ p: '0' }}>
              <InfoIcon sx={{ color: '#000' }} />
            </IconButton>
          </Tooltip>
        ) : null
        )}
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
        {pathType === '/analyticals' && viewHelpText ? (
          <p className='printInfoText'>{viewHelpText}</p>
        ) : null}
      </Box>
    </div>
  );
};

export default LineChartComponent;
