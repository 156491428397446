// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kpibox{background: rgb(52,136,198);
    background: linear-gradient(277deg, rgba(52,136,198,1) 16%, rgba(25,66,96,1) 100%);
    color:#fff;

}

/* @media print {
    .map-container {
      width: 100%;
      height: 600px;
      display: block !important;
    }
  
    .mapboxgl-canvas {
      width: 100% !important;
      height: auto !important;
    }
  } */`, "",{"version":3,"sources":["webpack://./src/components/analytical/analytical.css"],"names":[],"mappings":"AAAA,QAAQ,2BAA2B;IAC/B,kFAAkF;IAClF,UAAU;;AAEd;;AAEA;;;;;;;;;;;KAWK","sourcesContent":[".kpibox{background: rgb(52,136,198);\n    background: linear-gradient(277deg, rgba(52,136,198,1) 16%, rgba(25,66,96,1) 100%);\n    color:#fff;\n\n}\n\n/* @media print {\n    .map-container {\n      width: 100%;\n      height: 600px;\n      display: block !important;\n    }\n  \n    .mapboxgl-canvas {\n      width: 100% !important;\n      height: auto !important;\n    }\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
