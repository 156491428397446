import React, { useEffect, useReducer, useCallback, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "react-toastify/dist/ReactToastify.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import IconDelete from '../../assets/images/icon-delete-o.svg';
import IconEdit from '../../assets/images/icon-edit-o.svg';
import HideSidebar from '../../assets/images/hide-sidebar.svg';
import ShowSidebar from '../../assets/images/show-sidebar.svg';
import IconClose from '../../assets/images/icon-close.svg';

import {
  MoveDown,
  Delete,
  Edit,
  Share,
  FileDownload,
  Restore,
  Folder,
  ModeEdit,
} from "@mui/icons-material";
//** local files */
import { authAPI } from "../../services/authAPI";
import FileUploadModal from "./FileUploadModel";

import "./vault.css";
import VersionHistory from "./VersionHistory";
import PeopleListPopup from "./ShareFilePopup";
import MoveFilePopup from "./MoveFilePopup";
import RenameFilePopup from "./RenameFilePopup";
import RenameFolderPopup from "./RenameFolderPopup";
import FileUploadProgress from "./FileUploadProgress";
import FolderCreateModel from "./FolderCreateModel";
import { Box, CircularProgress, Tab, Tabs, TextField, } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useParams } from 'react-router-dom';
import UseDebounce from '../vault/useDebounce';
import Stack from '@mui/material/Stack';
import Pagination from 'react-js-pagination';
const initialState = {
  folderList: null,
  sharedList: null,
  SelectedFolder: {},
  fileList: [],
  sharedFileList: [],
  popUpOpen: false,
  popFolderOpen: false,
  toggleOpen: false,
  toggleData: {},
  isSharePopupShow: false,
  shareClicked: {},
  isMoveFilePopupShow: false,
  selectedFile: {},
  isRenameFilePopupShow: false,
  isRenameFolderPopupShow: false,
  fileUploadProgress: false,
  isLoading: true,
  isLoadingFile: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setFolderList":
      return { ...state, folderList: action.payload };
    case "setSharedList":
      return { ...state, sharedList: action.payload };
    case "setSelectedFolder":
      return { ...state, selectedFolder: action.payload };
    case "setSelectedSharedFolder":
      return { ...state, selectedSharedFolder: action.payload };
    case "setSelectedFolderRef":
      return { ...state, SelectedFolder: action.payload };
    case "setSelectedSharedFolderRef":
      return { ...state, SelectedSharedFolder: action.payload };
    case "addFileToSelection":
      return { ...state, fileList: action.payload };
    case "addSharedFileToSelection":
      return { ...state, sharedFileList: action.payload };
    case "popUpOpen":
      return { ...state, popUpOpen: action.payload };
    case "popFolderOpen":
      return { ...state, popFolderOpen: action.payload };
    case "toggleOpen":
      return { ...state, toggleOpen: action.payload };
    case "toggleData":
      return { ...state, toggleData: action.payload };
    case "isSharePopupShow":
      return { ...state, isSharePopupShow: action.payload };
    case "isMoveFilePopupShow":
      return { ...state, isMoveFilePopupShow: action.payload };
    case "isRenameFilePopupShow":
      return { ...state, isRenameFilePopupShow: action.payload };
    case "isRenameFolderPopupShow":
      return { ...state, isRenameFolderPopupShow: action.payload };
    case "selectedFile":
      return { ...state, selectedFile: action.payload };
    case "shareClicked":
      return { ...state, shareClicked: action.payload };
    case "fileUploadProgress":
      return { ...state, fileUploadProgress: action.payload };
    case "isLoading":
      return { ...state, isLoading: action.payload };
    case "isLoadingFile":
      return { ...state, isLoadingFile: action.payload };
    default:
      return state;
  }
};
const ITEM_HEIGHT = 48;
const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));
export default function Vault() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const selectedFile = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const userProfile = useSelector((state) => state.userProfile);
  const access_token = useSelector((state) => state.auth.access_token, shallowEqual);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [groupFolder, setGroupFolder] = useState(null);

  const [value, setValue] = React.useState('folder_list');
  const [listLimit, setListLimit] = React.useState(10);
  const [listTotal, setListTotal] = React.useState(10);
  const [customFoldersWithAll, setCustomFoldersWithAll] = React.useState([]);



  const handleChange = (event, newValue) => {
    localStorage.removeItem('vaultItemId');
    setValue(newValue);
    var netValue = 'false';
    if (value === "folder_list") {
      netValue = 'true';
    }
    state.fileList = null;
    state.sharedFileList = null;
    state.selectedSharedFolder = null;
    state.selectedFolder = null;
    setGroupFolder(null)
    fetchDocumentList(netValue);
  };

  const handleClick = (row, event) => {
    setAnchorEl(event.currentTarget);
    // Set the selected row here
    dispatch({ type: "shareClicked", payload: row });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const debouncedSearchTerm = UseDebounce(searchValue, 300);

  const handleSearch = (e) => {
    dispatch({ type: "isLoading", payload: true })
    setSearchValue(e?.target?.value);
    state.fileList = null;
    state.sharedFileList = null;
    dispatch({ type: "isLoading", payload: false })

  };

  const fetchDocumentList = useCallback(async (netValue = 'false') => {
    dispatch({ type: "isLoading", payload: true })
    const List = await authAPI.getVaultFoldersList({ access_token, value: netValue });
    if (List?.status === true) {
      if (!shallowEqual(List, state.folderList, state.sharedList)) {
        const newData = List?.result;
        dispatch({ type: "setFolderList", payload: newData });
        dispatch({ type: "setSharedList", payload: newData });
      }
      dispatch({ type: "isLoading", payload: false })
    }
  }, [access_token, state.folderList, state.sharedList, value, state.isLoading]);

  useEffect(() => {
    fetchDocumentList();
  }, []);

  useEffect(() => {
    if (value == "folder_list" && (debouncedSearchTerm || currentPage)) {
      getSelectedFolderFiles();
    } else if (value == "shared_list" && (debouncedSearchTerm || currentPage)) {
      getSelectedSharedFolderFiles();
    }
  }, [debouncedSearchTerm, currentPage]);


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const vaultId = localStorage.getItem('vaultItemId')

  const getSelectedFolderFiles = useCallback(
    async (event) => {

      dispatch({ type: "isLoadingFile", payload: true })
      const data = event?.id || state.selectedFolder?.id || vaultId;
      const List = await authAPI.getSelectedFolderFileList(access_token, data, debouncedSearchTerm, currentPage);
      if (List?.status) {
        const newData = List?.result;
        dispatch({ type: "addFileToSelection", payload: newData });
        setListTotal(List?.totalRecord)
        setListLimit(List?.limit)
      }
      dispatch({ type: "isLoadingFile", payload: false })
    },
    [access_token, state.fileList, currentPage, debouncedSearchTerm]
  );

  const getSelectedSharedFolderFiles = useCallback(
    async (event) => {
      dispatch({ type: "isLoadingFile", payload: true })

      const data = event?.id || state.selectedSharedFolder?.id;
      const List = await authAPI.getSelectedSharedFolderFileList(access_token, data, debouncedSearchTerm, currentPage);
      if (List?.status) {

        const newData = List?.result;
        dispatch({ type: "addSharedFileToSelection", payload: newData });
        setListTotal(List?.totalRecord)
        setListLimit(List?.limit)
        dispatch({ type: "isLoadingFile", payload: false })

      }
    },
    [access_token, state.sharedFileList, currentPage, debouncedSearchTerm]
  );

  const handelFolderClick = useCallback(
    (event) => {
      localStorage.setItem('vaultItemId', event?.id)
      setCurrentPage(1)
      dispatch({ type: "setSelectedFolderRef", payload: event });
      dispatch({ type: "setSelectedFolder", payload: event });
      getSelectedFolderFiles(event);
      setGroupFolder(event?.is_group_folder)
    },
    [getSelectedFolderFiles]
  );

  const handelSharedFolderClick = useCallback(
    (event) => {
      dispatch({ type: "setSelectedSharedFolderRef", payload: event });
      dispatch({ type: "setSelectedSharedFolder", payload: event });
      getSelectedSharedFolderFiles(event);
    },
    [getSelectedSharedFolderFiles]
  );

  const handleUplaodFiles = useCallback(
    async (files) => {
      if (state.SelectedFolder?.id == undefined) {
        await toast.error("Please select  a folder to upload files");
        return;
      }
      dispatch({ type: "popUpOpen", payload: true });
    },
    [state.SelectedFolder]
  );

  const handleCreateFolder = useCallback(
    async (files) => {
      if (state.SelectedFolder?.id == undefined) {
        await toast.error("Please select the parent folder first");
        return;
      }
      dispatch({ type: "popFolderOpen", payload: true });
    },
    [state.SelectedFolder]
  );


  const handelResponseGet = useCallback(async (response) => {
    dispatch({ type: "popUpOpen", payload: false });
    dispatch({ type: "fileUploadProgress", payload: true });
  }, []);

  const handleDeleteFile = useCallback(
    (file) => {
      setAnchorEl(null);
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
        allowOutsideClick: false,
        showCloseButton: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `Do you really want to delete this file ${file?.name}? This action will also remove the file from the shared user's access.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            let fileId = file?.id;
            try {
              const response = await authAPI.deleteSelectedFolderFile({
                access_token,
                fileId,
              });

              if (response.status) {
                swalWithBootstrapButtons.fire({
                  title: "Deleted!",
                  text: `Your file ${file?.name} has been deleted.`,
                  icon: "success",
                });
                await ApiCalling()
                await getSelectedFolderFiles(state.selectedFolder);
                
              }
            } catch (error) {
              console.log(error);
            }
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire({
              title: "Cancelled",
              text: `Your file ${file?.name} is safe!`,
              icon: "error",
            });
          }
        });
    },
    [access_token, getSelectedFolderFiles, state.selectedFolder]
  );

  const handleDeleteFolder = useCallback(
    (file) => {
      setAnchorEl(null);
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
        allowOutsideClick: false,
        showCloseButton: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `Do you really want to delete this folder ${file?.name}!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const data = {
              "folder_id": file?.id
            }
            try {
              const response = await authAPI.deleteSelectedFolder({ access_token, data });

              if (response.status) {
                swalWithBootstrapButtons.fire({
                  title: "Deleted!",
                  text: `Your folder ${file?.name} has been deleted.`,
                  icon: "success",
                });
                await ApiCalling()
                await getSelectedFolderFiles(state.selectedFolder);
              }
            } catch (error) {
              console.log(error);
            }
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire({
              title: "Cancelled",
              text: `Your folder ${file?.name} is safe!`,
              icon: "error",
            });
          }
        });
    },
    [access_token, getSelectedFolderFiles, state.selectedFolder]
  );

  const handelFileDownload = useCallback((file) => {
    window.open(file?.url, "_blank", "noreferrer");
    setAnchorEl(null);
  }, []);

  const handelVersionHistory = useCallback((file) => {
    dispatch({ type: "toggleOpen", payload: true });
    dispatch({ type: "toggleData", payload: file });
  }, []);

  const handleMoveData = useCallback((row) => {
    dispatch({
      type: "isMoveFilePopupShow",
      payload: true,
    });
    selectedFile.current = row;
    setAnchorEl(null);
  }, []);

  const ApiCalling = async() =>{
    try {
      const response = await authAPI.moveFolderList({ access_token });
      setCustomFoldersWithAll(response?.result)
      //handleMoveFileToFolder();
    } catch (error) {
      console.log('error while calling Move Popup Api',error)
    }
  }

  useEffect(()=>{
    ApiCalling()
  },[])

  const handelMoveFileStatus = useCallback(
    (response) => {
      getSelectedFolderFiles(state.selectedFolder);
    },
    [getSelectedFolderFiles, state.selectedFolder]
  );

  const handleShareManager = useCallback((row, index) => {
    dispatch({
      type: "isSharePopupShow",
      payload: true,
    });

    setAnchorEl(null);
  }, []);

  const handelUploadResponse = useCallback(
    async (response) => {
      if (response?.status) {
        new Promise((resolve) => {
          setTimeout(async () => {
            await getSelectedFolderFiles(state.selectedFolder);
            dispatch({ type: "fileUploadProgress", payload: false });
          }, 500); // 1 seconds in milliseconds
        });
        // await getSelectedFolderFiles(state.selectedFolder);
        // dispatch({ type: "fileUploadProgress", payload: false });
      }
    },
    [getSelectedFolderFiles, state.selectedFolder]
  );

  const handelRenameFileStatus = useCallback(async (response) => {
    if (response?.status) {

      await getSelectedFolderFiles(state.selectedFolder);
      dispatch({ type: "isRenameFilePopupShow", payload: false })
    }
  }, [getSelectedFolderFiles, state.selectedFolder])

  const handelRenameFolderStatus = useCallback(async (response) => {
    if (response?.status) {

      await getSelectedFolderFiles(state.selectedFolder);
      dispatch({ type: "isRenameFolderPopupShow", payload: false })
    }
  }, [getSelectedFolderFiles, state.selectedFolder])


  const handelCloseShareFilePopUp = async () => {
    dispatch({ type: "isSharePopupShow", payload: false });
    await getSelectedFolderFiles(state.selectedFolder);
  };

  const getDateTime = (dt) => {
    const currentDate = new Date(dt);
    const currentDayOfMonth = currentDate.getUTCDate();
    const currentMonth = currentDate.getMonth(); // Be careful! January is 0, not 1
    const currentYear = currentDate.getFullYear();
    // const currentTime = currentDate.getHours();
    // const minutes = `0${currentDate.getMinutes()}`.slice(-2);

    const dateString =
      currentYear +
      "-" +
      (currentMonth < 10 ? "0" + (currentMonth + 1) : currentMonth + 1) +
      "-" +
      (currentDayOfMonth < 10 ? "0" + (currentDayOfMonth) : currentDayOfMonth);
    return dateString;
  };

  const folderList = state?.folderList || [];

  const groupFolders = folderList.filter(fl => fl.is_group_folder);
  const nonGroupFolders = folderList.filter(fl => !fl.is_group_folder);
  const folderSlug = useParams();
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  return (

    <div className={`anlytical-dashboard vault  ${isOpen ? 'showReportMenu' : 'hideReportMenu'}`}>
      <div className={`scrollablesidebar leftbar-vault folder-list ${isOpen ? 'show' : ''}`}
        id="left"
        style={{
          overflowY: "auto",

        }}
      >
        <button className="sidebarClose" onClick={toggle}>
          <img width="15" height="15" src={IconClose} alt="" />
        </button>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '15px' }}>
          <Tabs value={value} onChange={handleChange} >
            <Tab sx={{ textTransform: 'capitalize' }} value="folder_list" label="Folder List" />
            {userProfile?.user_type !== "is_client_user" &&
              <Tab sx={{ textTransform: 'capitalize' }} value="shared_list" label="Shared Folder List" />}
          </Tabs>
        </Box>
        {state?.folderList?.length === 0 ?
          <div
            className="rYfpJe"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <div
              jsaction="rcuQ6b:npT2md"
              tabindex="0"
              className="ab-S-Wg a-s-tb-paa-zf-ja-j"
              jscontroller="FFiA5c"
              aria-labelledby="empty-view-content-folders"
            >
              <div className="ab-S-Wb">
                <table>
                  <tr>
                    <td className="ab-S-c-qd-j">
                      <img
                        role="presentation"
                        src="//ssl.gstatic.com/docs/doclist/images/empty_state_home.svg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div id="empty-view-content-folders">
                        <div
                          role="heading"
                          className="ab-S-V"
                          id="empty-view-title-folders"
                          style={{ textAlign: "center" }}
                        >
                          No Folder Found
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          :
          <>
            {!state.isLoading ? value === 'folder_list' ? (
              <>
                {groupFolders.map((fl) => (
                  <div key={fl.id}>
                    <NavLink
                      onClick={() => handelFolderClick(fl)}
                      to={`/the-vault/${fl.name.toLowerCase().replace(/\s+/g, "-")}`}
                      className={state.selectedFolder?.id === fl.id
                        ? "d-flex ml-4 left-bar-btn mb-4 active"
                        : "d-flex ml-4 left-bar-btn mb-4"
                      }>

                      <div className="mr-3">
                        {(state.selectedFolder?.id === fl.id || vaultId === fl.id) ? (
                          <i className="fas fa-folder-open"></i>
                        ) : (
                          <i className="fas fa-folder"></i>
                        )}
                      </div>
                      <div>
                        <h5 className="my-auto">{fl.name}</h5>
                      </div>
                    </NavLink>
                  </div>
                ))}
                {nonGroupFolders.map((fl) => (
                  <div key={fl.id}>
                    <NavLink
                      onClick={() => handelFolderClick(fl)}
                      to={`/the-vault/${fl.name.toLowerCase().replace(/\s+/g, "-")}`}
                      className={
                        state.selectedFolder?.id === fl.id
                          ? "d-flex ml-4 left-bar-btn mb-4 active"
                          : "d-flex ml-4 left-bar-btn mb-4"
                      }>
                      <div className="mr-3">
                        {state.selectedFolder?.id === fl.id ? (
                          <i className="fas fa-folder-open"></i>
                        ) : (
                          <i className="fas fa-folder"></i>
                        )}
                      </div>
                      <div>
                        <h5 className="my-auto">{fl.name}</h5>
                      </div>
                    </NavLink>
                  </div>
                ))}
              </>
            ) : (
              <>
                {state?.folderList?.filter(fl => !fl.is_group_folder).map((fl) => {
                  return (
                    <div key={fl.id}>
                      <NavLink
                        onClick={() => handelSharedFolderClick(fl)}
                        to={`/the-vault/${fl.name.toLowerCase().replace(/\s+/g, "-")}`}
                        className={
                          state.selectedSharedFolder?.id === fl.id
                            ? "d-flex ml-4 left-bar-btn mb-4 active"
                            : "d-flex ml-4 left-bar-btn mb-4"
                        }>
                        <div className="mr-3">
                          {state.selectedSharedFolder?.id === fl.id ? (
                            <i className="fas fa-folder-open"></i>
                          ) : (
                            <i className="fas fa-folder"></i>
                          )}
                        </div>
                        <div>
                          <h5 className="my-auto">{fl.name}</h5>
                        </div>
                      </NavLink>
                    </div>
                  );
                })}
              </>
            ) :
              <CircularProgress className="loader-vault" />
            }
          </>
        }
      </div>

      <div className=" scrollablearea" id="right">
        <button className="sidebarToggle" onClick={toggle}>
          <img width="30" height="30" src={isOpen ? HideSidebar : ShowSidebar} alt="" /> {isOpen ? 'Hide' : 'Show'}  Folder list
        </button>

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a
                href="#"
                style={{ cursor: "pointer", color: "#000" }}
              >
                {state.SelectedFolder?.name}
              </a>
            </li>
          </ol>
        </nav>
        <div className="quick-access ml-2 mb-5" id="main">
          <div className="row align-items-center justify-content-start">
  
                   <div className="col-sm-12 col-md-6">
                   {state?.sharedFileList?.path?.length > 0 && (
                    <>
                  {state?.sharedFileList?.path?.map((bread, index) => {
                    return (
                    
                      <span className="mb-3 prgr" style={{ fontWeight: '600', color: '#000000', fontSize: '16px' }} onClick={() => handelSharedFolderClick(bread)} key={index}>
                        {bread?.name} {index < (state?.sharedFileList?.path?.length - 1) && ' > '}
                      </span>

                    )
                  })
                  }
                  </>
               )}
            {state?.fileList?.path?.length > 0 && (
              <>
                {state?.fileList?.path?.map((bread, index) => {
                  return bread ? (
               
                      <span
                        className="mb-3 prgr"
                        style={{ fontWeight: '600', color: '#000000', fontSize: '16px' }}
                        onClick={() => handelFolderClick(bread)}
                        key={index}
                      >
                        {bread.name} {index < (state.fileList.path.length - 1) && ` > `}
                      </span>
                  
                  ) : null;
                })}
              </>
            )}
              </div>
            <div className="col-sm-12 col-md-6 md:!justify-end sm:!justify-start" style={{ display: 'flex' }}>
              {((state.selectedFolder?.id && groupFolder && value === 'folder_list') || (value === 'shared_list' && state.selectedSharedFolder?.id)) && (
                <TextField
                  type="search"
                  label="Search..."
                  className="vault-search"
                  value={searchValue}
                  onChange={handleSearch}
                  variant="filled"
                />
              )}
              {(userProfile?.user_type !== "is_client_user" && value == 'folder_list' && !groupFolder && groupFolder !== null && folderSlug?.name != '' && folderSlug?.name != undefined && folderSlug?.name != null) && (
                <>
                  <button
                    className="upload-btn"
                    onClick={handleUplaodFiles}
                  >
                    <i className="fa fa-upload" style={{ width: "20px" }}></i>{" "}
                    Upload File
                  </button>
                  <button
                    className="upload-btn"
                    onClick={handleCreateFolder}
                  >
                    <i className="fa fa-file" style={{ width: "20px" }}></i>{" "}
                    Create Folder
                  </button>
                </>
              )}
            </div>
          </div>

          {!state.isLoadingFile ? state?.fileList?.file?.length > 0 || state?.fileList?.folders?.length > 0 || state?.sharedFileList?.folders?.length > 0 || state?.sharedFileList?.file?.length > 0 ?
            <TableContainer component={Paper} elevation={6}>
              <div className="overflow-auto">
                <Table className="w-full border-collapse table mt-3"

                  sx={{
                    minWidth: 600,
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow sx={{ background: '#fff' }}>
                      <TableCell sx={{ paddingLeft: '10px !important' }}>Name</TableCell>
                      {(groupFolder || value == "shared_list") && (
                        <>
                          <TableCell>Company</TableCell>
                          <TableCell>Document Holder</TableCell>
                        </>
                      )}
                      <TableCell align="right">Last Modified</TableCell>
                      {((userProfile?.user_type === "is_group_admin" && !groupFolder && value == 'folder_list') || (userProfile?.user_type === "is_client_admin" && value == 'folder_list')) && (
                        <TableCell align="right">People</TableCell>
                      )}
                      <TableCell align="right" sx={{ paddingRight: '10px !important' }}>Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {state?.fileList?.folders?.length > 0 && (
                      <>
                        {state?.fileList?.folders?.map((row, index) => (
                          <>
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="td" scope="row" style={{ paddingLeft: '10px' }}>
                                <i className="far fa-folder" > </i> {row.name}
                              </TableCell>
                              {(groupFolder || value == "shared_list") && (
                                <>
                                  <TableCell component="td" scope="row">
                                    {row.company}
                                  </TableCell>
                                  <TableCell component="td" scope="row">
                                    {row.client_admin}
                                  </TableCell>
                                </>
                              )}
                              <TableCell align="right">
                                {getDateTime(row.created_at)}
                              </TableCell>
                              {(userProfile?.user_type === "is_group_admin" || userProfile?.user_type === "is_client_admin") && (
                                <TableCell align="right"></TableCell>)}
                              <TableCell align="right">
                                <IconButton
                                  onClick={() => handelFolderClick(row)}
                                >
                                  <i className="far fa-folder" > </i>
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    dispatch({
                                      type: "isRenameFolderPopupShow",
                                      payload: true,
                                    });
                                    dispatch({
                                      type: "selectedFile",
                                      payload: row,
                                    });
                                    setAnchorEl(null);
                                  }}
                                  key="rename"
                                ><img src={IconEdit} alt="Add Icon" style={{ width: '18px', height: '18px' }} />

                                </IconButton>
                                {(userProfile?.user_type !== "is_client_user" && value == 'shared_list') || ((userProfile?.user_type !== "is_client_user" && value == 'folder_list')) ? (
                                  <IconButton
                                    onClick={() => handleDeleteFolder(row)}
                                  >
                                    <img src={IconDelete} alt="Add Icon" style={{ width: '18px', height: '18px' }} />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={() => handelFileDownload(row)}
                                  >
                                    <FileDownload fontSize="small" />
                                  </IconButton>
                                )}
                                {/*userProfile?.user_type ===
                                  "is_client_admin" && (
                                    <IconButton
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                      onClick={(event) => handleClick(row, event)}
                                    >
                                      <MoreVertIcon fontSize="small" />
                                    </IconButton>
                                  )*/}
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </>
                    )}
                    {state?.fileList?.file?.length > 0 && (
                      <>
                        {state?.fileList?.file?.map((row, index) => (
                          <>
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="td" scope="row" style={{ paddingLeft: '10px' }}>
                                <i className="far fa-file"></i> {row.name}
                              </TableCell>
                              {(groupFolder || value == "shared_list") && (
                                <>
                                  <TableCell component="td" scope="row">
                                    {row.company}
                                  </TableCell>
                                  <TableCell component="td" scope="row">
                                    {row.client_admin}
                                  </TableCell>
                                </>
                              )}
                              <TableCell align="right">
                                {getDateTime(row.created_at)}
                              </TableCell>
                              {(userProfile?.user_type !== "is_client_user" && value == 'folder_list' && !groupFolder) && (
                                <TableCell
                                  align="right"
                                // onClick={() => handleShareManager(row, index)}
                                >
                                  {/* Render the share icons based on the shareBy number */}
                                  {[...Array(1)].map((_, index) => {
                                    return (
                                      <IconButton key={index}>
                                        {row?.users?.some(
                                          (user) => user.has_permission === true
                                        ) == true ? (
                                          row?.users.map(
                                            (t) =>
                                              t.has_permission && (
                                                <div
                                                  className="people_firstletter"
                                                  style={{
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  {t.first_name?.charAt(0)}
                                                </div>
                                              )
                                          )
                                        ) : (
                                          // Display first characters for allowed users
                                          <div
                                            className="people_firstletter"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            +
                                          </div>
                                        )}
                                      </IconButton>
                                    );
                                  })}
                                </TableCell>
                              )}
                              <TableCell align="right">
                                <IconButton
                                  onClick={() => handelVersionHistory(row)}
                                >
                                  <Restore />
                                </IconButton>
                                {userProfile?.user_type ===
                                  "is_client_admin" ? (
                                  <IconButton
                                    onClick={() => handleDeleteFile(row)}
                                  >
                                    <img src={IconDelete} alt="Delete" />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={() => handelFileDownload(row)}
                                  >
                                    <FileDownload fontSize="small" />
                                  </IconButton>
                                )}
                                {(userProfile?.user_type !== "is_client_user" && value == 'folder_list' && !groupFolder) && (
                                  <>
                                    <IconButton
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                      onClick={(event) => handleClick(row, event)}
                                    >
                                      <MoreVertIcon fontSize="small" />
                                    </IconButton>
                                    <Menu
                                      id="long-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={open}
                                      onClose={handleClose}
                                      PaperProps={{
                                        style: {
                                          maxHeight: ITEM_HEIGHT * 4.5,
                                          width: "20ch",
                                        },
                                      }}
                                    >
                                      <MenuItem
                                        onClick={() => handleShareManager(row, index)}
                                        key={index}
                                      >
                                        <ListItemIcon>
                                          <Share fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Share</ListItemText>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => handleMoveData(row)}
                                        key="move"
                                      >
                                        <ListItemIcon>
                                          <MoveDown fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Move File</ListItemText>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          dispatch({
                                            type: "isRenameFilePopupShow",
                                            payload: true,
                                          });
                                          dispatch({
                                            type: "selectedFile",
                                            payload: row,
                                          });
                                          setAnchorEl(null);
                                        }}
                                        key="rename"
                                      >
                                        <ListItemIcon>
                                          <img src={IconEdit} alt="" />
                                        </ListItemIcon>
                                        <ListItemText>Rename</ListItemText>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => handelFileDownload(state.shareClicked)}
                                        key="download"
                                      >
                                        <ListItemIcon>
                                          <FileDownload fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Download</ListItemText>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          handleDeleteFile(state.shareClicked)
                                        }
                                        key="delete"
                                      >
                                        <ListItemIcon>
                                          <img src={IconDelete} alt="Delete" />
                                        </ListItemIcon>
                                        <ListItemText>Delete</ListItemText>
                                      </MenuItem>
                                    </Menu>
                                  </>
                                )}
                              </TableCell>
                            </TableRow>

                          </>
                        ))}
                      </>
                    )}
                  </TableBody>
                  <TableBody>
                    {state?.sharedFileList?.folders?.length > 0 && (
                      <>
                        {state?.sharedFileList?.folders?.map((row, index) => (
                          <>
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="td" scope="row" style={{ paddingLeft: '10px' }}>
                                <i className="far fa-folder" > </i> {row.name}
                              </TableCell>
                              {(userProfile?.user_type === "is_group_admin" || userProfile?.user_type === "is_client_admin") && (
                                <>
                                  <TableCell component="td" scope="row" >
                                    {row.company}
                                  </TableCell>
                                  <TableCell component="td" scope="row" >
                                    {row.client_admin}
                                  </TableCell>
                                </>
                              )}
                              <TableCell align="right">
                                {getDateTime(row.created_at)}
                              </TableCell>
                              {userProfile?.user_type === "is_group_admin" && value == 'folder_list' && (
                                <TableCell align="right"></TableCell>)}
                              <TableCell align="right">
                                <IconButton
                                  onClick={() => handelSharedFolderClick(row)}
                                >
                                  <Folder />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </>
                    )}
                    {state?.sharedFileList?.file?.length > 0 && (
                      <>
                        {state?.sharedFileList?.file?.map((row, index) => (
                          <>
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="td" scope="row" style={{ paddingLeft: '10px' }}>
                                <i className="far fa-file"></i> {row.name}
                              </TableCell>
                              {((userProfile?.user_type === "is_group_admin") || ((!groupFolder || value == "shared_list"))) && (
                                <>
                                  <TableCell component="td" scope="row">
                                    {row.company}
                                  </TableCell>
                                  <TableCell component="td" scope="row">
                                    {row.client_admin}
                                  </TableCell>
                                </>
                              )}
                              <TableCell align="right">
                                {getDateTime(row.created_at)}
                              </TableCell>
                              {userProfile?.user_type === "is_group_admin" && value == 'folder_list' && (
                                <TableCell align="right"></TableCell>)}

                              {(userProfile?.user_type !== "is_client_user" && value == 'folder_list') && (
                                <TableCell
                                  align="right"
                                >
                                  {/* Render the share icons based on the shareBy number */}
                                  {[...Array(1)].map((_, index) => {
                                    return (
                                      <IconButton key={index}>
                                        {row?.users?.some(
                                          (user) => user.has_permission === true
                                        ) == true ? (
                                          row?.users.map(
                                            (t) =>
                                              t.has_permission && (
                                                <div
                                                  className="people_firstletter"
                                                  style={{
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  {t.first_name?.charAt(0)}
                                                </div>
                                              )
                                          )
                                        ) : (
                                          // Display first characters for allowed users
                                          <div
                                            className="people_firstletter"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            +
                                          </div>
                                        )}
                                      </IconButton>
                                    );
                                  })}
                                </TableCell>
                              )}
                              <TableCell align="right">
                                <IconButton
                                  onClick={() => handelVersionHistory(row)}
                                >
                                  <Restore />
                                </IconButton>
                                {(userProfile?.user_type !== "is_client_user" && value == 'folder_list') ? (
                                  <IconButton
                                    onClick={() => handleDeleteFile(row)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={() => handelFileDownload(row)}
                                  >
                                    <FileDownload fontSize="small" />
                                  </IconButton>
                                )}
                                {(userProfile?.user_type !== "is_client_user" && value == "folder_list") && (
                                  <>
                                    <IconButton
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                      onClick={(event) => handleClick(row, event)}
                                    >
                                      <MoreVertIcon fontSize="small" />
                                    </IconButton>
                                    <Menu
                                      id="long-menu"
                                      anchorEl={anchorEl}
                                      keepMounted
                                      open={open}
                                      onClose={handleClose}
                                      PaperProps={{
                                        style: {
                                          maxHeight: ITEM_HEIGHT * 4.5,
                                          width: "20ch",
                                        },
                                      }}
                                    >
                                      <MenuItem
                                        onClick={() => handleShareManager(row, index)}
                                        key={index}
                                      >
                                        <ListItemIcon>
                                          <Share fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Share</ListItemText>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => handleMoveData(row)}
                                        key="move"
                                      >
                                        <ListItemIcon>
                                          <MoveDown fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Move File</ListItemText>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          dispatch({
                                            type: "isRenameFilePopupShow",
                                            payload: true,
                                          });
                                          dispatch({
                                            type: "selectedFile",
                                            payload: row,
                                          });
                                          setAnchorEl(null);
                                        }}
                                        key="rename"
                                      >
                                        <ListItemIcon>
                                          <Edit fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Rename</ListItemText>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => handelFileDownload(state.shareClicked)}
                                        key="download"
                                      >
                                        <ListItemIcon>
                                          <FileDownload fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Download</ListItemText>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() =>
                                          handleDeleteFile(state.shareClicked)
                                        }
                                        key="delete"
                                      >
                                        <ListItemIcon>
                                          <Delete fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Delete</ListItemText>
                                      </MenuItem>
                                    </Menu>
                                  </>
                                )}
                              </TableCell>
                            </TableRow>

                          </>
                        ))}
                      </>
                    )}

                  </TableBody>
                </Table>
              </div>
              {/* {((state.selectedFolder?.id && groupFolder && value == "folder_list") || (state.selectedSharedFolder?.id && !groupFolder && value == "shared_list")) && ( */}
              {(currentPage > 1 || listTotal > 10) && <Pagination
                activePage={currentPage}
                itemsCountPerPage={listLimit}
                totalItemsCount={listTotal} // Total number of items you want to paginate
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />}

              {/* )} */}
            </TableContainer>
            : !state.isLoadingFile &&
            <div
              className="rYfpJe"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                jsaction="rcuQ6b:npT2md"
                tabindex="0"
                className="ab-S-Wg a-s-tb-paa-zf-ja-j"
                jscontroller="FFiA5c"
                aria-labelledby="empty-view-content-folders"
              >
                <div className="ab-S-Wb">
                  <table>
                    <tr>
                      <td className="ab-S-c-qd-j">
                        <img
                          role="presentation"
                          style={{ width: "500px", margin: "auto" }}
                          src="//ssl.gstatic.com/docs/doclist/images/empty_state_home.svg"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div id="empty-view-content-folders">
                          <div
                            role="heading"
                            className="ab-S-V"
                            id="empty-view-title-folders"
                            style={{ textAlign: "center" }}
                          >
                             Welcome to the Vault. Please select a folder from the list in the left-hand menu. Once you select a folder, the upload files option will become available.
                          </div>
                          {/* <div
                                  className="ab-S-Sg"
                                  style={{ textAlign: "center" }}
                                >
                                  Drag your files and folders here or use the
                                  “Upload File” button to upload
                                </div> */}
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div> : (!state.isLoading) &&
          <div className="dashboard-logo loader-logo">
            <div className="animated-logo">
              <div className="animated-logo-360"></div>
            </div>
          </div>
          }
          {state.isLoading && (
            <div className="dashboard-logo loader-logo">
              <div className="animated-logo">
                <div className="animated-logo-360"></div>
              </div>
            </div>
          )}
        </div>

        <FileUploadModal
          openPopup={state.popUpOpen}
          handleClose={() => {
            dispatch({ type: "popUpOpen", payload: false });
          }}
          fileId={state.selectedFolder}
          onResponseGet={handelResponseGet}
        />
        <FolderCreateModel openPopup={state.popFolderOpen}
          handleClose={() => {
            dispatch({ type: "popFolderOpen", payload: false });
            ApiCalling()
            handelFolderClick({
              id: state.selectedFolder?.id,
              name: state.selectedFolder?.name
            })
          }}
          fileId={state.selectedFolder}
          onResponseGet={handelResponseGet}
        />
        <VersionHistory
          toggleOpen={state.toggleOpen}
          onClose={() =>
            dispatch({ type: "toggleOpen", payload: !state.toggleOpen })
          }
          file={state.toggleData}
          toggle={"right"}
        />
        <PeopleListPopup
          onClose={handelCloseShareFilePopUp}
          open={state.isSharePopupShow}
          sharedUsers={state.shareClicked}
        />
        <MoveFilePopup
          folderList={customFoldersWithAll}
          selectedFile={state.shareClicked}
          responseStatus={handelMoveFileStatus}
          onClose={() =>
            dispatch({ type: "isMoveFilePopupShow", payload: false })
          }
          open={state.isMoveFilePopupShow}
        />
        <RenameFilePopup
          onClose={() =>
            dispatch({ type: "isRenameFilePopupShow", payload: false })
          }
          open={state.isRenameFilePopupShow}
          fileId={state.shareClicked}
          responseStatus={handelRenameFileStatus}
        />

        <RenameFolderPopup
          onClose={() =>
            dispatch({ type: "isRenameFolderPopupShow", payload: false })
          }
          open={state.isRenameFolderPopupShow}
          fileId={state.selectedFile}
          responseStatus={handelRenameFolderStatus}
        />

        <FileUploadProgress
          open={state.fileUploadProgress}
          // open={true}
          onClose={() => dispatch({ type: "fileUploadProgress", payload: false })}
          fileId={state.selectedFolder}
          fileUploadSuccess={handelUploadResponse}
        />
        <ToastContainer />
      </div>
    </div>
  );
}