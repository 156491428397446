import React, { useState, useEffect } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const DatePeriodsComponent = ({ onDateChange }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedValue, setSelectedValue] = useState('');

  // useEffect(() => {
  //   if (selectedValue !== 'custom') {
  //     onDateChange({ type: 'date', dateType: selectedValue });
  //   } else if (dateRange[0] && dateRange[1]) {
  //     onDateChange({
  //       type: 'date',
  //       dateType: 'custom',
  //       startDate: dateRange[0].toISOString(),
  //       endDate: dateRange[1].toISOString()
  //     });
  //   }
  // }, [selectedValue, dateRange, onDateChange]);

  const handleDateChange = (index, date) => {
    const newRange = [...dateRange];
    newRange[index] = date;
    setDateRange(newRange);
    onDateChange({
      type: 'date',
      dateType: 'custom',
      startDate: newRange[0],
      endDate: newRange[1]
    });
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    onDateChange({ type: 'date', dateType: value });
  };

  return (
    <>
      <Box className="date-periods" sx={{width:'50%', marginBottom:'25px' }}>
        <RadioGroup onChange={handleChange}>
          <FormControlLabel value="lastmonth" control={<Radio />} label="Last Month" />
          <FormControlLabel value="thisquarter" control={<Radio />} label="This Quarter" />
          <FormControlLabel value="lastquarter" control={<Radio />} label="Last Quarter" />
          <FormControlLabel value="thisyear" control={<Radio />} label="This Year" />
          <FormControlLabel value="lastyear" control={<Radio />} label="Last Year" />
          <FormControlLabel value="custom" control={<Radio />} label="Custom" />
        </RadioGroup>
      </Box>
      {selectedValue === 'custom' &&
        <LocalizationProvider dateAdapter={AdapterDayjs} >
          <div style={{display:'flex',justifyContent:'space-between',width:'100%', gap:'10px'}}>
          <DatePicker className='popup-dapicker' sx={{width:'50%'}}
            label="Start Date"
            value={dateRange[0]}
            onChange={(date) => handleDateChange(0, date)}
          />
          <DatePicker className='popup-dapicker' sx={{width:'50%'}}
            label="End Date"
            value={dateRange[1]}
            onChange={(date) => handleDateChange(1, date)}
          />
          </div>
        </LocalizationProvider>
      }
    </>
  );
};

export default DatePeriodsComponent;
