import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import AddFilters from './addFilters.js';
import moment from 'moment';
import dayjs from 'dayjs';
import {countryNameMapping} from "../../analytical/AnalyticalCommon"

const Filters = ({ open, handleClose, filterDataView ,removeSelectedFilter, filteredData}) => {
  const [addFilter, setAddFilter] = useState(false);
  const [filters, setFilters] = useState(filteredData || []);
  const [selectedComponent, setSelectedComponent] = useState('');
  const countryName = countryNameMapping();
  // Load filters from localStorage on mount
  useEffect(() => {
    const savedFilters = JSON.parse(window.localStorage.getItem('filters')) || [];
    setFilters(savedFilters);
  }, []);

  const toggleAddFilter = () => {
    setAddFilter(!addFilter);
  };

  const handleRemoveFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
    window.localStorage.setItem('filters', JSON.stringify(newFilters));
    window.dispatchEvent(new Event('filterUpdate'));
    handleRefresh();
    removeSelectedFilter(newFilters)
  };

  const filterResult = (payload) => {
    const newFilters = payload.filters;
    const filterValues = [...filters, ...newFilters];
    setFilters(filterValues);
    window.localStorage.setItem('filters', JSON.stringify(filterValues));
    window.dispatchEvent(new Event('filterUpdate'));
    setAddFilter(false);
    filterDataView(filterValues)
  };

  const handleRefresh = () => {
    setSelectedComponent(selectedComponent === 'selected' ? 'unselected' : 'selected');
  };

  const handleClearAll = () => {
    setFilters([]);
    window.localStorage.setItem('filters', JSON.stringify([]));
    window.dispatchEvent(new Event('filterUpdate'));
    handleRefresh();
  };


  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={false} className='filterPopup' >
      <DialogTitle sx={{ display: 'flex', position: 'relative', padding: '15px 15px 10px', borderBottom: 'solid 1px #c2c2c2', marginBottom: '15px' }} color={"#194260"}>
        <Typography variant='h6' component='span'>
          Filters
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ color: '#7e8c9c', padding: '0 10px 0 15px', minHeight: '100px' }}>
        {filters?.length > 0 ? (
          filters?.map((filter, index) => (
            <Typography variant="body1" key={index} sx={{ padding: '0', fontSize: '14px' }}>
              <IconButton
                size="small"
                onClick={() => handleRemoveFilter(index)}
                sx={{ float: "none" }}
              >
                <CancelIcon fontSize="small" sx={{ color: "#1a425f" }} />
              </IconButton>
              {filter?.columnDisplay}:
              <strong>
              { filter?.columnDisplay === "Dispatch Country" || filter?.columnDisplay === "Origin Country" ? countryName[filter?.value?.value] : filter?.value?.value }
              </strong>
              {filter?.value?.dateType === 'lastmonth' ?
                (<strong> Last Month</strong>)
                : filter?.value?.dateType === 'thisquarter' ?
                  (<strong> This Quarter</strong>)
                  : filter.value?.dateType === 'lastquarter' ?
                    (<strong> Last Quarter</strong>)
                    : filter.value?.dateType === 'thisyear' ?
                      (<strong> This Year</strong>)
                      : filter.value?.dateType === 'lastyear' ?
                        (<strong> Last Year</strong>)
                        : (<strong> {filter?.value?.dateType}</strong>)
              }
              {filter?.value?.startDate && filter?.value?.endDate && (
                <strong> : {dayjs(filter?.value?.startDate).format('DD/MM/YYYY')} to {dayjs(filter?.value?.endDate).format('DD/MM/YYYY')}</strong>
              )}
            </Typography>
          ))
        ) : (
          <Typography variant='body1'>No filters set</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button sx={{ textTransform: 'capitalize', color: '#000' }} onClick={handleClose}>Close</Button>
        <Button sx={{ textTransform: 'capitalize', color: '#000' }} onClick={handleClearAll}>Refresh</Button>
        <Button sx={{ textTransform: 'capitalize', color: '#000' }} onClick={handleClearAll}><CancelIcon sx={{ marginRight: '4px', fontSize: '1.2rem' }} />Clear all</Button>
        <Button className="btn-blue" sx={{ backgroundColor: '#194260', color: '#ffffff', ml: '5px', textTransform: 'capitalize' }} onClick={toggleAddFilter}>
          <AddIcon sx={{ marginRight: '4px', fontSize: '1.2rem' }} />Add Filter
        </Button>
        {addFilter && (<AddFilters open={addFilter} handleClose={toggleAddFilter} filterResult={filterResult} />)}
      </DialogActions>
    </Dialog>
  );
};

export default Filters;
