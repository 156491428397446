import React from "react";
import "../../assets/styles/custom.css";
function NotFound() {
  return (
    <div className="NotFound">
      <img
        src={require("../../assets/images/404ERROR.jpg")}
        alt="Error"
        className="NotFound__image"
      />
    </div>
  );
}

export default NotFound;
