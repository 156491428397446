import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import Userpic from "../../assets/images/user-avatar.svg";
import { signOutManager } from "../../reducers/authSlice";
import { Autocomplete, Box, Grid, TextField, createFilterOptions } from "@mui/material";
import { authAPI } from "../../services/authAPI";
import { toast } from "react-toastify";

const Header = ({ onToggleSidebar }) => {
  const userProfile = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const [todayDate, setTodayDate] = useState('');
  const filter = createFilterOptions();
  const [companyData, setCompanyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const access_token = useSelector((state) => state.auth.access_token, shallowEqual);
  const hasFetched = useRef(false);
  const companyId =  userProfile?.default_client || 0;
  const currentLocation = useLocation();
  const checkAdmin = userProfile?.user_type === 'is_group_admin';
  const companyClientName = userProfile?.company_details
  const fetchCompanies = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      let selectedCompanyId = 0; // Default fallback value

      if (checkAdmin) {
        selectedCompanyId = companyId;
      } else {
        selectedCompanyId = userProfile?.id || 0;
      }

      formData.append('companyId', selectedCompanyId);
      const response = await authAPI.fetchCompaniesId({ access_token, formData, selectedCompanyId });

      const processedData = response?.result.map(company => ({
        group: company?.company_name,
        options: company?.clientAdmin.map(admin => ({
          label: `${admin?.first_name} ${admin?.last_name}`,
          id: admin?.id,
          profile_image: admin?.profile_image,
        })),
      }));

      setCompanyData(processedData);
    } catch (error) {
      toast.error(error?.message);
    }
    setLoading(false);
  };



  // Fetch companies when the user logs in or the component mounts
  useEffect(() => {
    if (access_token && !hasFetched.current && checkAdmin) {
      fetchCompanies();
      hasFetched.current = true;
    }
  }, [access_token,companyId]);

  // Set today's date
  useEffect(() => {
    const getTodayDate = () => {
      const now = new Date();
      const options = { month: 'long', day: 'numeric', year: 'numeric' };
      const formattedDate = now.toLocaleDateString('en-US', options);
      setTodayDate(formattedDate);
    };
    getTodayDate();
  }, []);

  // Handle company selection
  const handleCompanySelect = async (event, value) => {
    if (value) {
      try {
        const response = await authAPI.getCompanyDetails({
          access_token,
          companyId: value.id, // Use the selected company's ID
        });
        // toast.success(`Report of ${value.label}`);
        window.location.reload();
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  const handleLogout = () => {
    dispatch(signOutManager());
    window.location.href = "/login";
  };

  return (
    <header className="flex justify-between items-center">
      <div className="header-right">
        <div className="sidebar-toggle" onClick={onToggleSidebar}>
          <svg
            width="19"
            height="21"
            viewBox="0 0 19 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 3.9375C0 3.21152 0.606473 2.625 1.35714 2.625H17.6429C18.3935 2.625 19 3.21152 19 3.9375C19 4.66348 18.3935 5.25 17.6429 5.25H1.35714C0.606473 5.25 0 4.66348 0 3.9375ZM0 10.5C0 9.77402 0.606473 9.1875 1.35714 9.1875H17.6429C18.3935 9.1875 19 9.77402 19 10.5C19 11.226 18.3935 11.8125 17.6429 11.8125H1.35714C0.606473 11.8125 0 11.226 0 10.5ZM19 17.0625C19 17.7885 18.3935 18.375 17.6429 18.375H1.35714C0.606473 18.375 0 17.7885 0 17.0625C0 16.3365 0.606473 15.75 1.35714 15.75H17.6429C18.3935 15.75 19 16.3365 19 17.0625Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="current-status">
          <div className="current-date">
            <span>Today’s Date</span>
            <h5>{todayDate}</h5>
          </div>
          <div className="total-report">
            <span>Total report count</span>
            <h5>{userProfile?.report_count}</h5>
          </div>
        </div>
      </div>

      <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {userProfile?.user_type === 'is_group_admin' && currentLocation?.pathname === '/analyticals' &&
          <Box sx={{ width: '250px', marginRight: '15px', padding: '0', fontSize: '14px' }} className="headerfilter">
            <Autocomplete
              options={companyData?.flatMap(group => group.options)}
              groupBy={(option) => {
                for (let group of companyData) {
                  if (group?.options.includes(option)) {
                    return group?.group;
                  }
                }
                return '';
              }}
              getOptionLabel={(option) => option.label}
              filterOptions={(options, state) => filter(options, state)}
              renderInput={(params) => <TextField {...params} label={companyClientName} />}
              renderOption={(props, option) => (
                <li {...props}>{option.label}</li>
              )}
              renderGroup={(params) => (
                <li key={params.key} className="test" sx={{ fontSize: '14px' }}>
                  <div className="dropdown-heading">{params.group}</div>
                  <ul className="dropdown-list">{params.children}</ul>
                </li>
              )}
              onChange={handleCompanySelect} // Handle company selection
            />
          </Box>
        }

        <Menu
          menuButton={
            <MenuButton id="userpic">
              <img src={(userProfile?.profile_image || Userpic)} alt="" />{" "}
              {userProfile?.first_name} {userProfile?.last_name}
            </MenuButton>
          }
        >
          <MenuItem>
            <NavLink to="/update-profile" activeClassName="active">
              <span>Profile</span>
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to="/update-password" activeClassName="active">
              <span>Change Password</span>
            </NavLink>
          </MenuItem>
          {userProfile.user_type === "is_client_admin" && (
            <>
              <MenuItem>
                <NavLink to="/view-user" activeClassName="active">
                  <span>Users</span>
                </NavLink>
              </MenuItem>
            </>
          )}
          <MenuItem onClick={handleLogout}>
            <NavLink activeClassName="active">
              <span>Logout</span>
            </NavLink>
          </MenuItem>
        </Menu>
      </Grid>
    </header>
  );
};

export default Header;
