import React, {
  useCallback,
  useLayoutEffect,
  useEffect,
  useState,
} from "react";
import Card from "@mui/material/Card";
import { Box, CircularProgress, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
//** local files */
import { authAPI } from "../../services/authAPI";
const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));
const FileUploadProgress = ({ open, onClose, fileId, fileUploadSuccess }) => {
  const [progress, setProgress] = useState([]);
  const [intervalId, setIntervalId] = useState(open);
  const access_token = useSelector(
    (state) => state.auth.access_token,
    shallowEqual
  );

  const fetchProgress = useCallback(async () => {
    if (!fileId) {
      // Handle missing fileId gracefully
      alert("File ID is missing. Cannot poll API.");
      return;
    }
    try {
      const response = await authAPI.getProgressUploadedFile({
        access_token,
        data: fileId.id, // Ensure correct data field usage
      });

      if (response.status) {
        if (response && response?.result?.length === 0) {
          setIntervalId(false);
          fileUploadSuccess(response);
        }
        setProgress(response); // Update progress state
      }
    } catch (error) {
      console.error("API error:", error);
      // Implement appropriate error handling, e.g., retry logic, exponential backoff, etc.
    }
  }, [access_token, fileId, fileUploadSuccess]);

  useLayoutEffect(() => {
    if (open) {
      var interval = setInterval(() => {
        fetchProgress();
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [fetchProgress, open]);
  //** For file name shortening */
  function getFileName(str) {
    if (str.length > 22) {
      return str.substr(0, 11) + "..." + str.substr(-11);
    }
    return str;
  }

  return (
    open && (
      <Card>
        <Box
          className="fup_popup"
          sx={{
            position: "fixed",
            top: "75%",
            right: "5%",
            left: "auto",
            // transform: "translate(-50%, -50%)",
            width: 362,
            bgcolor: "background.paper",
            border: "1px solid #ccc",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
            overflow: "auto",
            maxHeight: "80vh",
          }}
        >
          <Box className="fup_popup_title" mb={2}>
            <Typography
              id="file-upload-progress-modal-title"
              variant="h6"
              component="h2"
            >
              File Upload Progress
            </Typography>
            {/* <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton> */}
          </Box>
          <Box className="fup_popup_body" display="flex" flexDirection="column">
            {/* Replace with your upload items. This is an example. */}
            {progress?.result?.length > 0 ? (
              progress?.result?.map((t) => {
                let tm = t.process_message.split("...");
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    mb={2}
                    justifyContent={"space-between"}
                  >
                    <Typography variant="body1">
                      <i class="fas fa-image"></i> {getFileName(t.name)}
                    </Typography>
                    {t.process_message === "Pending" ? (
                      <CircularProgress
                        determinate
                        size={20}
                        sx={{ ml: 5 }}
                        value={progress}
                      />
                    ) : tm[0] === "Uploading" ? (
                      <CircularProgress
                        determinate
                        size={20}
                        sx={{ ml: 5 }}
                        value={progress}
                      />
                    ) : t.process_message ===
                      "Upload completed successfully" ? (
                      <SuccessIcon color="success" size="sm" sx={{ ml: 5 }} />
                    ) : (
                      <ErrorIcon color="error" size="sm" sx={{ ml: 5 }} />
                    )}
                  </Box>
                );
              })
            ) : (
              <div style={{ maxHeight: "80vh", overflowY: "auto" }}>
                <StyledBox
                  sx={{
                    px: 2,
                    pb: 2,
                    height: "100%",
                    overflow: "auto",
                  }}
                >
                  <Skeleton variant="rectangular" height="100%" />
                </StyledBox>
              </div>
            )}
          </Box>
        </Box>
      </Card>
    )
  );
};

export default FileUploadProgress;
