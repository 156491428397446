import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import IconCurrencies from "../../../assets/images/icon-currency.svg"; // Adjust the path as necessary
import IconDeclarant from "../../../assets/images/icon-declarants.svg"; // Adjust the path as necessary
import IconCountry from "../../../assets/images/icon-country.svg";

const Index = ({ icon: Icon, number, value, description }) => {
  const { pathname } = useLocation();

  // Only render the component if the pathname is '/analyticals'
  if (pathname !== "/analyticals") return null;

  // Styles object
  const styles = {
    card: {
      display: "flex",
      alignItems: "center",
      padding: "16px",
      width: "25%",
      height: "100px",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      margin: "8px",
      justifyContent: "space-between",
    },
    cardContent: {
      padding: 0,
      width: "150px",
    },
    description: {
      color: "#fff",
    },
    numberBox: {
      display: "flex",
    },
    number: {
      color: "#fff",
      fontWeight: "500",
      fontSize: "25px",
    },
    value: {
      color: "#fff",
      fontSize: "14px",
      marginLeft: "3px",
      fontWeight: "300",
    },
    iconContainer: {
      padding: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "40px",
      height: "40px",
      borderRadius: "5px",
      boxShadow: "0px 0px 1px 1px rgba(255, 255, 255, 1)",
      backgroundColor: "transparent",
    },
    icon: {
      fontSize: 30,
      color: "#ffffff",
      backgroundColor: "transparent",
      borderRadius: "5px",
      boxShadow: "0px 0px 1px 1px rgba(255, 255, 255, 1)",
      padding: "0px",
      width: "40px",
      height: "40px",
      display: "block",
    },
  };

  // Render the appropriate icon based on the prop
  const renderIcon = () => {
    if (Icon === IconCurrencies) {
      return <img src={Icon} alt="Icon" />;
    }
    if (Icon === IconDeclarant) {
      return <img src={Icon} alt="Icon" />;
    }
    if (Icon === IconCountry) {
      return (
        <img src={Icon} alt="Icon" style={{ padding: "0", width: "28px" }} />
      );
    } else {
      return <Icon sx={styles.icon} />;
    }
  };
  function formatNumber(value) {
    return value?.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 3
    });
}

  return (
    <Card sx={styles.card} className="kpibox">
      <CardContent sx={styles.cardContent} className="dashOuter">
        <Typography variant="body2" sx={styles.description} className="dashHeading">
          {description}
        </Typography>
        <Box sx={styles.numberBox}>
          {description === "Customs Value Import Total" && number ? (
            <Typography sx={styles.number} className="numberbox">
              £{number ? formatNumber(number) : "0.00"} <span style={styles.value}>{value}</span>
            </Typography>
          ) : (
            <Typography sx={styles.number} className="numberbox">
              £{number ? formatNumber(number) : "0.00"} <span style={styles.value}>{value}</span>
            </Typography>
          )}
        </Box>
      </CardContent>
      <Box sx={styles.iconContainer} className="infoicon">{renderIcon()}</Box>
    </Card>
  );
};

export default Index;
