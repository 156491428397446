import { useFormik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import Iconeyeoff from "../../assets/images/eye-off-svgrepo-com.svg";
import Iconeye from "../../assets/images/eye-svgrepo-com.svg";
import { authAPI } from "../../services/authAPI";

function UpdatePassword() {
  const access_token = useSelector((state) => state.auth.access_token);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old password can't be blank ."),
    newPassword: Yup.string()
      .required("New password can't be blank .")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{16,}$/,
        "Password must contain at least 16 characters, one uppercase letter, one lowercase letter, one number, and one special character"
      ),
    confirmPassword: Yup.string()
      .required("Confirm password can't be blank .")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setSubmitting(true);
        if (access_token) {
          const updatedUserPassword = await authAPI.updateUserPassword({
            access_token,
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword,
          });
          if (!updatedUserPassword.error) {
            // showSuccessMessage(updatedUserPassword.message);
            toast.success(updatedUserPassword.message);
            resetForm();
          } else if (updatedUserPassword.error) {
            // showSuccessMessage(updatedUserPassword.error);
            toast.error(updatedUserPassword.error);
            resetForm();
          }
        }
      } catch (error) {
        console.log("Error updating user profile:", error);
        const message = "Something wrong !";
        // showSuccessMessage(message);
        toast.error(message);
        resetForm();
        formik.setFieldError(
          "newPassword",
          "Error updating password. Please try again."
        );
        setSubmitting(false);
      }
    },
  });

  return (
    <div>
      <div className="pagecontainer scrollablearea">
        <div className="logincontainer mt-5 pt-10">
          <h1 className="text-3xl font-medium mb-8">Change Password</h1>
          <div className="bg-white rounded-md">
            <ToastContainer />
            <form onSubmit={formik.handleSubmit}>
              <div className="columns-1">
                <div className="w-full mb-3">
                  <label className="mb-1 block">Old Password:</label>
                  <input
                    className={`bg-gray w-full rounded-lg border-primary h-12 border px-3 ${formik.errors.oldPassword}`}
                    type="text"
                    name="oldPassword"
                    value={formik.values.oldPassword}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.oldPassword && (
                    <div className="error-message">
                      {formik.errors.oldPassword}
                    </div>
                  )}
                </div>
                <div className="w-full mb-3">
                  <label className="mb-1 block">New Password:</label>
                  <div className="input-w-icon">
                    <input
                      className={`bg-gray w-full rounded-lg border-primary h-12 border px-3 `}
                      type={showNewPassword ? "text" : "password"}
                      name="newPassword"
                      value={formik.values.newPassword}
                      onChange={formik.handleChange}
                    />
                    <span
                      className="new-eye-icon"
                      onClick={() => setShowNewPassword(!showNewPassword)} // Toggle the state
                    >
                      {showNewPassword ? (
                        <img src={Iconeye} width="18" alt="eye" />
                      ) : (
                        <img src={Iconeyeoff} width="18" alt="new password" />
                      )}{" "}
                    </span>
                  </div>
                  {formik.errors.newPassword && (
                    <div className="error-message">
                      {formik.errors.newPassword}
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full mb-5">
                <label className="mb-1 block">Confirm Password:</label>
                <div className="input-w-icon">
                  <input
                    className={`bg-gray w-full rounded-lg border-primary h-12 border px-3 `}
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                  />
                  <span
                    className="new-eye-icon"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)} // Toggle the state
                  >
                    {showConfirmPassword ? (
                      <img src={Iconeye} width="18" alt="eye" />
                    ) : (
                      <img src={Iconeyeoff} width="18" alt="confirm password" />
                    )}{" "}
                    {/* Toggle the eye icon */}
                  </span>
                </div>

                {formik.errors.confirmPassword && (
                  <div className="error-message">
                    {formik.errors.confirmPassword}
                  </div>
                )}
              </div>
              <div className="text-right">
                <button
                  className="button-default mb-3"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? "Updating..." : "Update Password"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatePassword;
