import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Iconeyeoff from '../../assets/images/eye-off-svgrepo-com.svg';
import Iconeye from '../../assets/images/eye-svgrepo-com.svg';
import { setSuccessMessage } from '../../reducers/messageSlice';
import { authAPI } from '../../services/authAPI';

function ResetPassword() {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state && location.state.successMessage;

  const initialValues = {
    otp: '',
    newPassword: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    otp: Yup.string().required('OTP is required'),
    newPassword: Yup.string()
      .required('New password is required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{16,}$/,
        'Password must contain at least 16 characters, one uppercase letter, one lowercase letter, one number, and one special character'
      ),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const handleResetPassword = async (values) => {
    try {
      const response = await authAPI.resetPassword(values);
      setMessage(response.message);
      if (response.message) {
        const message  = response.message;
        dispatch(setSuccessMessage({ message })); // Dispatch success message to Redux
        navigate('/login');
        // navigate('/login', { state: { successMessage: response.message } });
      }
    } catch (error) {
      setMessage('Failed to reset password. Please try again.');
    }
  };

  return (
    <div>
      <div className='logincontainer-outer'>
        <div className='logincontainer pb-5'>
        <div className="flex justify-center">
        <Link to="/">
            <div className="animated-logo">
              <div className="animated-logo-360"></div>
            </div>
        </Link>
          </div>
          <div className='mt-5'>
          {successMessage && <p className="success-message">{successMessage}</p>}</div>
          <div className='mb-5'>
            <h1 className='text-3xl font-medium mb-5 mt-2'>Reset Password</h1>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleResetPassword}
            >
              <Form>
              <div className="form-group mb-5 relative">
                  <Field type="text" name="otp" placeholder="Enter OTP" />
                  <ErrorMessage name="otp" component="div" className="error-message" />
                </div>
                <div className="form-group mb-5 relative">
                  <Field
                    type={showNewPassword ? 'text' : 'password'}
                    name="newPassword"
                    placeholder="Enter new password"
                  />
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="error-message"
                  />
                  <span
                  className="new-eye-icon top-17"
                  onClick={() => setShowNewPassword(!showNewPassword)} // Toggle the state
                  >
                    {showNewPassword ? <img src={Iconeye} width="18" alt='new password' /> : <img src={Iconeyeoff} width="18" alt='eye' />} {/* Toggle the eye icon */}
                  </span>
                </div>
                <div className="form-group mb-5 relative">
                  <Field
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    placeholder="Confirm new password"
                  />
                  <span
                    className="new-eye-icon top-17"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)} // Toggle the state
                  >
                    {showConfirmPassword ? <img src={Iconeye} width="18" alt='confirm password' /> : <img src={Iconeyeoff} width="18" alt='eye' />} {/* Toggle the eye icon */}
                  </span>
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className='text-end mt-5'>
                  <button type="submit">Submit</button>
                </div>
              </Form>
            </Formik>
          </div>
          <div className=' mb-5'><p className="error-message">{message}</p></div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
