// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 500px; /* Adjust as needed */
  position: fixed;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 35px;
  border: none;
  background: none;
  font-size: 2rem;
  cursor: pointer;
}

.upload-container {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
}

.upload-instructions {
  margin-bottom: 15px;
}

.upload-button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.file-types {
  color: #666;
  margin-top: 15px;
  font-size: 0.8rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/vault/CustomModal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,YAAY,EAAE,qBAAqB;EACnC,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".modal-backdrop {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.modal-content {\n  background: white;\n  padding: 20px;\n  border-radius: 5px;\n  width: 500px; /* Adjust as needed */\n  position: fixed;\n}\n\n.close-button {\n  position: absolute;\n  top: 20px;\n  right: 35px;\n  border: none;\n  background: none;\n  font-size: 2rem;\n  cursor: pointer;\n}\n\n.upload-container {\n  border: 2px dashed #ccc;\n  border-radius: 5px;\n  padding: 20px;\n  text-align: center;\n}\n\n.upload-instructions {\n  margin-bottom: 15px;\n}\n\n.upload-button {\n  padding: 10px 20px;\n  margin: 10px;\n  border: none;\n  background-color: #007bff;\n  color: white;\n  cursor: pointer;\n  border-radius: 5px;\n}\n\n.file-types {\n  color: #666;\n  margin-top: 15px;\n  font-size: 0.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
