import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import Swal from "sweetalert2";

import {
  TextField,
  Button,
  Modal,
  Box,
  Typography,
  FormHelperText,
} from "@mui/material";
import "./CustomModal.css";
import { authAPI } from "../../services/authAPI";
import { useState } from "react";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";

function RenameFolderPopup({ open, onClose, fileId, responseStatus }) {
  const [folderName, setFolderName] = useState(fileId?.name || '')
  const [folderError, setFolderError] = useState('')
  const handleClose = () => {
    onClose();
    setFolderError('')
  };
  const [isLoading, setLoading] = useState(false);

  const access_token = useSelector(
    (state) => state.auth.access_token,
    shallowEqual
  );

  useEffect(() => {
    setFolderName(fileId?.name)
  }, [fileId?.name])



  const handleChange = (e) => {
    const input = e.target.value;
    setFolderName(input)
    const regex = /^[a-zA-Z0-9_ .]*$/; // Regular expression to allow only letters, numbers, underscores, and spaces
    if (!regex.test(input)) {
      setFolderError('Special characters are not allowed');
    } else if (input === '') {
      setFolderError('Folder name is required');
    } else {
      setFolderError('')
    }
  }

  const handelFolderRenameManager = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
      allowOutsideClick: false,
      showCloseButton: true,
    });
  
    const trimmedFolderName = folderName.trim();
    if (trimmedFolderName === '' || folderError !== '') {
      setFolderError(folderError || 'Folder name is required');
      return false;
    }
  
    setLoading(true); // Start loading before the API call
    const data = {
      "name": trimmedFolderName,
      "folder_id": fileId?.id
    };
  
    try {
      const response = await authAPI.renameFolderName({ access_token, data });
      if (response?.status) {
        swalWithBootstrapButtons
          .fire({
            title: "Folder Renamed!",
            text: `Your folder has been renamed successfully.`,
            icon: "success",
          })
          .then((result) => {
            if (result?.isConfirmed) {
              responseStatus(response);
            }
          });
        onClose();
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      swalWithBootstrapButtons.fire({
        title: "Error",
        text: `An error occurred: ${error.message}`,
        icon: "error",
      });
    } finally {
      setLoading(false); // Stop loading regardless of the outcome
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "250px",
    width: "350px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "16px",
    blur: "8px",
    opacity: 2,
    justifyItems: "center",
    alignItems: "center",
  };
  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="file-upload-modal"
      aria-describedby="file-upload-description"
    >
      <Box className="renamefile_popup" sx={style} elevation={8}>
        <div
          style={{
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
            flexDirection: "row",
          }}
        >
          <Typography id="file-upload-modal" variant="h9" component="h2">
            Rename Folder
          </Typography>

          <button className="close-button" onClick={handleClose}>
            &times;
          </button>
        </div>

        <Box className="renamefile_body"
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#F9F4EF",
            background:
              "linear-gradient(0deg, #F9F4EF, #F9F4EF) linear-gradient(0deg, #FF6B04, #FF6B04)",

            alignItems: "center",
            border: "1px dashed orange",
            height: "100px",
            marginTop: "1rem",
            borderRadius: 2,
            cursor: "pointer",
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            value={folderName}
            onChange={handleChange}
            placeholder="Rename Folder"
          />
          {folderError !== '' && <FormHelperText className="danger">{folderError}</FormHelperText>}
        </Box>
        {isLoading ? (
          <LoadingButton
            loading
            loadingIndicator="Loading…"
            variant="contained"
            style={{ position: "absolute", bottom: "25px", right: "35px" }}
          >
            Fetch data
          </LoadingButton>
        ) : (
          <Button className="renamefile_submitbtn"
            variant="outlined"
            onClick={handelFolderRenameManager}
            style={{ position: "absolute", bottom: "25px", right: "32px" }}
          >
            Submit
          </Button>
        )}

      </Box>
    </Modal>
  );
}

export default RenameFolderPopup;
