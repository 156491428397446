import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Box, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import DatePeriodsComponent from './datePeriod';

const CustomCalendar = ({ open, handleClose }) => {
  const [dateRange, setDateRange] = useState([null, null]);

  const handleDateChange = (newRange) => {
    setDateRange(newRange);
    if (newRange[0] && newRange[1]) {
      handleDate({
        column: "clearanceDate",
        columnDisplay: "Clearance Date",
        value: {
          type: 'date',
          dateType: 'custom',
          startDate: newRange[0].toISOString(),
          endDate: newRange[1].toISOString()
        }
      });
    }
  };

  const handleDate = (valueSelected) => {
    console.log("🚀 ~ handleDate ~ valueSelected:", valueSelected);
    const filters = [valueSelected];
    window.localStorage.setItem('filters', JSON.stringify(filters));
    window.dispatchEvent(new Event('filterUpdate'));
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true}>
        <Grid container borderRadius={1}>
          <Grid item xs={8}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant='h6' color={"#194260"} ml={4} mt={2}>
                <CalendarTodayIcon sx={{ marginRight: '5px', fontSize: '1.2rem' }} /> Custom Range
              </Typography>
            </Box>
            <DialogContent>
              <Calendar
                onChange={handleDateChange}
                value={dateRange}
                selectRange={true}
              />
            </DialogContent>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6' color={"#194260"} mt={2} mb={2}>Date Periods</Typography>
            <DatePeriodsComponent onDateChange={handleDate} />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default CustomCalendar;
