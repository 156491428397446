// import { TrashIcon } from "@heroicons/react/24/solid";
import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authAPI } from "../../services/authAPI";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip } from "@mui/material";
import IconDelete from '../../assets/images/icon-delete-o.svg';
const DataManage = () => {
  const access_token = useSelector((state) => state.auth.access_token);
  const [uploadedDataList, setUploadedDta] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [dataIdToDelete, setDataIdToDelete] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const fetchUserProfile = useCallback(async () => {
    setIsLoading(true);
    try {
      if (access_token) {
        const response = await authAPI.getUploadData({ access_token });
        if (response.data) {
          setUploadedDta(response?.data);
          setIsProcessing(response?.isProcessing);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log("Error fetching user profile:", error);
    }
  }, [access_token]);

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (isProcessing)
        fetchUserProfile();
    }, 8000);

    return () => clearInterval(interval);
  }, [isProcessing, fetchUserProfile]);

  const onAccept = async () => {
    setShowModal(false);
    try {
      const response = await authAPI.deleteUploadData({
        access_token,
        dataId: dataIdToDelete,
      });
      if (response.message) {
        toast.success(response.message);
        // Update the data list by removing the deleted data
        setUploadedDta((prevDataList) =>
          prevDataList.filter((data) => data.id !== dataIdToDelete)
        );
      } else if (response.error) {
        toast.error(response.error);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsDeleting(false);
      setDataIdToDelete(null);
    }
  };

  const OnUnAccept = () => {
    // hide the modal
    setIsDeleting(false);
    setShowModal(false);
    setDataIdToDelete(null);
  };

  const handleDeleteUser = (dataId) => {
    setIsDeleting(true);
    setShowModal(true);
    setDataIdToDelete(dataId);
  };
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear().toString().slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);

    // Create the formatted date string
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

    return formattedDate;
  }

  return (
    <div>
      <div className="pagecontainer view-user scrollablearea">
        <div className="flex justify-between">
          <h1 className="text-3xl font-medium mb-8 sm:text-2xl sm:mb-2">Manage Uploaded Data</h1>
        </div>
        <ToastContainer />
        {showModal && (
          <div className="popup">
            <div className="modal-content ">
              <div className="modal-header"></div>
              <div className="modal-body text-center">
                <h6>Are you sure you want to delete?</h6>
              </div>
              <div className="modal-footer flex justify-center">
                <button onClick={OnUnAccept}>Cancel</button>
                <button className="button-default" onClick={onAccept}>
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
        <>
          {uploadedDataList.length === 0 ? (
            // Check if user list is empty
            <div className="user-not-found-message heading">Data not found.</div>
          ) : (
            <table className="w-full border-collapse table mt-3">
              <thead className="table-light">
                <tr>
                  <th className="p-2 text-left font-medium w-12">Status</th>
                  <th className="p-2 text-left font-medium">Name</th>
                  <th className="p-2 text-left font-medium">Type</th>
                  <th className="p-2 text-left font-medium">Created At</th>
                  <th className="p-2 text-left font-medium">EORI</th>
                  <th className="p-2 text-center w-32 font-medium">Action</th>
                </tr>
              </thead>
              <tbody>
                {uploadedDataList.map((uploadData) => (
                  <tr key={uploadData?.id}>
                    <td data-label="Status">
                      {uploadData.processStatus === 'complete' && (
                        <Tooltip title='Data uploaded successfully' placement="right">
                          
                          <IconButton sx={{ p: '0' }}>
                            <CheckCircleIcon sx={{ color: '#2b8915' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {uploadData.processStatus === 'processing' && (
                        <Tooltip title={uploadData?.processMessage} placement="right">
                          <div className="loader"></div>
                        </Tooltip>
                      )}
                      {uploadData.processStatus === 'failed' && (
                        <Tooltip title={uploadData?.processMessage} placement="right">
                          <IconButton sx={{ p: '0' }}>
                            <ErrorIcon sx={{ color: '#ff4106' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </td>
                    <td className="p-2" data-label="Name">{uploadData?.file_name}</td>
                    <td className="p-2" data-label="Type">{uploadData?.upload_type}</td>
                    <td className="p-2" data-label="Created At">
                      {formatDate(uploadData?.created_at)}
                    </td>
                    <td className="p-2" data-label="EORI">{uploadData?.eori}</td>
                    <td className="p-2 text-center" data-label="Action">
                      <button
                        onClick={() => handleDeleteUser(uploadData?.id)}
                        disabled={isDeleting}
                      >
                        <span
                          className="h-6 w-6 text-blue-500"
                          style={{ opacity: isLoading ? 0.5 : 1 }}
                        >
                         <img src={IconDelete} alt="Add Icon" style={{ width: '18px', height: '18px'}} />
                        </span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      </div>
    </div>
  );
};

export default DataManage;
