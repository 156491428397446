import * as React from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { authAPI } from "../../services/authAPI";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import DownloadIcon from "@mui/icons-material/Download";
import { Divider } from "@mui/material";
import { Delete } from "@mui/icons-material";
import Swal from "sweetalert2";
import ClearIcon from "@mui/icons-material/Clear";
const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  //   height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(100% - 15px)",
}));

function SwipeableEdgeDrawer({ file, toggle, toggleOpen, onClose }) {
  const [versionData, setVersionData] = React.useState(null);
  const access_token = useSelector(
    (state) => state.auth.access_token,
    shallowEqual
  );
  const userProfile = useSelector((state) => state.userProfile);
  const gettingVerionHistoryManager = React.useCallback(async () => {
    const data = file?.id;
    const response = await authAPI.getFileVersionHistory({
      access_token,
      data,
    });
    if (response?.status == true) {
      setVersionData(response?.result);
    }
  }, [access_token, file?.id]);

  React.useLayoutEffect(() => {
    if (toggleOpen) {
      gettingVerionHistoryManager();
    }
  }, [toggleOpen, gettingVerionHistoryManager]);
  const handleClose = () => {
    onClose();
    setVersionData(null);
  };
  //** file Download */
  const handelFileDownload = React.useCallback((file) => {
    window.open(file, "_blank", "noreferrer");
  }, []);
  //** For file name shortening */
  function getFileName(str) {
    let fileName = str.split("/");

    if (fileName[fileName.length - 1].length > 22) {
      return (
        fileName[fileName.length - 1].substr(0, 11) +
        "..." +
        fileName[fileName.length - 1].substr(-11)
      );
    }
    return fileName[fileName.length - 1];
  }

  //** delete file */
  const handleDeleteFile = React.useCallback(
    async (event) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: true,
        allowOutsideClick: false,
        showCloseButton: true,
      });
      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `Do you really want to delete this file ${getFileName(
            file?.name
          )}!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const data = new FormData();
              data.append("version_id", event?.VersionId);
              data.append("file_id", file?.id);
              const response = await authAPI.deleteFileVersionHistory({
                access_token,
                data,
              });
              if (response?.status) {
                swalWithBootstrapButtons.fire({
                  title: "Deleted!",
                  text: `Your file ${getFileName(
                    file?.name
                  )} has been deleted.`,
                  icon: "success",
                });
                await gettingVerionHistoryManager();
              }
            } catch (error) {
              console.log(error);
            }
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire({
              title: "Cancelled",
              text: `Your file ${getFileName(file?.name)} is safe!`,
              icon: "error",
            });
          }
        });
    },
    [file?.id, access_token, file?.name, gettingVerionHistoryManager]
  );
  function formatSizeUnits(bytes) {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + " GB";
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + " MB";
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes > 1) {
      bytes = bytes + " bytes";
    } else if (bytes == 1) {
      bytes = bytes + " byte";
    } else {
      bytes = "0 bytes";
    }
    return bytes;
  }
  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: '100%',
            overflow: "visible",
            top: "0",
            bottom: "0px",
            width: `calc(35% - ${drawerBleeding}px)`,
          },
        }}
      />
      <SwipeableDrawer
        // container={container}
        anchor="right"
        open={toggleOpen}
        onClose={handleClose}
        // onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box sx={{display:'flex',justifyContent:'space-between'}}>
          <Typography sx={{ p: 2, fontWeight:700 }}>
            History
          </Typography>
          <Button
            onClick={handleClose}
            className="closeButton"
            variant="text"
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <ClearIcon />
          </Button>
        </Box>
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />

          <Typography sx={{ p: 2, color: "text.secondary" }}>
            {versionData?.length} Results
          </Typography>
        </StyledBox>
        {versionData?.length >= 1 ? (
          <Paper>
            <List component="nav" aria-label="version history">
              {versionData.map((version, index) => (
                <React.Fragment key={version.id}>
                  {console.log("version", version)}
                  <ListItem>
                    <ListItemText
                      primary={getFileName(version.Key)}
                      secondary={`${version.date}           `}
                    />
                    <IconButton
                      edge="end"
                      aria-label="download"
                      onClick={() => handelFileDownload(version.url)}
                    >
                      <DownloadIcon fontSize="small" />
                    </IconButton>
                    {userProfile?.user_type === "is_client_admin" && (
                      <IconButton
                        edge="end"
                        aria-label="more"
                        onClick={() => handleDeleteFile(version)}
                      >
                        <Delete fontSize="small" />
                      </IconButton>
                    )}
                  </ListItem>
                  {index < versionData.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        ) : versionData?.length === 0 ? (
          <div
            class="rYfpJe"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <div
              jsaction="rcuQ6b:npT2md"
              tabindex="0"
              class="ab-S-Wg a-s-tb-paa-zf-ja-j"
              jscontroller="FFiA5c"
              aria-labelledby="empty-view-content-folders"
            >
              <div class="ab-S-Wb">
                <table>
                  <tr>
                    <td class="ab-S-c-qd-j">
                      <img
                        role="presentation"
                        src="//ssl.gstatic.com/docs/doclist/images/empty_state_home.svg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div id="empty-view-content-folders">
                        <div
                          role="heading"
                          class="ab-S-V"
                          id="empty-view-title-folders"
                          style={{ textAlign: "center" }}
                        >
                          No Version History Found
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="dashboard-logo loader-logo">
            <div className="animated-logo">
              <div className="animated-logo-360"></div>
            </div>
          </div>
        )}
      </SwipeableDrawer>
    </Root>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
