import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ClearLocalStorageOnNavigation = () => {
  const location = useLocation();

  useEffect(() => {
    localStorage.removeItem('filters');
    localStorage.removeItem('viewCurrency');
    localStorage.removeItem('currencySymbol');
  }, [location]);

  return null; // This component doesn't render anything
};

export default ClearLocalStorageOnNavigation;
