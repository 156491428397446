import React, { useState, useEffect } from 'react'
import { Box, Button, Dialog, Grid, IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LoupeIcon from '@mui/icons-material/Loupe';
import SettingsIcon from '@mui/icons-material/Settings';
import LineChart from './Charts/lineChartComponent';
import BarChart3DComponent from './Charts/BarChart3DComponent';
import SettingModal from './Control/SettingModal';
import InfoDisplay from './Charts/InfoDisplay';
import TableDataChart from './Charts/TableDataChart';
import GaugeChart from './Charts/GaugeChart';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import InfoIcon from '@mui/icons-material/Info';
import MapNewComponent from './Charts/newMapChart';
import { shallowEqual, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useGlobalContext } from '../../hook/globalstate';



/**
 * DroppableItem component for handling drag-and-drop functionality.
 * 
 * @param {Object} props - Component props.
 * @param {Function} props.onDragOver - Function to handle drag over event.
 * @param {string} props.dropKey - Key for the drop area.
 * @param {Function} props.handleAreaDelete - Function to handle deletion of drop area.
 * @param {Function} props.handleDroppedArea - Function to handle dropped area.
 * @param {Function} props.moveDroppableArea - Function to move droppable area.
 * @param {Array} props.sectionItem - Items in the section.
 * @param {number} props.totalAreas - Total number of areas.
 * @param {Function} props.handleSettingModelData - Function to handle setting modal data.
 * @returns {JSX.Element} - React component.
 */

const DroppableItem = (props) => {
  const { onDragOver, dropKey, handleAreaDelete, handleDroppedArea, moveDroppableArea, sectionItem, totalAreas } = props;
  const [dropItem, setDropItem] = useState(sectionItem || []);
  const [sectionId, setSectionId] = useState(dropKey || null);
  const [draggingItem, setDraggingItem] = useState(null);
  const [leftItem, setLeftItem] = useState(null);
  const [showDeleteButton, setShowDeleteButton] = useState('true')
  const [settingId, setSettingId] = useState(null); // State to manage the setting ID
  const [showSettingsModal, setShowSettingsModal] = useState(false); // State to control modal visibility
  const [modalItem, setModalItem] = useState(null);
  const [modalTitle, setModalTile] = useState(null); 
  const { state, setState } = useGlobalContext()
   
  const handleOpenSettings = (item) => {
    setShowSettingsModal(true);
    setSettingId(item);
  };

  const handleCloseSettings = () => {
    setShowSettingsModal(false);
  };

  useEffect(() => {
    setDropItem(sectionItem);
  }, [sectionItem]);


  const handleDrop = (e) => {
    e.preventDefault();
    const data = e.dataTransfer.getData('text/plain');
    const dragData = JSON.parse(data);
    const { item } = dragData;
    let droppedItems = [...dropItem];
    if (droppedItems?.length > 0) {
      const checkExist = droppedItems?.filter((di) => di?.item === dragData?.item)
      if (checkExist?.length > 0) {
        return false
      }
    }
    if (droppedItems?.length > 1) {
      toast.error('You cannot drop more than two widgets in a single droppable area')
      return false
    } else {
      if (item !== undefined) {
        droppedItems.push(dragData); // Push the item object, not the data string
      }
      setDropItem(droppedItems);
      handleDroppedArea(droppedItems, dropKey);
    }
    setDropItem(null)
  };

  const deleteDropAbleArea = (areaKey) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
      allowOutsideClick: false,
      showCloseButton: true,
      showCancelButton: true,
      reverseButtons: true,
    });

    swalWithBootstrapButtons.fire({
      title: "Delete Row?",
      text: "Delete this row and its contents? This can't be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // setDropItem([]);
        handleAreaDelete(areaKey);
      }
    });
    setDropItem(null)
  }

  const deleteDroppedItem = (deleteItem) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
      allowOutsideClick: false,
      showCloseButton: true,
      showCancelButton: true,
      reverseButtons: true,
    });

    swalWithBootstrapButtons.fire({
      title: "Delete Widget?",
      text: "Delete this widget? Any custom configuration will be lost.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // var array = [...dropItem];
        var array = Array.isArray(dropItem) ? [...dropItem] : [];
        var index = array?.indexOf(deleteItem);
        if (index !== -1) {
          array.splice(index, 1);
          setDropItem(array);
          handleDroppedArea(array, dropKey);
          setLeftItem(array);
        }
      }
    });
  }

  const handleItemDragStart = (e, item) => {
    setDraggingItem(item);
    setDropItem(null)
    const dragData = JSON.stringify(item);
    e.dataTransfer.setData('text/plain', dragData);
  };

  const handleItemDragEnd = () => {
    setDraggingItem(null)
    setDropItem(null)
  }

  const handleItemDragOver = (e) => {
    e.preventDefault()
  }

  const handleItemDrop = (e, targetItem) => {
    // const fields = dropItem
    const fields = Array.isArray(dropItem) ? [...dropItem] : [];
    const currentIndex = fields?.indexOf(draggingItem)
    const targetIndex = fields?.indexOf(targetItem)

    if (currentIndex !== -1 && targetIndex !== -1) {
      fields.splice(currentIndex, 1)
      fields.splice(targetIndex, 0, draggingItem)
      setDropItem(fields)
    }
  }

  const settingWidgetData = (data) => {
    props?.handleSettingModelData(data)
  }

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (itemDrop) => {
    setModalItem(itemDrop?.item);
    // Updating the title according to settings
    if(itemDrop?.properties){
      if(itemDrop?.properties?.label !== ""){
        setModalTile(itemDrop?.properties?.label)
      }else if(itemDrop?.properties?.hideLabel === true){
        setModalTile("");
      }else{
        setModalTile(itemDrop?.name);
      }
    }else{
      setModalTile(itemDrop?.name);
    }
    setModalTile(itemDrop?.name);
    setSettingId(itemDrop?.properties?.settingId);
    setSectionId(itemDrop?.properties?.dropKey)
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setModalItem(null); // Clear the item when the modal closes
  }

  return (
    <Grid container spacing={6} className='dashboard-row'
      onDrop={handleDrop}
      onDragOver={onDragOver} sx={{ width: '100%', p: '0px', mt: '5px' }}>
      {dropItem?.length > 0 ?
        <>
          {dropItem?.map((itemDrop, index) => {
            const gridSize = (12 / dropItem?.length)
            
            let widgetSettings = state.settingData[`${itemDrop?.itemId}-${dropKey}`];
            // Add widgetSettings to itemDrop under a new properties key
            itemDrop.properties = widgetSettings;            
            
            return <>
              <Grid key={index} item xs={12} sm={12} md={gridSize} lg={gridSize} sx={{ cursor: 'all-scroll', margin: '0', padding: '0' }} className='map-container'
                draggable
                onDragStart={(e) => handleItemDragStart(e, itemDrop, itemDrop?.id, itemDrop?.name, itemDrop?.infoTitle)}
                onDragEnd={handleItemDragEnd}
                onDragOver={handleItemDragOver}
                onDrop={(e) => handleItemDrop(e, itemDrop)}
                onMouseEnter={() => setShowDeleteButton(true)}
                onMouseLeave={() => setShowDeleteButton(false)}
              >
                {/* InfoDisplay chart  start*/}
                {(itemDrop?.item === 'widgets.imports.edr' || //c
                  itemDrop?.item === 'widgets.imports.edrOnDutiable' || //c
                  itemDrop?.item === 'widgets.imports.totalImports' || //c
                  itemDrop?.item === 'widgets.imports.totalVatPaid' || //c
                  itemDrop?.item === 'widgets.imports.totalVatValue' || //c
                  itemDrop?.item === 'widgets.exports.exportsByEntryNo' || //c
                  itemDrop?.item ==='widgets.imports.totalDutyPaid' || //c
                  itemDrop?.item === 'widgets.imports.averageClearance'
                ) && <InfoDisplay widgetName={itemDrop?.item} widgetTitle={itemDrop?.name} leftItem={leftItem}  dropKey={sectionId} settingId={itemDrop?.itemId} />}
                {/* InfoDisplay chart end */}

                {/* TableDataChart chart  */}
                {(
                  itemDrop?.item === 'widgets.imports.zeroCommodityCodes' ||
                  itemDrop?.item === 'widgets.imports.countryorigincomparedcountrydispatch' ||
                  itemDrop?.item === 'widgets.imports.dutyPaidByCurrency'
                ) && <TableDataChart widgetName={itemDrop?.item} widgetTitle={itemDrop?.name} leftItem={leftItem} dropKey={sectionId} settingId={itemDrop?.itemId} />}
                {/* TableDataChart chart end */}

                {/* LineChart chart  */}
                {(itemDrop?.item === 'widgets.imports.edrbymonth' || //c
                  itemDrop?.item === 'widgets.imports.edrbymonthOnDutiable'
                ) && <LineChart widgetName={itemDrop?.item} widgetTitle={itemDrop?.name} leftItem={leftItem}  dropKey={sectionId} settingId={itemDrop?.itemId} />}
                {/* LineChart chart  end*/}

                {/* BarChart3DComponent chart  */}
                {(
                  itemDrop?.item === 'widgets.imports.topCommodityCodesByDutyPaid' || //c
                  itemDrop?.item === 'widgets.imports.bottomCommodityCodesByDutyPaid' || //c
                  itemDrop?.item === 'widgets.imports.topDeclarantsByCustVal' || //c
                  itemDrop?.item === 'widgets.imports.topDeclarants' || //c
                  itemDrop?.item === 'widgets.imports.topSuppliersByDutyPaid' || //c
                  itemDrop?.item === 'widgets.imports.topSuppliersByImportVal' || //c
                  itemDrop?.item === 'widgets.imports.importValByCpc' || //c
                  itemDrop?.item === 'widgets.imports.importValByPref' || //c
                  itemDrop?.item === 'widgets.imports.suppliersByDispCtry' || //c
                  itemDrop?.item === 'widgets.imports.dutyRateByOrigCtry' || //c
                  itemDrop?.item === 'widgets.exports.topCommodityCodesByValue' || //c
                  itemDrop?.item === 'widgets.exports.bottomCommodityCodesByValue' || //c
                  itemDrop?.item === 'widgets.exports.totalcpc' || //c
                  itemDrop?.item === 'widgets.exports.exportsByConsignee' || //c
                  itemDrop?.item === 'widgets.imports.suppliersInMultipleCurrencies' || //c
                  itemDrop?.item === 'widgets.imports.totalvatposp' || //c
                  itemDrop?.item === 'widgets.imports.importCountByEpu'
                ) && <BarChart3DComponent widgetName={itemDrop?.item} widgetTitle={itemDrop?.name} leftItem={leftItem} dropKey={sectionId} settingId={itemDrop?.itemId} />}
                {/* BarChart3DComponent chart  end*/}

                {/* GaugeChart chart  start*/}
                {(
                  itemDrop?.item === 'widgets.imports.totalVatValuevsVatPaid' 

                ) && <GaugeChart widgetName={itemDrop?.item} widgetTitle={itemDrop?.name} leftItem={leftItem}  dropKey={sectionId} settingId={itemDrop?.itemId} />}
                {/* GaugeChart chart  end*/}

                {/* MapComponent chart  start*/}
                {(
                  itemDrop?.item === "widgets.imports.dutyPaidByOrigCtry" || //c
                  itemDrop?.item === "widgets.imports.importValByDispCtry" || //c
                  itemDrop?.item === "widgets.imports.importsByDispCtry" || //c
                  itemDrop?.item === "widgets.exports.commodityCodesPerDestCtry" ||  //c
                  itemDrop?.item === "widgets.exports.noOfExportsGoingToDestinations" || //c
                  itemDrop?.item === "widgets.imports.importsByOrigCtry"
                ) && <MapNewComponent widgetName={itemDrop?.item} widgetTitle={itemDrop?.name} leftItem={leftItem}  dropKey={sectionId} settingId={itemDrop?.itemId} />}
                {/* MapComponent chart  end*/}

                {showDeleteButton && (
                  <Box className="item-controls">
                    <Button className='btn-delete mat-focus-indicator mat-tooltip-trigger mat-icon-button mat-button-base' variant='text' onClick={(e) => handleOpenSettings(itemDrop?.itemId)}>
                      <SettingsIcon sx={{ color: '#ffffff' }} />
                    </Button>
                    <Tooltip title={itemDrop?.infoTitle || itemDrop?.helpText} placement="right" className='info'>
                      <IconButton sx={{ p: '0' }}>
                        <InfoIcon sx={{ color: '#fff' }} />
                      </IconButton>
                    </Tooltip>
                    <Button className='btn-delete mat-focus-indicator mat-tooltip-trigger mat-icon-button mat-button-base' variant='text' onClick={(e) => deleteDroppedItem(itemDrop)}>
                      <DeleteIcon sx={{ color: '#ffffff' }} />
                    </Button>
                    <Button onClick={(e) => handleOpenModal(itemDrop)} className='btn-delete mat-focus-indicator mat-tooltip-trigger mat-icon-button mat-button-base' variant='text'>
                      <LoupeIcon sx={{ color: '#ffffff' }} />
                    </Button>
                    {/*  <Button className='btn-delete mat-focus-indicator mat-tooltip-trigger mat-icon-button mat-button-base' variant='text'><ZoomOutMapIcon sx={{ color: '#ffffff' }} />
                    </Button> */}
                  </Box>
                )}
              </Grid>
            </>
          })}
          <Grid item xs={0.5} sm={0.5} md={0.5} sx={{ paddingLeft: '30px !important', }} className='positionIcons'>
            {dropKey != 1 &&
              <Button variant='text' sx={{ p: '0px', minWidth: '0px' }} onClick={(e) => deleteDropAbleArea(dropKey)}><DeleteIcon sx={{ color: '#bf0b11' }} /></Button>
            }
            <Button disabled={dropKey == 1 ? true : false} variant='text' sx={{ p: '0px', minWidth: '0px' }} onClick={(e) => moveDroppableArea(dropKey, 'up')}><ArrowUpwardIcon /></Button>
            <Button disabled={dropKey == totalAreas ? true : false} variant='text' sx={{ p: '0px', minWidth: '0px' }} onClick={(e) => moveDroppableArea(dropKey, 'down')}><ArrowDownwardIcon /></Button>
          </Grid>
        </>
        : <>
          <Grid item xs={11.5} sm={11.5} md={11.5} sx={{ display: 'grid', justifyContent: 'flex-start', paddingLeft: '10px !important', padding: '10px !important' }}>
            {dropKey != 1 &&
              <Button variant='text' sx={{ p: '0px', minWidth: '0px' }} onClick={(e) => deleteDropAbleArea(dropKey)}><DeleteIcon sx={{ color: '#bf0b11' }} /></Button>
            }
            <Button disabled={dropKey == 1 ? true : false} variant='text' sx={{ p: '0px', minWidth: '0px' }} onClick={(e) => moveDroppableArea(dropKey, 'up')}><ArrowUpwardIcon /></Button>
            <Button disabled={dropKey == totalAreas ? true : false} variant='text' sx={{ p: '0px', minWidth: '0px' }} onClick={(e) => moveDroppableArea(dropKey, 'down')}><ArrowDownwardIcon /></Button>
          </Grid>
        </>
      }
      <Dialog open={showSettingsModal} onClose={handleCloseSettings}>
        <SettingModal settingWidgetData={settingWidgetData} onClose={handleCloseSettings} settingId={settingId} dropKey={dropKey} />
      </Dialog>

      <Dialog
        open={showModal}
        contentLabel="Minimal Modal Example"
        className='reportModal'
      >
        <Button onClick={handleCloseModal} className='btn-delete ' variant='text'>
          <CloseIcon sx={{ color: '#000' }} />
        </Button>

        <>
          <div className="reportModal">
            <Grid className='map-container'>
              {/* InfoDisplay chart  start*/}
              {((modalItem === 'widgets.imports.edr') || //c
                modalItem === 'widgets.imports.edrOnDutiable' || //c
                modalItem === 'widgets.imports.totalImports' || //c
                modalItem === 'widgets.imports.totalVatPaid' || //c
                modalItem === 'widgets.imports.totalVatValue' || //c
                modalItem === 'widgets.exports.exportsByEntryNo' || //c
                modalItem === 'widgets.imports.totalDutyPaid' || //c
                modalItem === 'widgets.imports.averageClearance'
              ) && <InfoDisplay widgetName={modalItem} widgetTitle={modalTitle} leftItem={leftItem} />}
              {/* InfoDisplay chart end */}

              {/* TableDataChart chart  */}
              {(

                modalItem === 'widgets.imports.zeroCommodityCodes' ||
                modalItem === 'widgets.imports.dutyPaidByCurrency' ||
                modalItem === 'widgets.imports.countryorigincomparedcountrydispatch'
              ) && <TableDataChart widgetName={modalItem} widgetTitle={modalTitle} leftItem={leftItem} dropKey={sectionId} settingId={settingId} />}
              {/* TableDataChart chart end */}

              {/* LineChart chart  */}
              {(modalItem === 'widgets.imports.edrbymonth' || //c
                modalItem === 'widgets.imports.edrbymonthOnDutiable' 
              ) && <LineChart widgetName={modalItem} widgetTitle={modalTitle} leftItem={leftItem} />}
              {/* LineChart chart  end*/}

              {/* BarChart3DComponent chart  */}
              {(
                modalItem === 'widgets.imports.topCommodityCodesByDutyPaid' || //c
                modalItem === 'widgets.imports.bottomCommodityCodesByDutyPaid' || //c
                modalItem === 'widgets.imports.topDeclarantsByCustVal' || //c
                modalItem === 'widgets.imports.topDeclarants' || //c
                modalItem === 'widgets.imports.topSuppliersByDutyPaid' || //c
                modalItem === 'widgets.imports.topSuppliersByImportVal' || //c
                modalItem === 'widgets.imports.importValByCpc' || //c
                modalItem === 'widgets.imports.importValByPref' || //c
                modalItem === 'widgets.imports.suppliersByDispCtry' || //c
                modalItem === 'widgets.imports.dutyRateByOrigCtry' || //c
                modalItem === 'widgets.exports.topCommodityCodesByValue' || //c
                modalItem === 'widgets.exports.totalcpc' || //c
                modalItem === 'widgets.exports.bottomCommodityCodesByValue' || //c
                modalItem === 'widgets.exports.exportsByConsignee' || //c
                modalItem === 'widgets.imports.suppliersInMultipleCurrencies' || //c
                modalItem === 'widgets.imports.totalvatposp' || //c
                modalItem === 'widgets.imports.importCountByEpu'
              ) && <BarChart3DComponent widgetName={modalItem} widgetTitle={modalTitle} leftItem={leftItem} dropKey={sectionId} settingId={settingId} />}
              {/* BarChart3DComponent chart  end*/}

              {/* GaugeChart chart  start*/}
              {(
                modalItem === 'widgets.imports.totalVatValuevsVatPaid' 

              ) && <GaugeChart widgetName={modalItem} widgetTitle={modalTitle} leftItem={leftItem} />}
              {/* GaugeChart chart  end*/}

              {/* MapComponent chart  start*/}
              {(
                modalItem === "widgets.imports.dutyPaidByOrigCtry" || //c
                modalItem === "widgets.imports.importValByDispCtry" ||//c
                modalItem === "widgets.imports.importsByDispCtry" || //c
                modalItem === "widgets.exports.commodityCodesPerDestCtry" || //c
                modalItem === "widgets.exports.noOfExportsGoingToDestinations" || //c
                modalItem === "widgets.imports.importsByOrigCtry"
              ) && <MapNewComponent widgetName={modalItem} widgetTitle={modalTitle} leftItem={leftItem} dropKey={sectionId} settingId={settingId} />}
              {/* MapComponent chart  end*/}
            </Grid>
          </div>
        </>
      </Dialog>
    </Grid >

  );
}

export default DroppableItem
